import PropTypes from "prop-types";
import React from "react";

const LayoutPrint = ({ children, className = "", disableRightClick = false }) => {

    const handleClick = (event) => {
        if(disableRightClick) {
            event.preventDefault();
            alert("Use the provided Print button at the bottom of the page.");
        }
    };
    return (
        <div className={`contextMenu`} onContextMenu={handleClick}>
            <div className={`printPage ${className}`}>
                <main>
                    {children}
                </main>
            </div>
        </div>
    );
};

LayoutPrint.propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    disableRightClick: PropTypes.bool
};

export default LayoutPrint;
