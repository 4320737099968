import React from "react";
import PropTypes from "prop-types";

const AttentionContainer = ({
                                  children
                              }) => {


    return (
        <div className="attention-container">
            {children}
        </div>
    );
};

AttentionContainer.propTypes = {
    children: PropTypes.any
};

export default React.memo(AttentionContainer);