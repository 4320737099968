export const PARTICIPANT = "PARTICIPANT";
export const USERS_WITH_ENROLLMENTS = "USERS_WITH_ENROLLMENTS";
export const CAN_ENROLL = "CAN_ENROLL";
export const VIEW = "VIEW";
export const MANAGE = "MANAGE";
export const MANAGE_DELETED_USERS = "MANAGE_DELETED_USERS";
export const IMPORT_USERS = "IMPORT_USERS";
export const MERGE_USERS = "MERGE_USERS";
export const IMPORT_TRACKING_STUDENTS = "IMPORT_TRACKING_STUDENTS";
export const ADMIN_DASHBOARD = "ADMIN_DASHBOARD";
export const CATEGORY_MANAGE = "CATEGORY_MANAGE";
export const COURSE_MANAGE = "COURSE_MANAGE";
export const COURSE_EDIT = "COURSE_EDIT";
export const SURVEY_EDIT = "SURVEY_EDIT";
export const SURVEY_MANAGE = "SURVEY_MANAGE";
export const COURSE_EVENT_MANAGE = "COURSE_EVENT_MANAGE";
export const COURSE_TRAINING_MANAGE = "COURSE_TRAINING_MANAGE";
export const COURSE_PROFESSIONAL_COMMUNITIES_MANAGE = "COURSE_PROFESSIONAL_COMMUNITIES_MANAGE";
export const DELETE = "DELETE";
export const DELETE_FOLLOW_UP_FILES = "DELETE_FOLLOW_UP_FILES";
export const IMPERSONATE = "IMPERSONATE";
export const ACCESS_ALL_SITE_GROUPS = "ACCESS_ALL_SITE_GROUPS";
export const ACCESS_ALL_TASKS = "ACCESS_ALL_TASKS";
export const FOLLOW_UP_APPROVAL = "FOLLOW_UP_APPROVAL";
export const HAS_REGION = "HAS_REGION";
export const VIEW_REGIONS = "VIEW_REGIONS";
