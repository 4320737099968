import {isArrayNullOrEmpty} from "../../../Utilities/Types/arrayUtilities";

export const courseDetailsModel = ({
                                       bgImageExtension,
                                       categoryIds,
                                       contact,
                                       courseDescription,
                                       courseId,
                                       courseTitle,
                                       externalCourseId,
                                       externalDirectoryRoute,
                                       followUpActivityFileName,
                                       followUpActivityFormFileId,
                                       hasPreview = false,
                                       hidden = true,
                                       allowReEnrollments = false,
                                       isActive = true,
                                       isValid = true,
                                       options,
                                       prerequisiteDetails,
                                       previewDirectoryRoute,
                                       violations = [],
                                       hasSitewideCompleterSurvey = false,
                                       hasSitewideNonCompleterSurvey = false,
                                   } = {}) => {
    const basicInfo = {
        bgImageExtension: bgImageExtension || "",
        categoryIds: categoryIds || [],
        contact: contact || "",
        courseDescription: courseDescription || "",
        courseId: courseId || 0,
        courseTitle: courseTitle || "",
        externalCourseId: externalCourseId || "",
        externalDirectoryRoute: externalDirectoryRoute || "",
        followUpActivityFileName: followUpActivityFileName || "",
        followUpActivityFormFileId: followUpActivityFormFileId || "",
        hasPreview,
        hidden,
        allowReEnrollments,
        isActive,
        isValid,
        prerequisiteDetails: isArrayNullOrEmpty(prerequisiteDetails) ? [] : prerequisiteDetails,
        previewDirectoryRoute,
        violations,
        hasSitewideCompleterSurvey,
        hasSitewideNonCompleterSurvey,
    };

    const courseOptions = courseOptionsModel(options);
    return {...basicInfo, ...courseOptions};
};

export const courseOptionsModel = ({
                                       completeRequiredModulesInOrder = true,
                                       courseContentEnabled = true,
                                       demographicSurveyEnabled = true,
                                       demographicSurveyRequired = true,
                                       followUpActivityEnabled = true,
                                       followUpActivityRequired = true,
                                       inservicePoints,
                                       ashaPoints,
                                       otPtPoints,
                                       postAssessmentEnabled = true,
                                       postAssessmentRequired = true,
                                       preAssessmentEnabled = false,
                                       preAssessmentRequired = false,
                                       satisfactionSurveyEnabled = true,
                                       satisfactionSurveyRequired = true,
                                       completerSurveyEnabled = false,
                                       nonCompleterSurveyEnabled = false,
                                       subjectTags = [],
                                       surveyNotificationDaysAfterEnrollment = 0,
                                       usesModuleLastAccessed = false,
                                       usesCertificate = true
                                   } = {}) => ({
    completeRequiredModulesInOrder,
    courseContentEnabled,
    demographicSurveyEnabled,
    demographicSurveyRequired,
    followUpActivityEnabled,
    followUpActivityRequired,
    inservicePoints: inservicePoints || "",
    ashaPoints: ashaPoints || "",
    otPtPoints: otPtPoints || "",
    postAssessmentEnabled,
    postAssessmentRequired,
    preAssessmentEnabled,
    preAssessmentRequired,
    satisfactionSurveyEnabled,
    satisfactionSurveyRequired,
    completerSurveyEnabled,
    nonCompleterSurveyEnabled,
    subjectTags: isArrayNullOrEmpty(subjectTags) ? [] : subjectTags,
    surveyNotificationDaysAfterEnrollment,
    usesModuleLastAccessed,
    usesCertificate
});