import PropTypes from "prop-types";
import React from "react";
import {MapAdInfinitum} from "./index";
import {GridCell, GridContainer} from "../Grid";
import {convertToArray, isArrayNullOrEmpty} from "../../Utilities/Types/arrayUtilities";
import {isNullOrUndefined} from "../../Utilities/commonUtilities";

const GridContainerAdInfinitum = ({
                                      containerClassName,
                                      hasMarginX,
                                      hasMarginY,
                                      hasPaddingX,
                                      hasPaddingY,
                                      isFlush,
                                      isReverseOrder,
                                      component,
                                      yutes,
                                      noYutesMessage
                                }) => {
    yutes = convertToArray(yutes);
    if(yutes.length === 0 && (isNullOrUndefined(noYutesMessage) || noYutesMessage === ""))
        return null;

    return(
        <GridContainer hasMarginX={hasMarginX} hasMarginY={hasMarginY} hasPaddingX={hasPaddingX} hasPaddingY={hasPaddingY} isFlush={isFlush} isReverseOrder={isReverseOrder} className={containerClassName}>
            {
                isArrayNullOrEmpty(yutes)
                    ? <GridCell><p>{noYutesMessage}</p></GridCell>
                    : <MapAdInfinitum component={component} yutes={yutes}/>
            }
        </GridContainer>
    );
};

GridContainerAdInfinitum.propTypes = {
    containerClassName: PropTypes.string,
    hasMarginX: PropTypes.bool,
    hasMarginY: PropTypes.bool,
    hasPaddingX: PropTypes.bool,
    hasPaddingY: PropTypes.bool,
    isFlush: PropTypes.bool,
    isReverseOrder: PropTypes.bool,
    component: PropTypes.func.isRequired,
    yutes: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.element,
        PropTypes.bool,
        PropTypes.array,
    ]).isRequired,
    noYutesMessage: PropTypes.string
};

export default GridContainerAdInfinitum;