export const searchMockData = (allResults, criteria, searchResults, resultCourseField="enrollments") => {
	let courseId = parseInt(criteria.courseId);
	courseId = isNaN(courseId) ? 0 : courseId;

	let roleId = parseInt(criteria.roleId);
	roleId = isNaN(roleId) ? -1 : roleId;

	let surveyType = parseInt(criteria.surveyType);
	surveyType = isNaN(surveyType) ? 0 : surveyType;

	let status = parseInt(criteria.status);
	status = isNaN(status) ? 0 : status;
	
	if (criteria.siteGroupId !== "0")
		allResults = allResults.filter(f => f.siteGroupId === criteria.siteGroupId);

	if (roleId > -1)
		allResults = allResults.filter(f => f.roleId === roleId);

	if (surveyType > 0)
		allResults = allResults.filter(f => f.surveyType === surveyType);

	if (status > 0)
		allResults = allResults.filter(f => f.status === status);

	if(criteria.parentsOnly)
		allResults = allResults.filter(f => !f.parentId);

	let results = allResults
		.map((result) => {
			if (
				courseId === 0
				|| (result[resultCourseField] && result[resultCourseField].find(i => i.courseId === courseId))
			) {
				return searchResults({...result});
			}

			return null;
		})
		.filter((f) => f !== null);

	if (criteria.userInfo && criteria.userInfo !== "")
		results = results.filter(
			(user) =>
				user.firstName.includes(criteria.userInfo) ||
				user.lastName.includes(criteria.userInfo) ||
				user.email.includes(criteria.userInfo),
		);

	if (criteria.categoryInfo && criteria.categoryInfo !== "")
		results = results.filter(
			(category) =>
				category.title.includes(criteria.categoryInfo),
		);

	if (criteria.nameContains && criteria.nameContains !== "")
		results = results.filter((result) => result.title.includes(criteria.nameContains));

	if (criteria.isActive && criteria.isActive !== "")
		results = results.filter(f => f.isActive === criteria.isActive);

	if (criteria.isHidden && criteria.isHidden !== "")
		results = results.filter(f => f.hidden === criteria.isHidden);

	if (criteria.isValid && criteria.isValid !== "")
		results = results.filter(f => f.isValid === criteria.isValid);

	let sortProp = criteria.sortByColumn;
	let sortDir = criteria.sortDescending ? -1 : 1;

	results.sort(function (a, b) {
		return a[sortProp] > b[sortProp] ? sortDir : b[sortProp] > a[sortProp] ? -1 * sortDir : 0;
	});

	let recordsPerPage = results.length;
	if (criteria.recordsPerPage !== null) recordsPerPage = criteria.recordsPerPage;

	const skip = (criteria.page - 1) * recordsPerPage;
	const take = recordsPerPage + skip;

	let page = results.slice(skip, take);

	return {...{data: page, totalRecords: results.length}};
};
