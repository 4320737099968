import PropTypes from "prop-types";
import React from "react";
import { convertToArray } from "../../Utilities/Types/arrayUtilities";

const MapAdInfinitum = ({ component, yutes }) => {
    yutes = convertToArray(yutes);

    return yutes.map((child, index) => {
        if (!child) return null;

        const key = child.key || index;

        if (child.type && child.type.toString() === React.Fragment.toString()) {
            return child.props.children.map((grandchild, index) => {
                const key = child.key || index;
                return (
                    <MapAdInfinitum
                        key={key}
                        component={component}
                        yutes={grandchild}
                    />
                );
            });
        }
        else if (Array.isArray(child)) {
            return child.map((grandchild, index) => {
                const key = child.key || index;
                return (
                    <MapAdInfinitum
                        key={key}
                        component={component}
                        yutes={grandchild}
                    />
                );
            });
        }
        else if (React.isValidElement(child)) {
            return child;
        }
        else {
            return component(child, key);
        }
    });
};

MapAdInfinitum.propTypes = {
    component: PropTypes.func.isRequired,
    yutes: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.element,
        PropTypes.bool,
        PropTypes.array,
    ]).isRequired,
};

export default MapAdInfinitum;
