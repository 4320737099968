import PropTypes from "prop-types";
import React from "react";
import {locations} from "../../Utilities/Location";
import {PageNavigation, PageTitle} from "../Landmarks";

const EnrollmentNotAllowed = ({redirect}) => {

    const handleLogoutClick = () => {
        redirect(locations.SIGN_OUT.path);
    };

    return (
        <>
            <PageTitle h1={`Enrollment Not Allowed`} hasBorder/>
            <p>Administrators are not allowed to enroll in courses.  Please use a participant account to gain access to this course.</p>
            <PageNavigation handlePrimaryClick={handleLogoutClick} labelPrimary={"Logout"} />
        </>
    );
};

EnrollmentNotAllowed.propTypes = {
    redirect: PropTypes.func.isRequired,
};


export default EnrollmentNotAllowed;
