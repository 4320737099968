import {searchModel} from "../../../Utilities/Search/searchFactory";
import {SURVEY_STATUS_ID} from "../surveyConstants";

export const surveySearchResults = (attributes) => ({...attributes});

export const surveySearchModel = (userId, siteGroupId, courseId, surveyType, status) => {
	return Object.assign({}, searchModel(userId.toString(), "title", false, "title"), {
		courseId: courseId || "0",
		siteGroupId,
		status: status || SURVEY_STATUS_ID.Editing.toString(),
		surveyType: surveyType || "0",
		ClearSurveySearch: clearSurveySearch,
	});
};

function clearSurveySearch() {
	this.courseId = "0";
	this.status = "0";
	this.surveyType = "0";
	this.ClearSearch(false);
}