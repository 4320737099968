const NODE_ENV = process.env.NODE_ENV;
const NODE_ENV_PROD = "production";
const NODE_ENV_TEST = "test";
const SERVER_API_MODE = "SERVER_API_MODE";
const MOCK_API_MODE = "MOCK_API_MODE";
const SERVER_URL = NODE_ENV === NODE_ENV_PROD ? "" : "http://localhost:52601";
export const BACKGROUND_IMAGE_SERVER_URL = NODE_ENV === NODE_ENV_PROD ? "" : "http://localhost";

export const ALL_SITE_GROUP_ABBREVIATION = "All Sites";
export const APH_SITE_GROUP_ID = "1";
export const PDA_SITE_GROUP_ID = "2";
export const FCRR_SITE_GROUP_ID = "3";
export const FICW_SITE_GROUP_ID = "4";
export const DOH_SITE_GROUP_ID = "5";
export const DOE_OSS_SITE_GROUP_ID = "6";
export const FGRBI_SITE_GROUP_ID = "10";
export const EARLY_ACCESS_SITE_GROUP_ID = "11";
export const MAPR_SITE_GROUP_ID = "12";
export const MOCK_SITE_GROUP_ID = APH_SITE_GROUP_ID;

export const api = {
	environment: {
		MODE: NODE_ENV === NODE_ENV_PROD ? SERVER_API_MODE :
			NODE_ENV === NODE_ENV_TEST ? MOCK_API_MODE : SERVER_API_MODE, // mock or server
		DELAY: NODE_ENV_TEST ? 0 : 50
	},
	RETRIES: 10,
	modes: {
		MOCK: MOCK_API_MODE,
		SERVER: SERVER_API_MODE
	},
	URL: `${SERVER_URL}/api/`
};