import {MAPR_SITE_GROUP_ID, PDA_SITE_GROUP_ID} from "../../SiteConfig/siteConstants";
import {isObjectNullOrEmpty} from "../../Utilities/Types/objectUtilities";
import {courseTypes} from "../Course/courseConstants";

export function isValidUser(user) {
	return !isObjectNullOrEmpty(user) && !!user.userId && user.userId > 0;
}

export function separateEnrollments(enrollments = [], hasAllAccess = false, hasSingleCourse = false) {
	const enrollmentsWithoutCommunities = enrollments.filter(e => e.courseType !== courseTypes.ProfessionalCommunityCourse.id);

	if (hasSingleCourse)
		return {enrolled: enrollmentsWithoutCommunities, completed: []};

	const enrolled = enrollmentsWithoutCommunities.filter(e => !e.dateFinished || (e.courseType === courseTypes.RegularCourse.id && hasAllAccess));
	const completed = enrollmentsWithoutCommunities.filter(e => e.dateFinished && ((e.courseType === courseTypes.EventCourse.id || !hasAllAccess) || (e.courseType === courseTypes.Training.id || !hasAllAccess)));

	return {enrolled, completed};
}

export const UsesLocationForUserSearch = (siteGroupId) => {
	return siteGroupId !== PDA_SITE_GROUP_ID && siteGroupId !== MAPR_SITE_GROUP_ID;
}
