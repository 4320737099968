import PropTypes from "prop-types";
import React from "react";
import {isNullOrUndefined} from "../../../../Utilities/commonUtilities";
import {isInArray} from "../../../../Utilities/Types/arrayUtilities";
import {HtmlDisplay} from "../../../Display";
import {buttonIconClassPrefix, buttonIconType, buttonIconTypeList, buttonLabelClassPrefix} from "./buttonIconConstants";

const ButtonIcon = (
	{
		children,
		disabled,
		id,
		iconType,
		inputDescription,
		isInputAction,
		label,
		labelClassName = "",
		onClick
	}) => {

	if (!iconType || !isInArray(buttonIconTypeList, iconType)) return <> </>;

	const actionClass = isInputAction ? "buttonIcon--inputAction" : "";
	const disabledClass = disabled ? "is-disabled" : "";
	const buttonIconHasLabel = !isNullOrUndefined(label) ? "buttonIcon__hasLabel" : "";

	return (
		<>
			<span className={`buttonIcon ${buttonIconClassPrefix}${iconType} ${actionClass} ${disabledClass} ${buttonIconHasLabel}`}>
				<button
					id={id}
					className={`buttonIcon__button`}
					disabled={disabled}
					onClick={onClick}
					data-testid={id}
				>
					<HtmlDisplay html={inputDescription} className={`show-for-sr`} isInline/>
				</button>
				<span aria-hidden={true} className="buttonIcon__flag"/>
				{children}
			</span>
			{
				!isNullOrUndefined(label) &&
				<button
					id={`${id}_label`}
					onClick={disabled ? null : onClick}
					className={`buttonLabel ${labelClassName} ${buttonLabelClassPrefix}${iconType}`}
				>
					{
						typeof label === 'string' &&
						<HtmlDisplay html={label} isInline/>
					}
					{
						typeof label !== 'string' &&
						<>{label}</>
					}
				</button>
			}
		</>
	);
};

export const ButtonIconAdd = (props) => <ButtonIcon {...props} iconType={buttonIconType.ADD}/>;
export const ButtonIconAddOption = (props) => <ButtonIcon {...props} iconType={buttonIconType.ADD_OPTION}/>;
export const ButtonIconCertificate = (props) => <ButtonIcon {...props} iconType={buttonIconType.CERTIFICATE}/>;
export const ButtonIconCopyComponent = (props) => <ButtonIcon {...props} iconType={buttonIconType.COPY_COMPONENT}/>;
export const ButtonIconCollapse = (props) => <ButtonIcon {...props} iconType={buttonIconType.COLLAPSE}/>;
export const ButtonIconCollapseAll = (props) => <ButtonIcon {...props} iconType={buttonIconType.COLLAPSE_ALL}/>;
export const ButtonIconDelete = (props) => <ButtonIcon {...props} iconType={buttonIconType.DELETE}/>;
export const ButtonIconDeleteComponent = (props) => <ButtonIcon {...props} iconType={buttonIconType.DELETE_COMPONENT}/>;
export const ButtonIconEdit = (props) => <ButtonIcon {...props} iconType={buttonIconType.EDIT}/>;
export const ButtonIconExpand = (props) => <ButtonIcon {...props} iconType={buttonIconType.EXPAND}/>;
export const ButtonIconExpandAll = (props) => <ButtonIcon {...props} iconType={buttonIconType.EXPAND_ALL}/>;
export const ButtonIconDoneEditing = (props) => <ButtonIcon {...props} iconType={buttonIconType.DONE_EDITING}/>;
export const ButtonIconEnrollments = (props) => <ButtonIcon {...props} iconType={buttonIconType.ENROLLMENTS}/>;
export const ButtonIconFollowup = (props) => <ButtonIcon {...props} iconType={buttonIconType.DOWNLOAD_FOLLOWUP}/>;
export const ButtonIconImpersonate = (props) => <ButtonIcon {...props} iconType={buttonIconType.IMPERSONATE}/>;
export const ButtonIconContent = (props) => <ButtonIcon {...props} iconType={buttonIconType.CONTENT}/>;
export const ButtonIconSections = (props) => <ButtonIcon {...props} iconType={buttonIconType.SECTIONS}/>;
export const ButtonIconPages = (props) => <ButtonIcon {...props} iconType={buttonIconType.PAGES}/>;
export const ButtonIconModules = (props) => <ButtonIcon {...props} iconType={buttonIconType.MODULES}/>;
export const ButtonIconOrder = (props) => <ButtonIcon {...props} iconType={buttonIconType.ORDER}/>;
export const ButtonIconProfile = (props) => <ButtonIcon {...props} iconType={buttonIconType.PROFILE}/>;
export const ButtonIconRemove = (props) => <ButtonIcon {...props} iconType={buttonIconType.REMOVE}/>;
export const ButtonIconSave = (props) => <ButtonIcon {...props} iconType={buttonIconType.SAVE}/>;
export const InputClear = (props) => <ButtonIcon {...props} iconType={buttonIconType.CLEAR} isInputAction/>;
export const ButtonIconExit = (props) => <ButtonIcon {...props} iconType={buttonIconType.EXIT} isInputAction />;
export const ButtonIconView = (props) => <ButtonIcon {...props} iconType={buttonIconType.VIEW}/>;
export const ButtonIconUpload = (props) => {
	const {onClick, name} = props;
	const fileName = `${name}_file`;
	const onButtonClick = () => document.getElementById(fileName).click();

	return (
		<ButtonIcon {...props} iconType={buttonIconType.UPLOAD} onClick={onButtonClick}>
			{/* eslint-disable-next-line *//***********LINTER WRONG*************/}
			<label htmlFor={fileName}>
                <HtmlDisplay html={props.inputDescription} className={`show-for-sr`} isInline />
			</label>
			<input type="file"
			       data-testid={fileName}
			       name={fileName}
			       id={fileName}
			       onChange={onClick}
			/>
		</ButtonIcon>
	);
};

const sharedProps = {
	disabled: PropTypes.bool,
	id: PropTypes.string,
	inputDescription: PropTypes.string.isRequired,
	label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	labelClassName: PropTypes.string,
	onClick: PropTypes.func.isRequired,
};

ButtonIconAdd.propTypes = sharedProps;
ButtonIconCertificate.propTypes = sharedProps;
ButtonIconDelete.propTypes = sharedProps;
ButtonIconEdit.propTypes = sharedProps;
ButtonIconEnrollments.propTypes = sharedProps;
ButtonIconFollowup.propTypes = sharedProps;
ButtonIconImpersonate.propTypes = sharedProps;
ButtonIconContent.propTypes = sharedProps;
ButtonIconSections.propTypes = sharedProps;
ButtonIconPages.propTypes = sharedProps;
ButtonIconModules.propTypes = sharedProps;
ButtonIconOrder.propTypes = sharedProps;
ButtonIconRemove.propTypes = sharedProps;
ButtonIconProfile.propTypes = sharedProps;
InputClear.propTypes = sharedProps;
ButtonIconExit.propTypes = sharedProps;
ButtonIconView.propTypes = sharedProps;
ButtonIconUpload.propTypes = {
	...sharedProps,
	name: PropTypes.string.isRequired
};

ButtonIcon.propTypes = {
	...sharedProps,
	children: PropTypes.any,
	iconType: PropTypes.string.isRequired,
	isInputAction: PropTypes.bool,
};