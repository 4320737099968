import store from "store";
import {ROLES} from "../../Components/Authorize/roleConstants";
import {convertToBoolean, convertToNumber} from "../commonUtilities";
import { isNumber } from "../Types/typeUtilities";
import * as storageTypes from "./storageTypes";

export function isLoggedIn() {
	const userIdentity = store.get(storageTypes.CURRENT_USER);
	const isUserId = isNumber(userIdentity);
	const roleId = getRoleId();
	const isRoleId = isNumber(roleId);

	if (!isUserId && isRoleId)
		desistLogin();

	return isUserId;
}

export function getUserId() {
	const userIdentity = store.get(storageTypes.CURRENT_USER);
	const isUserId = isNumber(userIdentity);
	return isUserId ? userIdentity : 0;
}

export function isAdmin() {
	return convertToBoolean(store.get(storageTypes.IS_ADMIN));
}

export function getRoleId() {
	return convertToNumber(store.get(storageTypes.ROLE_ID));
}

export function getRoleIdOfImpersonator()
{
	return convertToNumber(store.get(storageTypes.ADMIN_ROLE_ID));
}

export function isSystemAdmin() {
	return getRoleId() === ROLES.SystemAdmin;
}

export function persistLogin(userIdentity, accessToken, refreshToken, isAdmin, roleId) {
	store.set(storageTypes.CURRENT_USER, userIdentity);
	store.set(storageTypes.ACCESS_TOKEN, accessToken);
	store.set(storageTypes.REFRESH_TOKEN, refreshToken);
	store.set(storageTypes.IS_ADMIN, isAdmin);
	store.set(storageTypes.ROLE_ID, roleId);
}

export function persistAdminRefreshToken() {
	store.set(storageTypes.ADMIN_REFRESH_TOKEN, store.get(storageTypes.REFRESH_TOKEN));
	store.set(storageTypes.ADMIN_ROLE_ID, store.get(storageTypes.ROLE_ID));
}

export function removeAdminRefreshToken() {
	store.remove(storageTypes.ADMIN_REFRESH_TOKEN);
	store.remove(storageTypes.ADMIN_ROLE_ID);
}

export function desistLogin() {
	store.remove(storageTypes.CURRENT_USER);
	store.remove(storageTypes.ACCESS_TOKEN);
	store.remove(storageTypes.REFRESH_TOKEN);
	store.remove(storageTypes.IS_ADMIN);
	store.remove(storageTypes.ROLE_ID);
	removeAdminRefreshToken();
}

export function getLoggedInUser() {
	return store.get(storageTypes.CURRENT_USER);
}

export function getAdminRefreshToken() {
	return store.get(storageTypes.ADMIN_REFRESH_TOKEN);
}

export function getAccessToken() {
	return "Bearer " + store.get(storageTypes.ACCESS_TOKEN);
}

export function saveJwtToken(token) {
	store.set(storageTypes.ACCESS_TOKEN, token);
}

export function saveRefreshToken(refreshToken) {
	store.set(storageTypes.REFRESH_TOKEN, refreshToken);
}

export function getJwtToken() {
	return store.get(storageTypes.ACCESS_TOKEN);
}

export function getRefreshToken() {
	return store.get(storageTypes.REFRESH_TOKEN);
}

const storageUtilitiesExport = {isLoggedIn, persistLogin, desistLogin, getLoggedInUser};
export default storageUtilitiesExport;