import {BACKGROUND_IMAGE_SERVER_URL} from "../SiteConfig/siteConstants";
import {isTrimmedStringEmpty} from "./commonUtilities";

export const courseBackground = "background__course";
export const cardBackground = "background__card";
const courseIcon = "icon icon--course";
export const moduleBackground = "background__module";
export const moduleIcon= "pageTitle__moduleTitle";
export const moduleTypeBackground = "background__moduleType__";
const pageIcon = "pageTitle--module";
const dashboardBackground = "dashboard--image";

function buildClassList(baseClass, courseId = "", externalCourseId = "") {
    let classes = !isTrimmedStringEmpty(courseId?.toString()) ? `${baseClass}${courseId}` : "";

    classes += !isTrimmedStringEmpty(externalCourseId?.toString()) ? ` ${baseClass}${externalCourseId}` : "";

    return classes;
}

export function getCourseIcon(courseId = "", externalCourseId = "", hasImage = false) {
    if(hasImage) return "";

    return buildClassList(courseIcon, courseId, externalCourseId);
}

export function getCourseImage(courseId = "", externalCourseId = "", hasImage = false) {
    if(!hasImage) return "";

    return buildClassList(courseBackground, courseId, externalCourseId);
}

export function getCardClass(courseId = "", externalCourseId = "") {
    return buildClassList(cardBackground, courseId, externalCourseId);
}

export function getModuleImage(id = "", type = "") {
    return `${moduleBackground}${id} ${moduleTypeBackground}${type}`;
}

export function getModuleType({allPageTypes, moduleType}) {
    const type = allPageTypes ? allPageTypes : moduleType;
    const typeClass = type.replace(/ /g, "").toLowerCase();
    return typeClass;
}

export function getPageIcon(id = "") {
    return `${pageIcon}${id}`;
}

export function getDashboardImage(hasImage = false) {
    return hasImage ? dashboardBackground : "";
}


export function setDbCourseImageStyle(allCourses = [], hasImage = false, forceRecreate = false) {
    const styleId = `styleTagCourseBgImages`;
    const oldElement = document.getElementById(styleId);

    if (!allCourses || allCourses.length === 0 || (oldElement && !forceRecreate))
        return;

    oldElement && oldElement.parentNode.removeChild(oldElement);

    let styles = "";
    for (let course of allCourses.filter(f => !isTrimmedStringEmpty(f.bgImageExtension))) {
        const imgSrc = `${BACKGROUND_IMAGE_SERVER_URL}/courseImages/${course.courseId}/background-course${course.courseId}${course.bgImageExtension}`;
        styles += hasImage
            ? `.card .${courseBackground}${course.courseId}:before {background-image: url(${imgSrc}) !important} `
            : `.icon.icon--course${course.courseId} {background-image: url(${imgSrc})} `
    }

    setBgImageStyles(styleId, styles);
}

export function setDbModuleImageStyle(courseId, modules = []) {
    const styleId = `styleTagModuleBgImages`;
    const oldElement = document.getElementById(styleId);
    oldElement && oldElement.parentNode.removeChild(oldElement);

    if (!modules || modules.length === 0 || !courseId)
        return;

    let styles = "";
    for (let module of modules.filter(f => !isTrimmedStringEmpty(f.bgImageExtension))) {
        const imgSrc = `${BACKGROUND_IMAGE_SERVER_URL}/courseImages/${courseId}/background-module${module.moduleId}${module.bgImageExtension}`;
        styles += `.card .${moduleBackground}${module.moduleId} {background-image: url(${imgSrc})} `;
    }

    setBgImageStyles(styleId, styles);
}

export function setDbPageTitleModuleStyle(courseId, moduleId, moduleBgImageExtension) {
    const styleId = `styleTagPageTitleImages`;
    const oldElement = document.getElementById(styleId);
    oldElement && oldElement.parentNode.removeChild(oldElement);

    if (!moduleId || !courseId || !moduleBgImageExtension)
        return;

    const imgSrc = `${BACKGROUND_IMAGE_SERVER_URL}/courseImages/${courseId}/background-module${moduleId}${moduleBgImageExtension}`;
    const styles = `.pageTitle.pageTitle--module${moduleId} .${moduleIcon}:before {background-image: url(${imgSrc}); content: ""} `;

    setBgImageStyles(styleId, styles);
}

function setBgImageStyles(styleId, styles) {
    if (isTrimmedStringEmpty(styles))
        return;

    const newElement = document.head.appendChild(document.createElement("style"));
    newElement.setAttribute("id", styleId);
    newElement.innerHTML = styles;
}