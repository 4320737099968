import React from "react";
import PropTypes from "prop-types";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

export const Loading = ({
                     isLoading
                 }) => {

    const {classes} = useStyles();

    return (
        <Backdrop open={isLoading} className={classes.backdrop}>
            <CircularProgress color="inherit" />
        </Backdrop>
    );
};

Loading.propTypes = {
    isLoading: PropTypes.bool
};