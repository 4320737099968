export const regions = [
    {regionId: 5, regionName: "Emerald Coast" },
    {regionId: 6, regionName: "PAEC" },
    {regionId: 7, regionName: "Miccosukee" },
    {regionId: 8, regionName: "Gateway" },
    {regionId: 9, regionName: "NEFEC" },
    {regionId: 10, regionName: "Crown" },
    {regionId: 11, regionName: "Springs" },
    {regionId: 12, regionName: "Action" },
    {regionId: 13, regionName: "East" },
    {regionId: 14, regionName: "Galaxy" },
    {regionId: 15, regionName: "Gulfcoast" },
    {regionId: 16, regionName: "Hillsborough" },
    {regionId: 17, regionName: "Sunrise" },
    {regionId: 18, regionName: "Suncoast" },
    {regionId: 19, regionName: "Heartland" },
    {regionId: 20, regionName: "Island Coast" },
    {regionId: 21, regionName: "Alpha" },
    {regionId: 22, regionName: "Reach" },
    {regionId: 23, regionName: "South" }
];

