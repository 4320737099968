import {isObjectNullOrEmpty} from "../../../Utilities/Types/objectUtilities";
import {allow, policyEvents} from "../../Authorize";

export const updateSearchCriteria = (userId, siteDetails, roleIdOfImpersonator, searchCriteria, setSearchCriteria,
                                     searchModel, canSearchAllSites = allow(policyEvents.ACCESS_ALL_SITE_GROUPS)) => {
	if (!userId || isObjectNullOrEmpty(siteDetails) || !siteDetails.siteGroupId) return;

	const searchCriteriaUserId = roleIdOfImpersonator && searchCriteria ? searchCriteria.pageId : userId.toString();

	if ((isObjectNullOrEmpty(searchCriteria) || searchCriteria.pageId !== searchCriteriaUserId)) {
		const siteGroupId = canSearchAllSites ? "" : siteDetails.siteGroupId;
		setSearchCriteria(searchModel(userId, siteGroupId));
	}
}