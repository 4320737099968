import PropTypes from "prop-types";
import {getRoleId} from "../../Utilities/LocalStorage/storageUtilities";
import { DefaultPolicy }  from "./DefaultPolicy";
import * as policyEvents from "./policyEventsConstants";

export const allow = (policyEvent = policyEvents.VIEW, allowSiteAdminsForPolicyEvent = false, roleId = getRoleId()) => {
    return Allow({policyEvent, roleId, allowSiteAdminsForPolicyEvent});
};

export const Allow = ({ children, policyEvent = policyEvents.VIEW, roleId = getRoleId(), allowSiteAdminsForPolicyEvent = false }) => {

    const policy = new DefaultPolicy(roleId, allowSiteAdminsForPolicyEvent);
    const isAllowed = policy.permit(policyEvent);

    if(!children)
        return isAllowed;

    if(isAllowed)
        return children;

    return null;
};

Allow.propTypes = {
    allowSiteAdminsForPolicyEvent: PropTypes.bool,
    children: PropTypes.any.isRequired,
    policyEvent: PropTypes.string,
    roleId: PropTypes.number,
};