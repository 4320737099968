import {searchModel} from "../../../Utilities/Search";
import {STATUS} from "../userConstants";

export const userSearchResults = (attributes) => ({...attributes});

export const userSearchModel = (userId, siteGroupId, userInfo, courseId, roleId, location, status) => {
    return Object.assign({}, searchModel(userId.toString(), "lastName", false, "lastName"), {
        userInfo: userInfo || "",
        courseId: courseId || "0",
        siteGroupId: siteGroupId || "0",
        roleId: roleId || "-1",
        location: location || "",
        status: status || STATUS.Active,
        ClearUserSearch: clearUserSearch,
    });
};

function clearUserSearch(includeSiteGroup, siteGroupId) {
    this.userInfo = "";
    this.courseId = "0";
    this.roleId = "-1";
    this.location = "";
    this.status = STATUS.Active;
    if(includeSiteGroup)
        this.siteGroupId = siteGroupId;
    this.ClearSearch(false);
}