import React from "react";
import {Switch, switchProps} from "./Switch";
import {InputLayout, inputLayoutProps} from "./InputLayout";

export const SwitchField = (props) => {
	return (
		<InputLayout {...props}>
			<Switch {...props} label={``}/>
		</InputLayout>
	);
};

SwitchField.propTypes = {
	...inputLayoutProps,
	...switchProps,
};
