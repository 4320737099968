import React, { createContext, useContext, useState } from "react";
import PropTypes from "prop-types";

export const NotificationContext = createContext([]);
export const useNotificationContext = () => useContext(NotificationContext);

export const NotificationContextProvider = ({
	                                            children
                             }) => {
	const [errors, setErrors] = useState([]);
	const [infos, setInfos] = useState([]);
	const [successes, setSuccesses] = useState([]);
	const [warnings, setWarnings] = useState([]);

	return (
		<NotificationContext.Provider value={{errors, infos, successes, warnings, setErrors, setInfos, setSuccesses, setWarnings}}>
			{children}
		</NotificationContext.Provider>
	);
};


NotificationContextProvider.propTypes = {
	children: PropTypes.any
};