import React from "react";
import {Allow, allow} from "./Allow";
import Authorize from "./Authorize";
import NotAuthorized from "./NotAuthorized";
import EnrollmentNotAllowed from "./EnrollmentNotAllowed";
import { TemplateMockAPH } from "../Layout";
import {redirect} from "../../Utilities/testingUtilities";
import * as policyEvents from "./policyEventsConstants";

const props = {redirect};

export const NotAuthorizedMock = (args) => <TemplateMockAPH><NotAuthorized {...props} {...args} /></TemplateMockAPH>;

export { Authorize, NotAuthorized, EnrollmentNotAllowed, Allow, allow, policyEvents };