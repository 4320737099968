import React from "react";
import PropTypes from "prop-types";

export const Switch = ({
						   activeLabel = `Yes`,
						   inactiveLabel = `No`,
						   label,
						   name,
						   onChange,
						   value,
					   }) => {

	return (
		<label className="switch large" htmlFor={name}>
			<div className={`show-for-sr`}>{label}</div>
			<input className="switch-input" id={name} data-testid={name} type="checkbox" name={name} checked={value}
				   onChange={onChange}/>
			<div className="switch-paddle" aria-hidden="true">
				<span className="switch-active">{activeLabel}</span>
				<span className="switch-inactive">{inactiveLabel}</span>
			</div>
		</label>
	);
};

export const switchProps = {
	activeLabel: PropTypes.string,
	inactiveLabel: PropTypes.string,
	label: PropTypes.string,
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	value: PropTypes.bool,
};

Switch.propTypes = {
	...switchProps,
};
