import React, {lazy, Suspense} from "react";
import {HashRouter, Redirect, Route} from "react-router-dom";
import {useAppContext} from "../../Components/StateManager/Context";
import {
    APH_SITE_GROUP_ID,
    DOE_OSS_SITE_GROUP_ID,
    DOH_SITE_GROUP_ID,
    EARLY_ACCESS_SITE_GROUP_ID,
    FCRR_SITE_GROUP_ID,
    FGRBI_SITE_GROUP_ID,
    FICW_SITE_GROUP_ID,
    MAPR_SITE_GROUP_ID,
    PDA_SITE_GROUP_ID,
} from "../siteConstants";
import {TemplateBasicHeaderNav} from "../../Components/Layout";
import {Loading} from "../../Components/Loading";

const RoutesAPH = lazy(() => import("./RoutesAPH"));
const RoutesDoeOss = lazy(() => import("./RoutesDoeOss"));
const RoutesDOH = lazy(() => import("./RoutesDOH"));
const RoutesEarlyAccess = lazy(() => import("./RoutesEarlyAccess"));
const RoutesFCRR = lazy(() => import("./RoutesFCRR"));
const RoutesFGRBI = lazy(() => import("./RoutesFGRBI"));
const RoutesFICW = lazy(() => import("./RoutesFICW"));
const RoutesMAPR = lazy(() => import("./RoutesMAPR"));
const RoutesPDA = lazy(() => import("./RoutesPDA"));
const RoutesShared = lazy(() => import("./RoutesShared"));

export const Router = () => {
    const {siteDetails} = useAppContext();
    if (!siteDetails) return null;

    const Routes = siteDetails.siteGroupId === APH_SITE_GROUP_ID ? RoutesAPH
        : siteDetails.siteGroupId === DOE_OSS_SITE_GROUP_ID ? RoutesDoeOss
            : siteDetails.siteGroupId === DOH_SITE_GROUP_ID ? RoutesDOH
                : siteDetails.siteGroupId === EARLY_ACCESS_SITE_GROUP_ID ? RoutesEarlyAccess
                    : siteDetails.siteGroupId === FCRR_SITE_GROUP_ID ? RoutesFCRR
                        : siteDetails.siteGroupId === FGRBI_SITE_GROUP_ID ? RoutesFGRBI
                            : siteDetails.siteGroupId === FICW_SITE_GROUP_ID ? RoutesFICW
                                : siteDetails.siteGroupId === MAPR_SITE_GROUP_ID ? RoutesMAPR
                                    : siteDetails.siteGroupId === PDA_SITE_GROUP_ID ? RoutesPDA
                                        : () => RoutesShared({TemplateBasicHeaderNav});
    return (
        <HashRouter>
            <Route exact path="/">
                <Redirect to="/home"/>
            </Route>
            <Suspense fallback={<Loading isLoading/>}>
                <Routes/>
            </Suspense>
        </HashRouter>
    );
};