import {isNullOrUndefined} from "../../../Utilities/commonUtilities";
import {searchModel} from "../../../Utilities/Search";

export const courseSearchResults = (attributes) => ({...attributes});

export const courseSearchModel = (userId, siteGroupId, nameContains, isActive, isHidden, isValid) => {
    return Object.assign({}, searchModel(userId.toString(), "courseTitle", false, "courseTitle"), {
        nameContains: nameContains ? nameContains : "",
        isActive: !isNullOrUndefined(isActive) ? isActive : "",
        isHidden: !isNullOrUndefined(isHidden) ? isHidden : "",
        isValid: isValid ? isValid : "",
        siteGroupId,
        ClearCourseSearch: clearCourseSearch,
    });
};

function clearCourseSearch() {
    this.nameContains = "";
    this.isActive = "";
    this.isHidden = "";
    this.isValid = "";
    this.ClearSearch(false);
}