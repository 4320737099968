import PropTypes from "prop-types";
import React from "react";
import {Link} from "react-scroll";

export const ScrollToLink = ({elementId, label, activeClass, offset = -180}) => {

	const handleClick = () => {
		document.getElementById(elementId).focus();
	};


	return (
		<Link href={`#${elementId}`} onClick={handleClick} activeClass={activeClass} to={elementId} spy={true}
		      smooth={`easeOutQuint`} duration={0}
		      offset={offset}>{label}</Link>
	);
};

ScrollToLink.propTypes = {
	activeClass: PropTypes.string,
	elementId: PropTypes.string,
	label: PropTypes.any,
	offset: PropTypes.number,
};
