import {isNullOrUndefined, isTrimmedStringEmpty} from "../../../Utilities/commonUtilities";
import { DefaultError } from "./Notification/DefaultError";
import { NoNotification } from "./Notification/NoNotification";
import { DefaultNotification } from "./Notification/DefaultNotification";
import { MultiErrorMessageNotification } from "./Notification/MultiErrorMessageNotification";
import { NotificationTypes } from "./Notification/NotificationTypes";
import { ToastrAdapter } from "./Toastr/ToastrAdapter";
import { catchError } from "../../../Utilities/Api/Requests";

export class NotificationFactory {
	constructor() {
		this._notificationAdapter = new ToastrAdapter();
	}

	clear(clearErrorWarningToastsOnly = false) {
		return this._notificationAdapter.clear(clearErrorWarningToastsOnly);
	}

	createNone() {
		return new NoNotification();
	}

	createSuccess(message) {
		return new DefaultNotification(this._notificationAdapter, NotificationTypes.SUCCESS, message);
	}

	createWarning(message) {
		return new DefaultNotification(this._notificationAdapter, NotificationTypes.WARNING, message);
	}

	createError(message) {
		return new DefaultNotification(this._notificationAdapter, NotificationTypes.ERROR, message);
	}

	createErrorsFromXhrObject(xhrObject) {
		if (xhrObject.status === 403)
			return new DefaultNotification(
				this._notificationAdapter,
				NotificationTypes.ERROR,
				"Please contact support to verify your access to this section.",
			);

		const responseText = !isNullOrUndefined(xhrObject.responseText) ? xhrObject.responseText : xhrObject.statusText;

		if (isTrimmedStringEmpty(responseText)) return new NoNotification();

		let errorResponseObject;

		try {
			errorResponseObject = JSON.parse(responseText);
		} catch (e) {
			catchError("The server did not return a valid error object.");
			catchError(e);
			catchError(JSON.stringify(xhrObject));
			return new DefaultError(this._notificationAdapter);
		}

		if (!errorResponseObject || !errorResponseObject.errors) {
			catchError("The server did not return a valid error object - errorResponseObject");
			catchError(errorResponseObject)
			return new DefaultError(this._notificationAdapter);
		}

		let notification;
		let totalErrors = errorResponseObject.totalErrors;

		if (totalErrors === 1)
			notification = new DefaultNotification(
				this._notificationAdapter,
				NotificationTypes.ERROR,
				errorResponseObject.errors[0].message,
			);
		else if (totalErrors > 1)
			notification = new MultiErrorMessageNotification(this._notificationAdapter, errorResponseObject.errors);
		else notification = new NoNotification();

		return notification;
	}

	createInfo(message) {
		let notification = new DefaultNotification(this._notificationAdapter, NotificationTypes.INFO, message);
		return notification;
	}
}
