import React from "react";
import { ContextPropsProvider } from "./Components/StateManager";
import { providers } from "./Components/StateManager/Context";
import { Router } from "./SiteConfig/Routes";
import "./robots.txt";
import '@progress/kendo-theme-material/dist/all.css';

const App = () => {
    return (
        <ContextPropsProvider providers={providers}>
            <Router/>
        </ContextPropsProvider>
    );
};

export default App;
