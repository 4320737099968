import Notification from "./Notification";

export class MultiErrorMessageNotification extends Notification {
	constructor(notificationAdapter, errorResponse) {
		super();
		this._notificationAdapter = notificationAdapter;
		this._notifications = [];

		errorResponse.forEach((errorGroup, index) => {
			let notificationGroup = {};

			let containsMultipleErrors =
				errorGroup.errors.length > 1 ||
				(errorGroup.errors.length === 1 && errorGroup.errors[0].errors.length > 1);
			notificationGroup.title = errorGroup.propertyName
				? `${errorGroup.propertyName} ${containsMultipleErrors ? "Errors" : "Error"}`
				: "";

			let message = "<ul>";
			let messageAsTitle = "";
			if (containsMultipleErrors) {
				if (errorGroup.message !== "") messageAsTitle = errorGroup.message;

				errorGroup.errors.forEach((item) => {
					if (item.errors.length > 0)
						item.errors.forEach((subItem) => (message += `<li>${subItem.message}</li>`));
					else message += `<li>${item.message}</li>`;
				});
			} else message += `<li>${errorGroup.message}</li>`;

			message += "</ul>";

			if (notificationGroup.title === "")
				notificationGroup.title = messageAsTitle !== "" ? messageAsTitle : `Error ${index + 1}`;

			notificationGroup.message = message;

			this._notifications.push(notificationGroup);
		});
	}

	notify() {
		this._notifications.forEach((notification) =>
			this._notificationAdapter.error(notification.message, notification.title),
		);
	}
}
