import PropTypes from "prop-types";
import React from "react";
import { isTrimmedStringEmpty } from "../../Utilities/commonUtilities";

const ProfileButton = ({ disabled, label, name, onClick, aria_controls }) => {
    if (isTrimmedStringEmpty(label)) return null;
    return (
        <button
            aria-controls={aria_controls}
            aria-haspopup="true"
            data-testid={name}
            id={name}
            onClick={onClick}
            className={`button button--profile`}
            disabled={disabled || !onClick}
        >
            {label}
        </button>
    );
};

ProfileButton.propTypes = {
    aria_controls: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    isCollapsed: PropTypes.bool,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onClick: PropTypes.func,
};

export default ProfileButton;
