import PropTypes from "prop-types";
import React from "react";
import {HtmlDisplay} from "../Display";
import {GridCell} from "../Grid";
import {inputType} from "./inputConstants";
import {Notification, notificationSuffix} from "./Notification";

export const CheckBoxItem = ({
                                 checked,
                                 disabled,
                                 hasTextField,
                                 label,
                                 name,
                                 onChange,
                                 onChangeOther,
                                 value,
                                 otherTextValue,
                             }) => {

    const otherTextInputName = `${name}_text`;

    return (
        <span className={`flex-container`}>
			{/* eslint-disable-next-line *//***********LINTER WRONG*************/}
            <label aria-disabled={disabled} className={``} htmlFor={name}>
				<input
                    aria-disabled={disabled}
                    type={inputType.CHECKBOX}
                    data-testid={name}
                    readOnly={disabled}
                    name={name}
                    id={name}
                    checked={checked}
                    onChange={onChange}
                    value={value}
                />
				<HtmlDisplay html={label} isInline/>
			</label>
            {
                hasTextField &&
                checked &&
                <>
                    <label className={`show-for-sr`} htmlFor={otherTextInputName}>{value}, requires additional
                        details</label>
                    <input
                        aria-disabled={disabled}
                        type={inputType.TEXT}
                        name={otherTextInputName}
                        data-testid={otherTextInputName}
                        id={otherTextInputName}
                        value={otherTextValue}
                        onChange={(event) => onChangeOther(value, event.target.value)}
                        readOnly={disabled}
                    />
                </>
            }
		</span>
    );
};
export const CheckBoxField = (props) => {
    const {error, name, label} = props;
    return (
        <GridCell
            aria-invalid={!!error}
            aria-errormessage={`${name}${notificationSuffix}`}
        >
            <CheckBoxItem {...props} />
            <Notification error={error} name={name} label={label}/>
        </GridCell>
    );
};

const checkBoxPropTypes = {
    checked: PropTypes.bool.isRequired,
    disabled: PropTypes.bool,
    hasTextField: PropTypes.bool,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    onChangeOther: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    otherTextValue: PropTypes.string,
};

CheckBoxItem.propTypes = {
    ...checkBoxPropTypes,
};

CheckBoxField.propTypes = {
    ...checkBoxPropTypes,
    error: PropTypes.string,
};

