import React from "react";
import PropTypes from "prop-types";
import {HtmlDisplay} from "./index";
import {professionalCommunitiesContentTypeClass} from "../../Containers/Course/courseConstants";

const SectionDisplay = ({
                            description,
                            type,
                            value,
                              }) => {


    return (
        <div className={getSectionTypeClass(type)}>
            <h2 className={type}>{description}</h2>
            <HtmlDisplay html={value}/>
        </div>
    );
};

export function getSectionTypeClass(type) {
    return professionalCommunitiesContentTypeClass[type];
}

SectionDisplay.propTypes = {
    description: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
};

export default React.memo(SectionDisplay);