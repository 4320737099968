import PropTypes from "prop-types";
import React from "react";
import {GridCell, gridConstants, GridContainer, GridFlexSpacer} from "../Grid";
import {Button} from "../Inputs";
import {ExternalLinkButton} from "../Link";
import {useAppContext} from "../StateManager/Context";

const PageNavigation = ({
                            disablePrimary = false,
                            externalUrlPrimary,
                            handlePrimaryClick,
                            handleSecondaryClick,
                            labelPrimary,
                            labelSecondary
                        }) => {

    const isCollapsed = !labelSecondary || !labelPrimary;
    const className = isCollapsed ? "text-center" : "";
    const { isLoading } = useAppContext();

    return (
        <GridContainer hasMarginX hasMarginY isFlush isReverseOrder>
            {
                !labelSecondary &&
                <GridFlexSpacer/>
            }
            {
                labelPrimary &&
                <GridCell medium_columns={gridConstants.column.FOUR} className={className}>
                    {
                        externalUrlPrimary &&
                        <ExternalLinkButton
                            isExpanded={!isCollapsed}
                            isPrimary
                            label={labelPrimary}
                            name={`btnPageNavigationPrimaryExternal`}
                            opensInNewTab
                            url={externalUrlPrimary}
                        />
                    }
                    {
                        handlePrimaryClick &&
                        <Button onClick={handlePrimaryClick} label={labelPrimary} isPrimary isCollapsed={isCollapsed}
                                disabled={disablePrimary || isLoading}
                                name={`btnPageNavigationPrimary`}/>
                    }
                </GridCell>
            }
            <GridFlexSpacer/>
            {
                labelSecondary &&
                <GridCell medium_columns={gridConstants.column.FOUR} className={className}>
                    <Button onClick={handleSecondaryClick} label={labelSecondary} isCollapsed={isCollapsed}
                            name={`btnPageNavigationSecondary`} disabled={isLoading}/>
                </GridCell>
            }
            {
                !labelPrimary &&
                <GridFlexSpacer/>
            }
        </GridContainer>
    );
};

PageNavigation.propTypes = {
    disablePrimary: PropTypes.bool,
    externalUrlPrimary: PropTypes.string,
    handlePrimaryClick: PropTypes.func,
    handleSecondaryClick: PropTypes.func,
    labelPrimary: PropTypes.string,
    labelSecondary: PropTypes.string
};

export default React.memo(PageNavigation);