import {searchModel} from "../../../Utilities/Search";

export const courseProfessionalCommunityEnrollmentsModel = ({
                                                                dateCreated,
                                                                dateLastAccessed,
                                                                email,
                                                                enrollmentId,
                                                                firstName,
                                                                lastName,
                                                                userId
                                                            } = {}) => ({
    dateCreated: dateCreated || "",
    dateLastAccessed: dateLastAccessed || "",
    email: email || "",
    enrollmentId: enrollmentId || 0,
    firstName: firstName || "",
    lastName: lastName || "",
    userId: userId || 0
});

export const professionalCommunityEnrollmentSearchModel = (userId) => {
    return Object.assign({}, searchModel(userId.toString(), "lastName", false,
        "lastName", 10), {
        ClearProfessionalCommunityEnrollmentSearch: clearProfessionalCommunityEnrollmentSearch,
    });
};

function clearProfessionalCommunityEnrollmentSearch() {
    this.ClearSearch(false);
}