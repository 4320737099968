import {
    config, mockRejectApiCall,
    mockResolveApiCall,
    requestOptions,
    requestTypes,
    sendRequest,
} from "../Utilities/Api";
import { MOCK_SITE_GROUP_ID } from "./siteConstants";
import {isNullOrUndefined} from "../Utilities/commonUtilities";
import { siteDetails } from "./siteDetailsMockData";

//************************************************** STANDARD API SERVER CALLS ****************************************

class ServerSiteDetailsApi {
    static getSiteDetails(siteGroupId) {
        if(isNullOrUndefined(siteGroupId))
            siteGroupId = "0";

        return sendRequest(
            requestOptions(
                config.URL + `sites/${siteGroupId}`,
                requestTypes.GET
            ),
        );
    }

    static getAllSiteDetails() {
        return sendRequest(
            requestOptions(
                config.URL + `sites/`,
                requestTypes.GET
            ),
        );
    }
}

//************************************************** MOCK API SERVER CALLS ****************************************

class MockSiteDetailsApi {
    static getSiteDetails(siteGroupId) {
        if(isNullOrUndefined(siteGroupId))
            siteGroupId = MOCK_SITE_GROUP_ID;

        const siteDetail = siteDetails.find(f => f.siteGroupId === siteGroupId);
        if(siteDetail)
            return mockResolveApiCall(siteDetail);
        else
            return mockRejectApiCall(`Error loading site details.`);
    }

    static getAllSiteDetails() {
        return mockResolveApiCall(siteDetails);
    }
}

export const siteDetailsApi = config.environment.MODE === config.modes.SERVER ? ServerSiteDetailsApi : MockSiteDetailsApi;
