/**
 * @typedef AjaxRequestOptions
 * @property {string} url
 * @property { import("./requestTypes").RequestTypes } type
 * Requests type
 * @property {object} [data]
 * Data to be sent with the request
 * @property {string} [contentType]
 * Content type of the data in the request
 * @property {string} [dataType]
 * Data type of the response data
 * @property {successMessage} [successMessage]
 * @property {function(successCallback, errorCallback):void} [setCallbacks]
 * Provide the success and error callbacks to use instead of the defaults.
 * Both success and error callbacks are required if either a success or an error
 * callback is provided.
 * @property {boolean} notifyOnError
 * This property only applies if an error callback isn't specified and
 * the default error callback is used.
 */

import {apiConstants} from "../apiConstants";

/**
 * Creates an ajax request options object that defines the configuration for an ajax request.
 * @param {string} url
 * The request url.
 * @param {symbol} type
 * { import("./RequestTypes").RequestTypes } type
 * Requests type enum (RequestTypes.GET, RequestTypes.POST, etc).
 * @param {object} [data]
 * Data to be sent with the request.
 * @param {string} [successMessage]
 * The success message to be displayed if the request was successful.
 * @param {?string} [contentType]
 * Content type of the data in the request
 * @param {?string} [enctype]
 * MIME type of content in the request
 * @returns {AjaxRequestOptions} a valid AjaxRequestOptions object
 */
const ajaxOptionsExport = (url, type, data, successMessage, contentType = null, enctype = null) => {
	/**
	 * @type {AjaxRequestOptions}
	 */
	let ajaxRequestOptions = {
		url: url,
		type: type,
		data: data,
		contentType: enctype ? false : (contentType ? contentType : apiConstants.contentType.JSON),
		dataType: null,
		enctype: enctype,
		responseType: null,
		xhrFields: {},
		processData: enctype ? false : null,
		cache: false,
		get successMessage() {
			return this._successMessage;
		},
		set successMessage(message) {
			this._successMessage = message;
			this._successCallback = null;
		},
		get successCallback() {
			return this._successCallback;
		},
		setCallbacks(successCallback, errorCallback= null) {
			if (isFunction(successCallback)) {
				this._successMessage = null;
				this._successCallback = successCallback;
			}
			if(isFunction(errorCallback))
				this._errorCallback = errorCallback;
		},
		get errorCallback() {
			return this._errorCallback;
		},
		notifyOnError: true,
	};

	if (successMessage) ajaxRequestOptions.successMessage = successMessage;

	return ajaxRequestOptions;

	function isFunction(obj) {
		return typeof obj === "function";
	}
};

export default ajaxOptionsExport;

/**
 * Setting the success message will remove the success callback
 * @callback successMessage
 * @param {string} message
 * Message to display if the request was successful.
 * @returns {void}
 */

/**
 * @callback successCallback
 * @param {Object} data
 * Data from the response body.
 * @param {string} status
 * Text status from the ajax request.
 * @param {Object} xhrObject
 * xhrObject from the response.
 * @returns {Object} Requests response.
 */

/**
 * @callback errorCallback
 * @param {Object} xhrObject
 * xhrObject from the response.
 * @returns {Object} xhrObject from the response.
 */
