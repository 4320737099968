import PropTypes from "prop-types";
import React from "react";
import {getButtonClasses} from "../inputUtility";

const ButtonFactory = ({className, disabled, label, name, onClick}) => {

    return (
        <button
            data-testid={name}
            id={name}
            onClick={onClick}
            className={className}
            disabled={disabled || !onClick}
        >
            {label}
        </button>
    );
};

const Button = (props) => {
    const {buttonType, isCollapsed, isPrimary, notCentered} = props;
    const className = getButtonClasses({isExpanded: !isCollapsed, isPrimary, buttonType, notCentered});
    return <ButtonFactory className={className} {...props}/>;
}
const TertiaryButton = (props) => {
    const {buttonType, isCollapsed, notCentered} = props;
    const className = getButtonClasses({isExpanded: !isCollapsed, isTertiary: true, buttonType, notCentered});
    return <ButtonFactory className={className} {...props}/>;
}

const sharedProps = {
    buttonType: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    isCollapsed: PropTypes.bool,
    label: PropTypes.any.isRequired,
    name: PropTypes.string.isRequired,
    notCentered: PropTypes.bool,
    onClick: PropTypes.func,
};

const buttonsProps = {
    isPrimary: PropTypes.bool,
}

Button.propTypes = {
    ...buttonsProps,
    ...sharedProps
};

ButtonFactory.propTypes = {
    ...sharedProps
};

TertiaryButton.propTypes = {
    ...sharedProps,
};

export {Button, TertiaryButton};