import React from "react";
import { locations } from "../../Utilities/Location";
import { GridCell, GridContainer } from "../Grid";
import { Link } from "../Link";
import PropTypes from "prop-types";

const HeaderOneColumn = ({label}) => {

    return (
        <header className="header">
            <GridContainer hasMarginX>
                <GridCell className={`logo`}>
                    <div className={`logo__layer1`}>
                        <div className={`logo__layer2`}>
                            <Link to={locations.LANDING.link.to} label={label}/>
                        </div>
                    </div>
                </GridCell>
            </GridContainer>
        </header>
    );
};

HeaderOneColumn.propTypes = {
    label: PropTypes.string.isRequired,
};

export default HeaderOneColumn;