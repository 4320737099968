import React from "react";
import PropTypes from 'prop-types';

export const Main = ({
	                     children,
                     }) => {


	return (
		<main className={`main`} id={`main`}>
			<div id={"toast-container"} aria-live={`assertive`}/>
			{children}
		</main>
	);
};

Main.propTypes = {
	children: PropTypes.any,
};