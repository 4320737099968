import {
    apiConstants,
    config,
    mockResolveApiCall,
    requestOptions,
    requestTypes,
    sendRequest,
    downloadBlob, mockRejectApiCall
} from "../../Utilities/Api";
import {addFollowUpFile} from "../User/userMockData";

//************************************************** STANDARD API SERVER CALLS ****************************************

class ServerFollowUpApi {
    static uploadFollowUpFile(files, courseId, userId, displaySuccessMessage = false) {
        let data = new FormData();

        // Currently the back end only support single file upload
        // so we need to throw an error if we receive more than one file
        if (files.length > 1)
            throw new Error('File upload limit exceeded. Only one file can be uploaded at a time.');

        data.append('file', files[0]);

        let request = requestOptions(
            `${config.URL}files/courses/${courseId}/users/${userId}`,
            requestTypes.POST,
            data,
            displaySuccessMessage ? apiConstants.successMessage.file.UPLOAD : "");

        request.processData = false;
        request.enctype = apiConstants.contentType.MULTI;
        request.contentType = false;

        return sendRequest(request);
    }

    static updateEnrollmentFollowUp(userId, enrollmentId, fileId, creditOptionSelected) {
        const followUpUploaded = {creditOptionSelected: creditOptionSelected};
        return sendRequest(
            requestOptions(
                `${config.URL}Users/${userId}/Enrollments/${enrollmentId}/FollowUp/${fileId}`,
                requestTypes.PUT,
                JSON.stringify(followUpUploaded),
                apiConstants.successMessage.followUp.UPDATE_FOLLOW_UP
            ),
        );
    }

    static downloadFollowUpFile(fileId) {
        return downloadBlob(
            requestOptions(
                `${config.URL}files/${fileId}`,
                requestTypes.GET,
                null,
                apiConstants.successMessage.file.DOWNLOAD
            ),
        );
    }
}

//************************************************** MOCK API SERVER CALLS ****************************************

class MockFollowUpApi {
    static uploadFollowUpFile() {
        return mockResolveApiCall(10);
    }

    static updateEnrollmentFollowUp(userId, enrollmentId, fileId, creditOptionSelected) {
        const result = addFollowUpFile(userId, enrollmentId, fileId, creditOptionSelected);
        if (result)
            return mockResolveApiCall(result, apiConstants.successMessage.file.UPLOAD);
        else
            return mockRejectApiCall(`Error: userId: ${userId}, enrollmentId: ${enrollmentId}, result: ${result}`);
    }

    static downloadFollowUpFile() {
        return mockResolveApiCall("FILE", apiConstants.successMessage.file.DOWNLOAD);
    }
}

const FollowUpApi = config.environment.MODE === config.modes.SERVER ? ServerFollowUpApi : MockFollowUpApi;
export default FollowUpApi;