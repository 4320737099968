import PropTypes from "prop-types";
import {useLayoutEffect} from "react";
import {isValidUser} from "../../Containers/User/userUtilities";
import {getCurrentLocation, locationBehaviors, locations} from "../../Utilities/Location";

const Authorize = ({
                       fetchUser,
                       isLoggedIn,
                       isAdmin,
                       path,
                       pathSearch,
                       pathUrl,
                       redirect,
                       returnUrl,
                       setReturnUrl,
                       user,
                   }) => {

    useLayoutEffect(() => {
        const currentLocation = getCurrentLocation(path);

        if (!isLoggedIn && (currentLocation[locationBehaviors.requiresAuthentication] || currentLocation[locationBehaviors.requiresAdminAuthentication])) {
            if (!currentLocation[locationBehaviors.doNotRedirectBack])
                setReturnUrl(`${pathUrl}${pathSearch}`);
            redirect(locations.SIGN_IN.path);
        }

        if (isLoggedIn && currentLocation[locationBehaviors.requiresAdminAuthentication] && !isAdmin) {
            redirect(locations.NOT_AUTHORIZED.path);
        }

        if (isLoggedIn && returnUrl) {
            redirect(returnUrl);
            setReturnUrl(undefined)
        } else if (isLoggedIn && currentLocation[locationBehaviors.redirectToDashboard]) {
            const homePath = locations.DASHBOARD.path;
            redirect(homePath);
        }

    }, [isLoggedIn, path]);

    useLayoutEffect(() => {
        if (isLoggedIn && !isValidUser(user))
            fetchUser();
    }, [user, isLoggedIn]);

    return null;
};

Authorize.propTypes = {
    fetchUser: PropTypes.func.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    path: PropTypes.string.isRequired,
    pathSearch: PropTypes.string.isRequired,
    pathUrl: PropTypes.string.isRequired,
    redirect: PropTypes.func.isRequired,
    returnUrl: PropTypes.string,
    setReturnUrl: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
};

export default Authorize;