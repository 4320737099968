import PropTypes from "prop-types";
import React from "react";
import {GridContainer, GridFlexSpacer} from "../Grid";
import {HeaderBasic} from "./HeaderBasic";
import {Main} from "./Main";

const TemplateHeaderHero = ({
                                     children,
                                     Header = HeaderBasic
                                 }) => {
    return (
        <>
            <Header/>
            <Main>
                <div className={`hero`}></div>
                <GridContainer hasMarginX hasMarginY>
                    <GridFlexSpacer>
                        {children}
                    </GridFlexSpacer>
                </GridContainer>
            </Main>
        </>
    );
};

TemplateHeaderHero.propTypes = {
    children: PropTypes.any,
    Header: PropTypes.element,
};

export default TemplateHeaderHero;
