import PropTypes from "prop-types";
import React from "react";
import {HeaderBasic} from "./HeaderBasic";
import {Main} from "./Main";

const TemplateHeaderFullWidth = ({
                                children,
                                Header = HeaderBasic
                            }) => {
    return (
        <>
            <Header/>
            <Main>
                {children}
            </Main>
        </>
    );
};

TemplateHeaderFullWidth.propTypes = {
    children: PropTypes.any,
    Header: PropTypes.element,
};

export default TemplateHeaderFullWidth;
