import React from "react";
import {InputAdapter, numberProps} from "./InputAdapter";
import {inputType} from "./inputConstants";

export const NumberField = (props) => {
	let {max, min, value} = props;
	value = value > max ? max : value;
	value = value < min ? min : value;

	return <InputAdapter {...props} value={value} inputType={inputType.NUMBER}/>;
};

NumberField.propTypes = {...numberProps};