export const isCourseEventEnabled = (siteGroupId, allSiteDetails) => {
    const siteDetails = allSiteDetails.find(f => f.siteGroupId.toString() === siteGroupId.toString());
    if (!siteDetails)
        return false;

    return siteDetails.hasCourseEvent;
}

export const isProfessionalCommunitiesEnabled = (siteGroupId, allSiteDetails) => {
    const siteDetails = allSiteDetails.find(f => f.siteGroupId.toString() === siteGroupId.toString());
    if (!siteDetails)
        return false;

    return siteDetails.hasProfessionalCommunities;
}

export function getContentSupportContactDetails(siteDetails) {
    return siteDetails.contentSupportLink
        ? `submit a ${siteDetails.contentSupportLink}`
        : `contact <a href="mailto:${siteDetails.contentSupportEmail}">${siteDetails.contentSupportEmail}</a>`;
}

export const findSiteAbbreviationForProperties = (siteGroupId, allSiteDetails) => {
    const siteDetails = allSiteDetails.find(f => f.siteGroupId.toString() === siteGroupId.toString());
    if (!siteDetails)
        return "";

    return getSiteAbbreviationForProperties(siteDetails);
}

export const getSiteAbbreviationForProperties = (siteDetails) => {
    return siteDetails.siteAbbreviation.replace(' ', '').toLowerCase();
}

