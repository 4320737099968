import PropTypes from "prop-types";
import React from "react";
import {HtmlDisplay} from "../Display";

export const helpTextSuffix = "_helpText";

export const HelpText = ({
                             helpText,
                             helpTextClass = "",
                             responseFeedback,
                             isCentered,
                             name,
                         }) => {
    const responseFeedbackClass = responseFeedback ? `help-text--feedback` : ``;
    return <HtmlDisplay
        className={`help-text ${helpTextClass} ${responseFeedbackClass} ${isCentered ? "text-center" : ""}`}
        isUpdatedLive
        html={responseFeedback || helpText} name={`${name}${helpTextSuffix}`}
    />;
};

HelpText.propTypes = {
    helpText: PropTypes.string,
	helpTextClass: PropTypes.string,
    responseFeedback: PropTypes.string,
    isCentered: PropTypes.bool,
    name: PropTypes.string.isRequired,
};