import PropTypes from "prop-types";
import React from "react";
import gridConstants from "../Grid/gridConstants";

const GridFlexSpacer = ({ children }) => {
    const hideForPrintClass = (!children) ? "hidden-print" : "";

    return <div className={`${gridConstants.class.CELL} ${gridConstants.column.AUTO} ${hideForPrintClass}`}>{children}</div>;
};

GridFlexSpacer.propTypes = {
    children: PropTypes.any,
};

export default GridFlexSpacer;
