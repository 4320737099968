import PropTypes from "prop-types";
import React from "react";
import {isTrimmedStringEmpty} from "../../Utilities/commonUtilities";
import { getButtonClasses } from "../Inputs/inputUtility";
import { Link } from "./Link";

export const LinkButton = ({ className, isExpanded, isLarge, isPrimary, label, to, handleClick = () => {} }) => {
    const defaultClassNames = getButtonClasses({ isExpanded, isPrimary, isLarge });

    className = !isTrimmedStringEmpty(className) ? `${className} ${defaultClassNames}` : defaultClassNames;

    return <Link to={to} label={label} className={className} handleClick={handleClick}/>;
};

export const TertiaryLinkButton = ({ className, isExpanded, isLarge, label, to }) => {
	const defaultClassNames = getButtonClasses({ isTertiary: true, isExpanded, isLarge });

    className = !isTrimmedStringEmpty(className) ? `${className} ${defaultClassNames}` : defaultClassNames;

	return <Link to={to} label={label} className={className}/>;
};

const sharedProps = {
    isExpanded: PropTypes.bool,
    isLarge: PropTypes.bool,
    isPrimary: PropTypes.bool,
    label: PropTypes.any,
    to: PropTypes.string.isRequired,
};


const buttonsProps = {
	isPrimary: PropTypes.bool,
}

LinkButton.propTypes = {
	...buttonsProps,
	...sharedProps,
    className: PropTypes.string,
    handleClick: PropTypes.func
};

TertiaryLinkButton.propTypes = {
	...sharedProps,
    className: PropTypes.string
};