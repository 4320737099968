export const courseEventDetailsModel = ({
                                            closingCode,
                                            courseId,
                                            courseTitle,
                                            defaultParticipantCompletionDate,
                                            isActive = true,
                                            openingCode,
                                            options
                                        } = {}) => {
    const basicInfo = {
        closingCode: closingCode || "",
        courseId: courseId || 0,
        courseTitle: courseTitle || "",
        defaultParticipantCompletionDate: defaultParticipantCompletionDate || "",
        isActive,
        openingCode: openingCode || ""
    };

    const courseOptions = courseEventOptionsModel(options);
    return {...basicInfo, ...courseOptions};
};

export const courseEventOptionsModel = ({
                                            inservicePoints
                                        } = {}) => ({
    inservicePoints: inservicePoints || ""
});