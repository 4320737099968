import {
    config,
    mockResolveApiCall,
    requestOptions,
    requestTypes,
    sendRequest,
} from "../Utilities/Api";
import {dynamicSort} from "../Utilities/Sort";
import { regions } from "./regionsMockData";

//************************************************** STANDARD API SERVER CALLS ****************************************

class ServerRegionsApi {
    static getRegions() {
        return sendRequest(
            requestOptions(
                config.URL + `regions/`,
                requestTypes.GET
            ),
        );
    }

    static getRegionsForSite(siteGroupId) {
        return sendRequest(
            requestOptions(
                config.URL + `regions/${siteGroupId}`,
                requestTypes.GET
            ),
        );
    }
}

//************************************************** MOCK API SERVER CALLS ****************************************

class MockRegionsApi {
    static getRegions() {
        return mockResolveApiCall(regions.sort(dynamicSort("regionName")));
    }

    static getRegionsForSite() {
        return mockResolveApiCall(regions.sort(dynamicSort("regionName")));
    }
}

export const regionsApi = config.environment.MODE === config.modes.SERVER ? ServerRegionsApi : MockRegionsApi;
