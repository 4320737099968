import {useEffect} from "react";
import {useHistory} from "react-router-dom";
import * as ReactGA from "react-ga";
import ga4 from "react-ga4";
import {isNullOrUndefined} from "./commonUtilities";

export const useAnalyticPageViews = (siteDetails, gaInitialized) => {
    const history = useHistory();
    const hash = history?.location?.hash;
    useEffect(() => {
        if (!siteDetails || isNullOrUndefined(siteDetails.gaTrackingCode) || !gaInitialized)
            return;

        ReactGA.pageview(hash);
        ga4.send({hitType: "pageview", page: hash});

    }, [hash, siteDetails, gaInitialized]);
};

export const initializeAnalytics = (GA_TRACKING_CODE) => ReactGA.initialize(GA_TRACKING_CODE);
export const initializeAnalytics4 = (GA_TRACKING_CODE) => ga4.initialize(GA_TRACKING_CODE);
