import {
    apiConstants,
    config,
    mockRejectApiCall,
    mockResolveApiCall,
    requestOptions,
    requestTypes,
    sendRequest,
} from "../../Utilities/Api";
import { getLoggedInUser } from "../../Utilities/LocalStorage/storageUtilities";
import {
    accountExists,
    addAccount,
    addEnrollment,
    completeModule,
    getAccount,
    getAccounts,
    startModule,
    updateAccount
} from "./userMockData";
import {ROLES} from "../../Components/Authorize/roleConstants";

//************************************************** STANDARD API SERVER CALLS ****************************************

class ServerUserApi {
    static attemptLogin(signInModel) {
        return sendRequest(
            requestOptions(config.URL + "Authentication/Login/",
                requestTypes.POST,
                JSON.stringify(signInModel),
            ),
        );
    }

    static attemptRegister(registerModel) {
        let attemptRegisterOptions = requestOptions(config.URL + "Registration/",
                requestTypes.POST,
                JSON.stringify(registerModel),
                apiConstants.successMessage.user.REGISTER
            );

        attemptRegisterOptions.setCallbacks((data, status, xhrObject, resolve, notificationFactory) => {
                data.notifier = data.isValidEmail
                    ? notificationFactory.createSuccess(apiConstants.successMessage.user.REGISTER)
                    : notificationFactory.createWarning(apiConstants.successMessage.user.REGISTER_WITH_INVALID_EMAIL);

                resolve(data);
            });
       return sendRequest(attemptRegisterOptions);
    }

    static getUser() {
        const userId = getLoggedInUser();
        return sendRequest(
            requestOptions(config.URL + `Users/${userId}`,
                requestTypes.GET,
                JSON.stringify(userId),
            ),
        );
    }

    static enroll(courseId, userId) {
        return sendRequest(
            requestOptions(
                config.URL + `Users/${userId}/Enrollment/${courseId}`,
                requestTypes.POST,
            ),
        );
    }

    static reEnroll(courseId, userId, enrollmentId) {
        return sendRequest(
            requestOptions(
                config.URL + `Users/${userId}/Enrollment/${enrollmentId}/ReEnroll/${courseId}`,
                requestTypes.POST,
            ),
        );
    }

    static getUserEnrollments(emailFromOldAccount, emailFromNewAccount) {
        return sendRequest(
            requestOptions(
                `${config.URL}Enrollments/Duplicates`,
                requestTypes.GET,
                { emailFromOldAccount, emailFromNewAccount },
            ),
        );
    }

    static  mergeEnrollments(mergeEnrollmentModel) {
        return  sendRequest(
            requestOptions(
                `${config.URL}Enrollments/Merge`,
                requestTypes.PUT,
                JSON.stringify(mergeEnrollmentModel),
                apiConstants.successMessage.user.MERGE_USERS,
            ),
        );
    }


    static moduleProgress(userId, enrollmentId, moduleId, pageId) {
        const moduleProgressUrl = config.URL + `Users/${userId}/Enrollment/${enrollmentId}/Module/${moduleId}`;
        return sendRequest(
            requestOptions(
                !pageId
                    ? moduleProgressUrl
                    : `${moduleProgressUrl}/Pages/${pageId}`,
                requestTypes.PUT,
                null,
            ),
        );
    }

    static moduleComplete(userId, enrollmentId, moduleId, pageId) {
        const moduleProgressUrl = config.URL + `Users/${userId}/Enrollment/${enrollmentId}/Module/${moduleId}`;
        return sendRequest(
            requestOptions(
                !pageId
                ? `${moduleProgressUrl}/Complete`
                : `${moduleProgressUrl}/Pages/${pageId}/Complete`,
                requestTypes.PUT,
                null,
            ),
        );
    }

    static updateUserProfile(userProfileModel, userId) {
        return sendRequest(
            requestOptions(config.URL + `Users/${userId}`,
                requestTypes.PUT,
                JSON.stringify(userProfileModel),
                apiConstants.successMessage.user.UPDATE_PROFILE,
            ),
        );
    }

    static getUserTokenAsAdmin(userId) {
        return sendRequest(
            requestOptions(config.URL + `Authentication/users/${userId}`,
                requestTypes.GET
            ),
        );
    }

    static getUserTokenFromRefreshToken(token) {
        return sendRequest(
            requestOptions(config.URL + `Authentication/RefreshToken`,
                requestTypes.GET,
                {refreshToken: token}
            ),
        );
    }

    static importUserFile(files) {
        if (files.length > 1)
            throw new Error('File upload limit exceeded. Only one file can be uploaded at a time.');

        let formData = new FormData();
        formData.append('file', files[0]);
        let request = requestOptions(
            `${config.URL}users/import`,
            requestTypes.POST,
            formData,
            apiConstants.successMessage.user.USER_IMPORT_SUCCESS);

        request.processData = false;
        request.enctype = 'multipart/form-data';
        request.contentType = false;

        return sendRequest(request);
    }

    static importTrackingStudentFile(files) {
        if (files.length > 1)
            throw new Error('File upload limit exceeded. Only one file can be uploaded at a time.');

        let formData = new FormData();
        formData.append('file', files[0]);
        let request = requestOptions(
            `${config.URL}users/import/trackingStudents`,
            requestTypes.POST,
            formData,
            apiConstants.successMessage.user.TRACKING_STUDENT_IMPORT_SUCCESS);

        request.processData = false;
        request.enctype = 'multipart/form-data';
        request.contentType = false;

        return sendRequest(request);
    }
}

//************************************************** MOCK API SERVER CALLS ****************************************

class MockUserApi {
    static attemptLogin(signInModel) {
        const accounts = getAccounts();
        const userAcct = accounts.find((account) =>
	        (account.roleId === ROLES.SystemAdmin || account.siteGroupId === signInModel.siteGroupId) &&
            account.email === signInModel.email &&
            account.password === signInModel.password);

        if (!userAcct) {
            return mockRejectApiCall("There was an error while attempting to login.");
        }
        return mockResolveApiCall(userAcct);
    }

    static attemptRegister(registerModel) {
        if (accountExists(registerModel.email)) {
            return mockRejectApiCall("Error, that email address is already registered");
        }
        const registeredAccount = addAccount(registerModel);
        return mockResolveApiCall(registeredAccount, apiConstants.successMessage.user.REGISTER);
    }

    static getUser() {
        let userId = getLoggedInUser();
        if (!userId) userId = 0;

        const userAcct = getAccount(userId);

        if (!userAcct) {
            return mockRejectApiCall("User not found");
        }

        return mockResolveApiCall(userAcct);
    }

    static enroll(courseId, userId) {
        const result = addEnrollment(courseId, userId);
        if (result > 0)
            return mockResolveApiCall(result);
        else
            return mockRejectApiCall(`Error: courseId: ${courseId}, userId: ${userId}, result: ${result}`);
    }

    static moduleProgress(userId, enrollmentId, moduleId, pageId) {
        const result = startModule(userId, enrollmentId, moduleId, pageId);
        if (result)
            return mockResolveApiCall(result);
        else
            return mockRejectApiCall(`Error: userId: ${userId}, enrollmentId: ${enrollmentId}, moduleId: ${moduleId}, pageId: ${pageId}, result: ${result}`);
    }


    static moduleComplete(userId, enrollmentId, moduleId, pageId) {
        const result = completeModule(userId, enrollmentId, moduleId, pageId);
        if (result)
            return mockResolveApiCall(result);
        else
            return mockRejectApiCall(`Error: userId: ${userId}, enrollmentId: ${enrollmentId}, moduleId: ${moduleId}, pageID: ${pageId}, result: ${result}`);
    }

    static updateUserProfile(userProfileModel, userIdString) {
        let userId = parseInt(userIdString);
        if (isNaN(userId)) userId = 0;

        if(userId > 0) {
            const userAcct = getAccount(userId);

            if (!userAcct) {
                return mockRejectApiCall("User not found");
            }
        }

        const updatedAccount = userId === 0 ? addAccount(userProfileModel) : updateAccount(userProfileModel, userId);
        return mockResolveApiCall(updatedAccount, apiConstants.successMessage.user.UPDATE_PROFILE);
    }

    static getUserTokenAsAdmin(userId) {
        const accounts = getAccounts();
        const userAcct = accounts.find((account) => account.userId === userId);

        if (!userAcct) {
            return mockRejectApiCall("There was an error retrieving the user account.");
        }
        return mockResolveApiCall(userAcct);
    }

    static getUserTokenFromRefreshToken(token) {
        const accounts = getAccounts();
        const userAcct = accounts.find((account) => account.refreshToken === token);

        if (!userAcct) {
            return mockRejectApiCall("There was an error retrieving the user account.");
        }
        return mockResolveApiCall(userAcct);
    }

    static importUserFile() {
        return mockResolveApiCall({});
    }

    static importTrackingStudentFile() {
        return mockResolveApiCall({});
    }

    static mergeEnrollments() {
        return mockResolveApiCall({});
    }

    static getUserEnrollments() {
        return mockResolveApiCall({});
    }
}

const AccountApi = config.environment.MODE === config.modes.SERVER ? ServerUserApi : MockUserApi;
export default AccountApi;