export const apiConstants = {
    apiRedirectPath: "/",
    contentType: {
        JSON: `application/json`,
        MULTI: `multipart/form-data`
    },
    successMessage: {
        GENERIC: `Success`,
        user: {
            FORGOT_PASSWORD: `Email sent. Check your inbox then return to the site.`,
            UPDATE_PASSWORD: `Your password has been successfully updated.`,
            REGISTER: `You have successfully registered!`,
            REGISTER_WITH_INVALID_EMAIL: `You have successfully registered but there was an issue sending your confirmation email.  Please update your email address in your profile or contact tech support for help.`,
            UPDATE_PROFILE: `You have successfully updated the profile.`,
            MERGE_USERS: `You have successfully merged users.`,
            UPDATE_ENROLLMENT: `You have successfully updated the enrollment details.`,
            DELETE_ENROLLMENT: `You have successfully deleted the enrollment.`,
            USER_IMPORT_SUCCESS: "User import file uploaded successfully.  See page for details.",
            TRACKING_STUDENT_IMPORT_SUCCESS: "Student import file uploaded successfully.  See page for details."
        },
        file: {
            UPLOAD: `File uploaded successfully.`,
            DOWNLOAD: `File downloaded successfully.`
        },
        report: {
            REPORT_DOWNLOADED: "Report successfully downloaded.",
            FOLLOW_UP_DELETED: "Follow-up files successfully delete."
        },
        followUp: {
            UPDATE_APPROVED: "You have successfully updated the approval status.",
            UPDATE_FOLLOW_UP: "You have successfully updated your completed form."
        },
        category: {
            UPDATE_ORDER: "You have successfully updated the order.",
            ADD_CATEGORY: `You have successfully added the category.`,
            UPDATE_CATEGORY: `You have successfully updated the category.`
        },
        course: {
            ADD_COURSE: `You have successfully added the course.`,
            UPDATE_COURSE: `You have successfully updated the course.`,
            MODULE_ORDER: "You have successfully updated the order.",
            ADD_MODULE: `You have successfully added the module.`,
            UPDATE_MODULE: `You have successfully updated the module.`,
            DELETE_MODULE: `You have successfully deleted the module.`,
            MODULE_CONTENT_ORDER: "You have successfully updated the order.",
            ADD_MODULE_CONTENT: `You have successfully added the module content.`,
            UPDATE_MODULE_CONTENT: `You have successfully updated the module content.`,
            DELETE_MODULE_CONTENT: `You have successfully deleted the module content.`,
        },
        courseEvent: {
            ADD_COURSE_EVENT: `You have successfully added the event.`,
            UPDATE_COURSE_EVENT: `You have successfully updated the event.`,
            ENROLLED: `Your account has successfully been associated with the event.`,
            DELETE_ENROLLMENT: `You have successfully removed the association for this participant with the event.`,
        },
        courseProfessionalCommunity: {
            ADD_COURSE_PROFESSIONAL_COMMUNITY: `You have successfully added the professional community.`,
            UPDATE_COURSE_PROFESSIONAL_COMMUNITY: `You have successfully updated the professional community.`,
            ENROLLED: `Your account has successfully been associated with the professional community.`,
            DELETE_ENROLLMENT: `You have successfully removed the association for this participant with the professional community.`,
            PROFESSIONAL_COMMUNITY_CONTENT_ORDER: "You have successfully updated the order.",
            ADD_PROFESSIONAL_COMMUNITY_CONTENT: `You have successfully added the content.`,
            UPDATE_PROFESSIONAL_COMMUNITY_CONTENT: `You have successfully updated the content.`,
            DELETE_PROFESSIONAL_COMMUNITY_CONTENT: `You have successfully deleted the content.`,
        },
        courseTraining: {
            ADD_TRAINING: `You have successfully added the training.`,
            UPDATE_TRAINING: `You have successfully updated the training.`,
            ENROLLED: `Your account has successfully been associated with the training.`,
            DELETE_ENROLLMENT: `You have successfully removed the association for this participant with the training.`,
            UPDATE_ENROLLMENT: `You have successfully updated the training enrollment profile.`,
            EXPORT_TRAINING_SEARCH: `Training search results exported successfully.`,
            EXPORT_TRAINING_ENROLLMENTS: `Training enrollments exported successfully.`,
        },
        survey: {
            ITEM_ORDER: "You have successfully updated the order."
        }
    },
};
export default apiConstants;
