import {deepCopyArray} from "../Types/arrayUtilities";
import {isString} from "../Types/typeUtilities";

export function dynamicSort(property, SortDescending = false, baseProperty = "") {
    let sortOrder = SortDescending ? -1 : 1;
    if (property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function(a, b) {
        let result = 0;
        const aProp = isString(a[property]) ? a[property].toLowerCase() : a[property];
        const bProp = isString(b[property]) ? b[property].toLowerCase() : b[property];
        if (aProp === bProp && baseProperty !== "") {
            const aBaseProp = isString(a[baseProperty]) ? a[baseProperty].toLowerCase() : a[baseProperty];
            const bBaseProp = isString(b[baseProperty]) ? b[baseProperty].toLowerCase() : b[baseProperty];
            result = (aBaseProp < bBaseProp) ? -1 : (aBaseProp > bBaseProp) ? 1 : 0;
        }
        else
            result = (aProp < bProp) ? -1 : (aProp > bProp) ? 1 : 0;
        return result * sortOrder;
    };
}

export function updateOrder(orderResults) {
    let listToSort = deepCopyArray(orderResults.listToSort);
    const [removed] = listToSort.splice(orderResults.startIndex, 1);
    listToSort.splice(orderResults.endIndex, 0, removed);

    return listToSort;
}

export function convertToSortedIds(orderResults) {
    return orderResults.map((result) => result.id);
}