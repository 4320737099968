import PropTypes from "prop-types";
import React from "react";
import {isNullOrUndefined} from "../../Utilities/commonUtilities";
import {isInArray, partialOptionIndexInArray} from "../../Utilities/Types/arrayUtilities";
import {isArray} from "../../Utilities/Types/typeUtilities";
import {MapAdInfinitum} from "../AdInfinitum";
import {HtmlDisplay} from "../Display";
import {fieldsetLayoutProps, FieldsetLayout} from "./FieldsetLayout";
import {appendedOtherValue, createFakeEvent, generateOtherValue} from "./inputUtility";
import {TextField} from "./TextField";

const FieldsetAdapter = ({
                             disabled,
                             helpText,
                             isInline,
                             inputType,
                             legend,
                             name,
                             onChange,
                             options,
                             value: selectedValue,
                             error,
                             responseFeedback,
                             listHasTextField = [],
                         }) => {
    if (isNullOrUndefined(selectedValue)) {
        selectedValue = "";
    }
    const verifySelected = (option, value) => {
        if (isArray(value))
            return isInArray(value, option);
        return option === value;
    };

    const handleUpdate = (selectedValue, otherValue) => {
        const value = generateOtherValue(selectedValue, otherValue);
        onChange(createFakeEvent(value, `${name}`));
    };

    const otherSelectListValueIndex = partialOptionIndexInArray(listHasTextField, selectedValue.toString(), false);
    const hasTextField = otherSelectListValueIndex > -1;

    const selectListValue = hasTextField ? listHasTextField[otherSelectListValueIndex] : selectedValue;
    const otherTextValue = hasTextField ? appendedOtherValue(selectedValue, selectListValue) : "";

    const component = (option, key) => {
        const checked = verifySelected(option.value, selectListValue);
        return (
            <label aria-disabled={disabled} key={key} className={`flex-container`}>
                <input
                    aria-disabled={disabled}
                    type={inputType}
                    name={name}
                    data-testid={`${name}_${key}`}
                    id={`${name}_${key}`}
                    value={option.value}
                    checked={checked}
                    onChange={(e) => onChange(e, checked)}
                    readOnly={disabled}
                />
                <HtmlDisplay html={option.text} isInline/>
                {
                    hasTextField &&
                    <span className={`show-for-sr`}>, requires other text</span>
                }
            </label>
        );
    };

    return (
        <>
            <FieldsetLayout
                disabled={disabled}
                error={error}
                helpText={helpText}
                isInline={isInline}
                legend={legend}
                name={name}
                responseFeedback={responseFeedback}
            >
                <MapAdInfinitum component={component} yutes={options}/>
            </FieldsetLayout>
            {
                hasTextField &&
                <TextField
                    label={`Specify Other Text`}
                    name={`${name}_text`}
                    disabled={disabled}
                    onChange={(event) => handleUpdate(selectListValue, event.target.value)}
                    value={otherTextValue}
                />
            }
        </>
    );
};

export const fieldsetAdapterProps = {
    ...fieldsetLayoutProps,
    onChange: PropTypes.func,
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.array]),
};

FieldsetAdapter.propTypes = {
    ...fieldsetAdapterProps,
    inputType: PropTypes.string.isRequired,
};

export {FieldsetAdapter};
