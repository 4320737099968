import PropTypes from "prop-types";
import React from "react";
import VideoAdapter from "./VideoAdapter";

const YouTubeDisplay = ({
                            videoId,
                        }) => {

    return <VideoAdapter
        name={videoId}
        url={`https://www.youtube.com/embed/${videoId}`}
    />;
};

YouTubeDisplay.propTypes = {
    videoId: PropTypes.string.isRequired,
};

export default React.memo(YouTubeDisplay);