import { emptyFunction, handleApiCall, fetchUser } from "../../Utilities/testingUtilities";
import { user } from "../../Containers/User/userMockData";

export const isLoggedIn = false;
export const isAdmin = false;
export const path = "/";
export const redirect = emptyFunction;
const setIsAdmin = emptyFunction;

const layoutMockExports = {isLoggedIn, isAdmin, path, redirect, user, handleApiCall, setIsAdmin, fetchUser};
export default layoutMockExports;
