import React from "react";
import { GridCell, GridContainer } from "../Grid";

export const HeaderBasic = () => {

    return (
        <header className="header">
            <GridContainer>
                <GridCell><span/></GridCell>
            </GridContainer>
        </header>
    );
};