export const filterCriteriaModel = () => {
    return {
        courseTitles: "",
        courseTitlesDescription: "",
        subjectTags: "",
        AreFiltersReset: areFiltersReset,
        ClearOptionalFilterCriteria: clearOptionalFilterCriteria,
        GetFilteredCourses: getFilteredCourses
    };
};

function clearOptionalFilterCriteria() {
    this.courseTitles = "";
    this.courseTitlesDescription = "";
    this.subjectTags = "";
}

function areFiltersReset() {
    return !this.courseTitles && !this.courseTitlesDescription && !this.subjectTags;
}

function getFilteredCourses(allCategoryCourses) {
    let filteredCourses = [...allCategoryCourses];

    if(this.courseTitles)
        filteredCourses = filteredCourses.filter(c => c.courseTitle?.includes(this.courseTitles));

    if(this.courseTitlesDescription)
        filteredCourses = filteredCourses.filter(c => c.courseTitle?.toLowerCase().includes(this.courseTitlesDescription.toLowerCase())
            || c.courseDescription?.toLowerCase().includes(this.courseTitlesDescription.toLowerCase()));

    if(this.subjectTags)
        filteredCourses = filteredCourses.filter(c => c.subjectTags?.some(s => s === this.subjectTags));

    return filteredCourses;
}

export const filterElements = {
    courseTitles: "courseTitles",
    courseTitlesDescription: "courseTitlesDescription",
    subjectTags: "subjectTags"
}