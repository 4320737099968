import {createObjectFromObjectPropertyNames} from "../../Utilities/Types/objectUtilities";
import {createListFromObject} from "../../Utilities/Types/listUtilities";

export const SURVEY_TYPE_ID = {
	DemographicSurvey: 1,
	PreAssessment: 2,
	FinalAssessment: 3,
	SatisfactionSurvey: 4,
	CheckYourKnowledge: 5,
	"Pre/FinalAssessment": 6,
	CompleterSurvey: 7,
	NonCompleterSurvey: 8
};
export const SURVEY_TYPE = createObjectFromObjectPropertyNames(SURVEY_TYPE_ID);
export const surveyTypeList = createListFromObject(SURVEY_TYPE_ID);
export const surveyTypeListWithoutCyk = surveyTypeList.filter(f => f.value !== SURVEY_TYPE_ID.CheckYourKnowledge);

export const SURVEY_STATUS_ID = {
	Editing: 1,
	Published: 11,
	Closed: 21
};
export const SURVEY_STATUS = createObjectFromObjectPropertyNames(SURVEY_STATUS_ID);
export const surveyStatusList = createListFromObject(SURVEY_STATUS_ID);

export function displayBottomNavigation(survey) {
	return survey.surveyComponents && survey.surveyComponents.length > 5;
}