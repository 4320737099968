export const NextTaskLocation = {
    Survey: "Survey",
    Course: "Course",
    FollowUp: "FollowUp",
    Certificate: "Certificate",
    Assessment: "Assessment",
    ReviewCourse: "ReviewCourse",
    ReEnrollment: "ReEnrollment"
}

export const surveyTypes = {
    DEMOGRAPHIC_SURVEY: 1,
    PREASSESSMENT: 2,
    ASSESSMENT: 3,
    SURVEY: 4,
};