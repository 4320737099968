import {isNullOrUndefined, isTrimmedStringEmpty} from "../commonUtilities";

export function isArray(value) {
	return Array.isArray(value);
}

export function isString(value) {
	return typeof value === "string";
}

export function isBoolean(value) {
	return typeof value === "boolean";
}

export function isNumber(value) {
	return !isNullOrUndefined(value) && !isNaN(value) && !isTrimmedStringEmpty(value.toString());
}