const root = "https://cdn.fcim.org/sep";

function createCdnLink(rel = "", type = "", dir = "", file = "", sizes = "",
                       color = "") {
	return {
		color,
		href: `${root}/${dir}/${file}`,
		rel,
		sizes,
		type,
	};
}
function createCdnMeta(name = "", content = "") {
	return {
		name,
		content,
	};
}

let timer = null;

function checkCdn(callBack, counter) {
	if (Array.from(document.styleSheets)[0] || counter > 1000) {
		clearInterval(timer);
		callBack();
	}
}

export function fetchFromCDN(dir, cssVersionNumber, color="#ffffff") {
	return new Promise((resolve) => {
		if (!dir) resolve();
		const css = createCdnLink("stylesheet", "text/css", `${dir}/css`, `site.css?v=${cssVersionNumber}`);
		const icon16 = createCdnLink("icon", "image/x-icon", `${dir}/favicon`, "favicon-16x16.png", "16x16");
		const icon32 = createCdnLink("icon", "image/x-icon", `${dir}/favicon`, "favicon-32x32.png", "32x32");
		const icon180 = createCdnLink("apple-touch-icon", "image/x-icon", `${dir}/favicon`, "apple-touch-icon.png", "180x180");
		const manifest = createCdnLink("manifest", "", `${dir}/favicon`, "site.webmanifest");
		const maskIcon = createCdnLink("mask-icon", "", `${dir}/favicon`, "safari-pinned-tab.svg", "", color);
		const shortcut = createCdnLink("shortcut icon", "", `${dir}/favicon`, "favicon.ico");
		const links = [css, icon16, icon32, icon180, manifest, maskIcon, shortcut];

		for(const doc of links) {
			const link = document.createElement("link");
			doc.color && link.setAttribute("color", doc.color);
			doc.href && link.setAttribute("href", doc.href);
			doc.rel && link.setAttribute("rel", doc.rel);
			doc.sizes && link.setAttribute("sizes", doc.sizes);
			doc.type && link.setAttribute("type", doc.type);
			document.head.appendChild(link);
		}

		const config = createCdnMeta("msapplication-config", `${root}/${dir}/favicon/browserconfig.xml`)
		const tileColor = createCdnMeta("msapplication-TileColor", color)
		const metas = [config, tileColor];

		for(const doc of metas) {
			const meta = document.createElement("meta");
			doc.content && meta.setAttribute("content", doc.content);
			doc.name && meta.setAttribute("name", doc.name);
			document.head.appendChild(meta);
		}

		let counter = 0;
		timer = setInterval(() => checkCdn(resolve, counter++), 10);
	});
}