import {getDistinctValuesByObjectProperty, isArrayNullOrEmpty} from "../../Utilities/Types/arrayUtilities";
import {isNullOrUndefined, isTrimmedStringEmpty} from "../../Utilities/commonUtilities";
import {NextTaskLocation} from "../Dashboard/dashboardConstants";
import {
	contentType,
	courseTypes,
	DEFAULT_CYK_LABEL,
	DEFAULT_NEXT_PAGE_LABEL,
	DEFAULT_PREVIOUS_PAGE_LABEL,
	pageType
} from "./courseConstants";

const courseId = "courseId";
const viewCourseLabel = "View Course";
const reEnrollLabel = "Re-Enroll";
const reviewCourseLabel = "Review Course";

export function filterEnrolledCourses(enrollments, courseList, property = courseId) {
	if (!isArrayNullOrEmpty(enrollments)) {
		const enrolledCourseIds = getDistinctValuesByObjectProperty(enrollments, property);
		courseList = courseList.filter(c => !enrolledCourseIds.includes(c[property]));
	}
	return courseList;
}

export function updateCourseListWithEnrollmentDetails(enrollments, courseList, property = courseId) {
	if (!isArrayNullOrEmpty(enrollments)) {
		return courseList.map((course) => {
			const enrollment = enrollments.find(c => c[property] === course[property]);
			return updateCourseWithEnrollmentDetails(enrollment, course);
		});
	}

	return courseList;
}

export function updateCourseWithEnrollmentDetails(enrollment, course) {
	if (enrollment) {
		let labelIfEnrolled = viewCourseLabel
		let labelIfReEnrolled = "";
		if (enrollment.taskStatuses && enrollment.taskStatuses.find(f => f.title === reviewCourseLabel))
			labelIfEnrolled = reviewCourseLabel;

		if (enrollment.taskStatuses && enrollment.taskStatuses.find(f => f.title === reEnrollLabel))
			labelIfReEnrolled = reEnrollLabel;
		return { ...course, ...{ labelIfEnrolled }, ...{ labelIfReEnrolled}, bookMark: enrollment.bookMark};
	}

	return {...course};
}

export function isReEnrollByLabel({labelIfReEnrolled}) {
	return labelIfReEnrolled === reEnrollLabel;
}

export function isReEnrollByEnrollment(enrollment) {
	return enrollment.taskStatuses && enrollment.taskStatuses.find(f => f.title === reEnrollLabel);
}

export function isModulePage(pageId){
	return isNullOrUndefined(pageId) || String(pageId) === "0";
}

export function getModuleContentDescription(pageId){
	return isModulePage(pageId) ? "Page" : "Section";
}

export function getModuleContentChildren(modulePages, pageId){
	return isModulePage(pageId)
		? modulePages
		: modulePages.find(p => String(p.moduleContentId) === String(pageId)).moduleSections;
}

export function getModuleSectionOutlineFromModulePage(modulePage) {
	return modulePage.moduleSections.length > 0 ? modulePage.moduleSections[0] : null;
}

export function getSurveyIdFromChild(moduleContent) {
    return isNullOrUndefined(moduleContent)
        ? 0
        : isNullOrUndefined(getModuleSectionSurveyFromModulePage(moduleContent))
            ? 0
            : getModuleSectionSurveyFromModulePage(moduleContent).surveyId;
}

export function getModuleSectionSurveyFromModulePage(modulePage) {
	return modulePage.moduleSections.length > 0 ? modulePage.moduleSections[0] : null;
}

export function getFirstPageIdForModule(module) {
	if(!module)
		return "0";

	const modulePages = module.modulePages;
	return modulePages.length > 0 ? modulePages[0].moduleContentId : "0"
}

export function getCourseNavigation(pageIndex, modulePages, progress, nextPageClick, previousPageClick, defaultBackButtonLabel, defaultBackClick, moduleOutlineClick) {
	const totalPages = modulePages.length;
	let courseNavigation = {
		primaryLabel: undefined,
		primaryHandler: undefined,
		secondaryLabel: defaultBackButtonLabel,
		secondaryHandler: defaultBackClick
	};

	if(pageIndex < 0 || pageIndex >= totalPages)
		return courseNavigation;

	const previousPageProgress = pageIndex > 0 ? progress.contentProgress.find(f => f.moduleContentId === modulePages[pageIndex - 1].moduleContentId) : undefined;
	const pageProgress = progress.contentProgress.find(f => f.moduleContentId === modulePages[pageIndex].moduleContentId);
	const hasCompletedPreviousPage = pageIndex === 0
		|| !isTrimmedStringEmpty(previousPageProgress.dateFinished)
		|| (pageProgress.isAvailable && previousPageProgress.isAvailable);
	const hasCompletedPage = !isTrimmedStringEmpty(pageProgress.dateFinished);
	const moduleOutlinePage = modulePages.find(f => f.type === contentType.outline);

	if(!hasCompletedPreviousPage) {
		if(moduleOutlinePage) {
			courseNavigation.primaryLabel = defaultBackButtonLabel;
			courseNavigation.primaryHandler = defaultBackClick;
			courseNavigation.secondaryLabel = moduleOutlinePage.description;
			courseNavigation.secondaryHandler = moduleOutlineClick;
		}
		return courseNavigation;
	}

	if(totalPages > 1 ) {
		const isCurrentPageCyk = modulePages[pageIndex].type === pageType.cyk;

		if((isCurrentPageCyk && hasCompletedPage) || !isCurrentPageCyk) {
			if (totalPages - 1 === pageIndex ) {
				courseNavigation.primaryLabel = defaultBackButtonLabel;
				courseNavigation.primaryHandler = (event) => defaultBackClick(event, true);
			} else {
				const cykPageTypeTitle = modulePages[pageIndex + 1].description || DEFAULT_CYK_LABEL;
				courseNavigation.primaryLabel = modulePages[pageIndex + 1].type === pageType.cyk ? cykPageTypeTitle : DEFAULT_NEXT_PAGE_LABEL;
				courseNavigation.primaryHandler = nextPageClick;
			}
		}
	}
	else if(totalPages === 1) {
		courseNavigation.secondaryHandler = (event) => defaultBackClick(event, true);
	}

	if(pageIndex > 0) {
		courseNavigation.secondaryLabel = DEFAULT_PREVIOUS_PAGE_LABEL;
		courseNavigation.secondaryHandler = previousPageClick;
	}

	return courseNavigation;
}

export function isCertificateEnabled(enrollment) {
	if(!enrollment) return false;

	return enrollment.taskStatuses.some(f => f.nextTaskLocation === NextTaskLocation.Certificate)
		&& enrollment.courseType !== courseTypes.ProfessionalCommunityCourse.id;
}