import Notification from "./Notification";
import { NotificationTypes } from "./NotificationTypes";

export class DefaultNotification extends Notification {
	constructor(notificationAdapter, notificationType, message) {
		super();
		this._notificationType = notificationType;
		this._message = message;
		this._notificationAdapter = notificationAdapter;
	}

	notify() {
		switch (this._notificationType) {
			case NotificationTypes.SUCCESS:
				this._notificationAdapter.success(this._message);
				break;
			case NotificationTypes.ERROR:
				this._notificationAdapter.error(this._message);
				break;
			case NotificationTypes.WARNING:
				this._notificationAdapter.warning(this._message);
				break;
			default:
				this._notificationAdapter.info(this._message);
				break;
		}
	}
}
