import PropTypes from "prop-types";
import React from "react";
import gridConstants from "../Grid/gridConstants";

const GridCell = ({
                    children,
                    className ="",
                    large_columns = "",
                    large_offset = "",
                    medium_columns = "",
                    medium_offset = "",
                    small_columns = gridConstants.column.TWELVE,
                    small_offset = "",
                }) => {
    let classes = gridConstants.class.CELL;
    classes += className ? ` ${className}` : ``;
    
    classes += small_columns ? ` small-${small_columns}` : ``;
    classes += medium_columns ? ` medium-${medium_columns}` : ``;
    classes += large_columns ? ` large-${large_columns}` : ``;

    classes += small_offset ? ` small-offset-${small_offset}` : ``;
    classes += medium_offset ? ` medium-offset-${medium_offset}` : ``;
    classes += large_offset ? ` large-offset-${large_offset}` : ``;

    return <div className={classes}>{children}</div>;
};

GridCell.propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    large_columns: PropTypes.string,
    large_offset: PropTypes.string,
    medium_columns: PropTypes.string,
    medium_offset: PropTypes.string,
    small_columns: PropTypes.string,
    small_offset: PropTypes.string,
};

export default GridCell;
