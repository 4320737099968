import React from "react";
import { locations } from "../../Utilities/Location";
import { GridCell, gridConstants, GridContainer } from "../Grid";
import { ExternalLink, Link } from "../Link";
import PropTypes from "prop-types";

const HeaderTwoColumn = ({leftLabel, rightLabel, rightUrl}) => {

    return (
        <header className="header">
            <GridContainer hasMarginX>
                <GridCell small_columns={gridConstants.column.SIX} className={`logo logo--left`}>
                    <Link to={locations.LANDING.link.to} label={leftLabel}/>
                </GridCell>
                <GridCell small_columns={gridConstants.column.SIX} className={`logo logo--right`}>
                    <ExternalLink url={rightUrl} label={rightLabel} hasNoIcon  />
                </GridCell>
            </GridContainer>
        </header>
    );
};

HeaderTwoColumn.propTypes = {
    leftLabel: PropTypes.string.isRequired,
    rightLabel: PropTypes.string.isRequired,
    rightUrl: PropTypes.string.isRequired,
};

export default HeaderTwoColumn;