import PropTypes from "prop-types";
import React, {useEffect} from "react";
import {progressModel} from "../../Containers/User/userFactory";
import {isTrimmedStringEmpty} from "../../Utilities/commonUtilities";
import {CoursePageTitle, PageNavigation} from "../Landmarks";
import {ContentMedium} from "../Layout";
import {HtmlDisplay} from "./index";

const DiscussionBoardDisplay = ({
                                    backButtonLabel,
                                    courseTitle,
                                    discussionBoardButtonLabel,
                                    discussionBoardHtml,
                                    discussionBoardModule,
                                    discussionBoardUrl,
                                    enrollment,
                                    externalCourseId,
                                    handleBackClick,
                                    updateModuleComplete
                        }) => {

    useEffect(() => {
        const updateCourseResourcesModuleComplete = () => {
            const moduleProgress = enrollment.progress.find(p => p.moduleId === discussionBoardModule.moduleId) || progressModel();
            if (isTrimmedStringEmpty(moduleProgress.dateFinished))
                updateModuleComplete(enrollment.userId, enrollment.enrollmentId, discussionBoardModule.moduleId);
        };

        updateCourseResourcesModuleComplete();
    }, [enrollment]);

    return (
        <>
            <CoursePageTitle courseTitle={courseTitle} className={`pageTitle--module${discussionBoardModule.moduleId}  pageTitle--pageType__discussionBoard`}
                             pageTitle={discussionBoardModule.title}/>
            <ContentMedium>
                <div className={`content`}>
                    <HtmlDisplay html={discussionBoardHtml} />
                </div>
                <PageNavigation
                    labelPrimary={discussionBoardButtonLabel}
                    externalUrlPrimary={`${discussionBoardUrl}${externalCourseId}/`}
                    handleSecondaryClick={handleBackClick}
                    labelSecondary={backButtonLabel}
                />
            </ContentMedium>
        </>

    );
};

DiscussionBoardDisplay.propTypes = {
    backButtonLabel: PropTypes.string.isRequired,
    courseTitle: PropTypes.string.isRequired,
    discussionBoardButtonLabel: PropTypes.string.isRequired,
    discussionBoardHtml: PropTypes.string.isRequired,
    discussionBoardModule: PropTypes.object.isRequired,
    discussionBoardUrl: PropTypes.string.isRequired,
    enrollment: PropTypes.object.isRequired,
    externalCourseId: PropTypes.string.isRequired,
    handleBackClick: PropTypes.func.isRequired,
    updateModuleComplete: PropTypes.func.isRequired
};

export default React.memo(DiscussionBoardDisplay);