import PropTypes from "prop-types";
import React from "react";
import {GridCell, gridConstants, GridContainer} from "../Grid";
import {Link} from "../Link";

export const InputFormHeader = ({h2, link1, link2, link3, link4}) => {

    return (
        <GridContainer isReverseOrder className={`form__header`} hasMarginX>
            <GridCell large_columns={gridConstants.column.SEVEN} className={`h2 align-right`}>
                <Link {...link1} />
                {
                    link2 &&
                    <>
                        &nbsp; | &nbsp;
                        <Link{...link2} />
                    </>
                }
                {
                    link3 &&
                    <>
                        &nbsp; | &nbsp;
                        <Link{...link3} />
                    </>
                }
                {
                    link4 &&
                    <>
                        &nbsp; | &nbsp;
                        <Link{...link4} />
                    </>
                }
            </GridCell>
            <GridCell large_columns={gridConstants.column.FIVE}>
                {
                    h2 ? <h2>{h2}</h2> : " "
                }
            </GridCell>
        </GridContainer>

    );
};

InputFormHeader.propTypes = {
    h2: PropTypes.string,
    link1: PropTypes.object,
    link2: PropTypes.object,
    link3: PropTypes.object,
    link4: PropTypes.object,
};