import PropTypes from "prop-types";
import React from "react";
import gridConstants from "../Grid/gridConstants";

const GridContainer = ({children, className, hasMarginX, hasMarginY, hasPaddingX, hasPaddingY, isFlush, isReverseOrder}) => {

	let classes = "grid-x";
	classes += className ? ` ${className}` : "";
	classes += hasMarginX ? ` ${gridConstants.class.GRID_MARGIN_X}` : "";
	classes += hasMarginY ? ` ${gridConstants.class.GRID_MARGIN_Y}` : "";
	classes += hasPaddingX ? ` ${gridConstants.class.GRID_PADDING_X}` : "";
	classes += hasPaddingY ? ` ${gridConstants.class.GRID_PADDING_Y}` : "";
	classes += isFlush ? ` ${gridConstants.class.GRID_FLUSH}` : "";
	classes += isReverseOrder ? ` ${gridConstants.class.GRID_REVERSE}` : "";

	return (
		<div className={classes}>
			{children}
		</div>
	);
};

GridContainer.propTypes = {
	children: PropTypes.any,
	className: PropTypes.string,
	hasMarginX: PropTypes.bool,
	hasMarginY: PropTypes.bool,
	hasPaddingX: PropTypes.bool,
	hasPaddingY: PropTypes.bool,
	isFlush: PropTypes.bool,
	isReverseOrder: PropTypes.bool,
};

export default GridContainer;
