import { useEffect } from "react";
import { useAppContext } from "../StateManager/Context";

export function useDocumentTitle(title) {
    const { siteDetails } = useAppContext();
    const defaultTitle = siteDetails && siteDetails.documentTitlePostFix ? `${siteDetails.documentTitlePostFix}` : "";

    useEffect(() => {
        document.title = title ? `${title} - ${defaultTitle}` : defaultTitle;
    }, [title]);
}

export function refocusOnPageChange(extraScreenReaderContent) {
    const resetFocusItem = document.getElementsByTagName("h1")[0];
    if(!resetFocusItem) return;

    const extraContentSpan = document.getElementById("extraScreenReaderContent");
    extraScreenReaderContent = extraScreenReaderContent || "";
    extraContentSpan.innerHTML = ` ${extraScreenReaderContent} View Loaded`;

    resetFocusItem.style.outline = "none";
    resetFocusItem.focus();
}