import PropTypes from "prop-types";
import React, {createContext, useContext, useEffect, useState} from "react";
import {
	professionalCommunityEnrollmentSearchModel
} from "../../../Containers/Course/CourseProfessionalCommunityEnrollments/courseProfessionalCommunityEnrollmentsFactory";
import {
	courseProfessionalCommunitySearchModel
} from "../../../Containers/Course/CourseProfessionalCommunitySearch/courseProfessionalCommunitySearchFactory";
import { courseEventSearchModel } from "../../../Containers/Course/CourseEventSearch/courseEventSearchFactory";
import { courseTrainingSearchModel } from "../../../Containers/Course/CourseTrainingSearch/courseTrainingSearchFactory";
import {userSearchModel} from "../../../Containers/User/UserSearch/userSearchFactory";
import {enrollmentSearchModel} from "../../../Containers/Enrollment/enrollmentSearchFactory";
import {reportSearchModel} from "../../../Containers/Reports/reportsFactory";
import * as storageUtilities from "../../../Utilities/LocalStorage/storageUtilities";
import {useAppContext} from "./AppContext";
import {updateSearchCriteria} from "./adminContextUtility";
import {surveySearchModel} from "../../../Containers/Survey/SurveySearch/surveySearchFactory";
import {categorySearchModel} from "../../../Containers/Category/CategorySearch/categorySearchFactory";
import {courseSearchModel} from "../../../Containers/Course/CourseSearch/courseSearchFactory";

export const AdminContext = createContext([]);
export const useAdminContext = () => useContext(AdminContext);

export const AdminContextProvider = ({
	                                     children,
                                     }) => {
	const {siteDetails} = useAppContext();
	const userId = storageUtilities.getLoggedInUser();
	const roleIdOfImpersonator = storageUtilities.getRoleIdOfImpersonator();
	const [isAdmin, setIsAdmin] = useState(storageUtilities.isAdmin());

	const [surveySearchCriteria, setSurveySearchCriteria] = useState(null);
	const handleSetSurveySearchCriteria = (criteria) => setSurveySearchCriteria(criteria);

	const [userSearchCriteria, setUserSearchCriteria] = useState(null);
	const handleSetUserSearchCriteria = (criteria) => setUserSearchCriteria(criteria);

	const [enrollmentSearchCriteria, setEnrollmentSearchCriteria] = useState(null);
	const handleSetEnrollmentSearchCriteria = (criteria) => setEnrollmentSearchCriteria(criteria);

	const [professionalCommunityEnrollmentSearchCriteria, setProfessionalCommunityEnrollmentSearchCriteria] = useState(null);
	const handleSetProfessionalCommunityEnrollmentSearchCriteria = (criteria) => setProfessionalCommunityEnrollmentSearchCriteria(criteria);

	const [reportSearchCriteria, setReportSearchCriteria] = useState(null);
	const handleSetReportSearchCriteria = (criteria) => setReportSearchCriteria(criteria);

	const [categorySearchCriteria, setCategorySearchCriteria] = useState(null);
	const handleSetCategorySearchCriteria = (criteria) => setCategorySearchCriteria(criteria);

	const [courseSearchCriteria, setCourseSearchCriteria] = useState(null);
	const handleSetCourseSearchCriteria = (criteria) => setCourseSearchCriteria(criteria);

	const [courseEventSearchCriteria, setCourseEventSearchCriteria] = useState(null);
	const handleSetCourseEventSearchCriteria = (criteria) => setCourseEventSearchCriteria(criteria);

	const [courseTrainingSearchCriteria, setCourseTrainingSearchCriteria] = useState(null);
	const handleSetCourseTrainingSearchCriteria = (criteria) => setCourseTrainingSearchCriteria(criteria);

	const [courseProfessionalCommunitySearchCriteria, setCourseProfessionalCommunitySearchCriteria] = useState(null);
	const handleSetCourseProfessionalCommunitySearchCriteria = (criteria) => setCourseProfessionalCommunitySearchCriteria(criteria);

	const searchProps = [userId, siteDetails, roleIdOfImpersonator];
	const updateEnrollmentSearchCriteria = () => updateSearchCriteria(...searchProps, enrollmentSearchCriteria,
		setEnrollmentSearchCriteria, enrollmentSearchModel);
	const updateProfessionalCommunityEnrollmentSearchCriteria = () => updateSearchCriteria(...searchProps, professionalCommunityEnrollmentSearchCriteria,
		setProfessionalCommunityEnrollmentSearchCriteria, professionalCommunityEnrollmentSearchModel);
	const updateReportSearchCriteria = () => updateSearchCriteria(...searchProps, reportSearchCriteria,
		setReportSearchCriteria, reportSearchModel, false);
	const updateSurveySearchCriteria = () => updateSearchCriteria(...searchProps, surveySearchCriteria,
		setSurveySearchCriteria, surveySearchModel, false);
	const updateUserSearchCriteria = () => updateSearchCriteria(...searchProps, userSearchCriteria,
		setUserSearchCriteria, userSearchModel, false);
	const updateCategorySearchCriteria = () => updateSearchCriteria(...searchProps, categorySearchCriteria,
		setCategorySearchCriteria, categorySearchModel, false);
	const updateCourseSearchCriteria = () => updateSearchCriteria(...searchProps, courseSearchCriteria,
		setCourseSearchCriteria, courseSearchModel, false);
	const updateCourseEventSearchCriteria = () => updateSearchCriteria(...searchProps, courseEventSearchCriteria,
		setCourseEventSearchCriteria, courseEventSearchModel, false);
	const updateCourseTrainingSearchCriteria = () => updateSearchCriteria(...searchProps, courseTrainingSearchCriteria,
		setCourseTrainingSearchCriteria, courseTrainingSearchModel, false);
	const updateCourseProfessionalCommunitySearchCriteria = () => updateSearchCriteria(...searchProps, courseProfessionalCommunitySearchCriteria,
		setCourseProfessionalCommunitySearchCriteria, courseProfessionalCommunitySearchModel, false);

	useEffect(() => {
		updateEnrollmentSearchCriteria();
		updateProfessionalCommunityEnrollmentSearchCriteria();
		updateReportSearchCriteria();
		updateSurveySearchCriteria();
		updateUserSearchCriteria();
		updateCategorySearchCriteria();
		updateCourseSearchCriteria();
		updateCourseEventSearchCriteria();
		updateCourseProfessionalCommunitySearchCriteria();
		updateCourseTrainingSearchCriteria();
	}, [siteDetails, userId, roleIdOfImpersonator]);

	return (
		<AdminContext.Provider
			value={
				{
					isAdmin,
					setIsAdmin,
					enrollmentSearchCriteria,
					handleSetEnrollmentSearchCriteria,
					handleSetProfessionalCommunityEnrollmentSearchCriteria,
					handleSetReportSearchCriteria,
					handleSetSurveySearchCriteria,
					handleSetUserSearchCriteria,
					handleSetCategorySearchCriteria,
					handleSetCourseSearchCriteria,
					handleSetCourseEventSearchCriteria,
					handleSetCourseTrainingSearchCriteria,
					handleSetCourseProfessionalCommunitySearchCriteria,
					professionalCommunityEnrollmentSearchCriteria,
					reportSearchCriteria,
					surveySearchCriteria,
					userSearchCriteria,
					categorySearchCriteria,
					courseSearchCriteria,
					courseEventSearchCriteria,
					courseTrainingSearchCriteria,
					courseProfessionalCommunitySearchCriteria
				}
			}>
			{children}
		</AdminContext.Provider>
	);
};

AdminContextProvider.propTypes = {
	children: PropTypes.any.isRequired,
};