import AjaxRequestWithRetry from "./ajaxRequestWithRetry";
import DownloadUtility from "./downloadUtility";
import FileSaver from "file-saver";

const blobRequestExport = (localStorage, notificationFactory, maxRetryCount, tokenUrl, retryTimeout = 150) => {
	function send(ajaxRequestOptions) {
		return new Promise((resolve, reject) => {
			//ajaxRequestOptions.contentType = false;
			ajaxRequestOptions.processData = false;
			ajaxRequestOptions.xhr = () => {
				let xhr = new XMLHttpRequest();
				xhr.onreadystatechange = () => {
					if (xhr.readyState === 2) {
						if (xhr.status === 200) xhr.responseType = "blob";
						else xhr.responseType = "text";
					}
				};
				return xhr;
			};

			ajaxRequestOptions.setCallbacks(
				function(data, status, xhrObject) {
					let filename = DownloadUtility.getFilenameFromHeader(xhrObject);

					FileSaver.saveAs(new Blob([data]), filename);

					if (ajaxRequestOptions.successMessage)
						notificationFactory.createSuccess(ajaxRequestOptions.successMessage).notify();

					resolve(data);
				},
				function(data) {
					notificationFactory.createErrorsFromXhrObject(data).notify();
					reject(data);
				},
			);
			AjaxRequestWithRetry(localStorage, notificationFactory, maxRetryCount, tokenUrl, retryTimeout).send(
				ajaxRequestOptions,
			);
		});
	}

	return { send };
};

export default blobRequestExport;