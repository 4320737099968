import { useReducer } from "react";

const actions = {
    DECREMENT: "decrement",
    INCREMENT: "increment",
    RESET: "reset",
};

export function useCounter(initialValue = 0) {


    const reducer = (state, action) => {
        switch (action) {
            case actions.INCREMENT: return state + 1;
            case actions.DECREMENT: return state - 1;
            case actions.RESET: return 0;
            default: throw new Error('Unexpected action');
        }
    };

    const [count, dispatch] = useReducer(reducer, initialValue);
    const decrement = () => { dispatch(actions.DECREMENT)};
    const increment = () => { dispatch(actions.INCREMENT)};
    const reset = () => { dispatch(actions.RESET)};

    return {
        count,
        decrement,
        increment,
        reset
    };
}