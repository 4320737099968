import {getRoleId} from "../../Utilities/LocalStorage/storageUtilities";
import {isArrayNullOrEmpty} from "../../Utilities/Types/arrayUtilities";
import {createListFromObject, createListItem} from "../../Utilities/Types/listUtilities";
import {createObjectFromObjectPropertyNames} from "../../Utilities/Types/objectUtilities";
import {convertCamelCaseToSpaceDelimited, convertToNumber} from "../../Utilities/commonUtilities";

export const ROLES = {
    Participant: 1,
    TrackingStudent: 8,
    DemoParticipant: 0,
    AllAccessParticipant: 2,
    AllDataViewer: 3,
    RegionalDataViewer: 7,
    DistrictDataViewer: 10,
    DistrictDataViewerAndTrainer: 11,
    Trainer: 9,
    SiteAdmin: 4,
    TechSupport: 6,
    SystemAdmin: 5
};

export const ROLE_NAMES = createObjectFromObjectPropertyNames(ROLES);

export const TECH_SUPPORT_MANAGE_ROLES = [ROLES.DemoParticipant, ROLES.Participant, ROLES.AllAccessParticipant, ROLES.TrackingStudent];

const OSS_DISTRICT_DATA_VIEWER_TITLE = `${ROLE_NAMES.DistrictDataViewer} (DTMC)`;

export const REPORT_ROLE_NAMES_LIST = [createListItem(ROLES.DistrictDataViewer, OSS_DISTRICT_DATA_VIEWER_TITLE), createListItem(ROLES.Trainer, ROLE_NAMES.Trainer)];

export function updateRoleListForOss(fullRoleList) {
    // TEMPORARY solution to add the DTMC role to the list of roles until all roles are removed at some point
    let districtDataViewer = fullRoleList.find(f => f.value === ROLES.DistrictDataViewer);
    let districtDataViewerTrainer = fullRoleList.find(f => f.value === ROLES.DistrictDataViewerAndTrainer);
    if(districtDataViewerTrainer)
        districtDataViewerTrainer.text = `${OSS_DISTRICT_DATA_VIEWER_TITLE} And Trainer`;
    if(districtDataViewer)
        districtDataViewer.text = OSS_DISTRICT_DATA_VIEWER_TITLE;
}

export function getRoleList(rolesAdminCanManage, rolesUsedBySite = [], userRoleId = getRoleId()) {
    let fullRoleList = createListFromObject(ROLES);

    updateRoleListForOss(fullRoleList);

    if(!isArrayNullOrEmpty(rolesUsedBySite)) {
        let updatedRoleList = [];
        for (let role of fullRoleList) {
            const siteUsesRole = rolesUsedBySite.some(f => f === role.id);
            if (siteUsesRole)
                updatedRoleList.push(role);
        }
        fullRoleList = updatedRoleList;
    }

    if (userRoleId === ROLES.TechSupport) {
        return fullRoleList.filter(f => TECH_SUPPORT_MANAGE_ROLES.includes(f.value));
    } else if (userRoleId === ROLES.SystemAdmin) {
        return fullRoleList;
    }

    let roleList = [];
    for (let role of rolesAdminCanManage) {
        const roleId = ROLES[role];
        if (roleId !== undefined)
            roleList.push(createListItem(roleId, convertCamelCaseToSpaceDelimited(role), role));
    }

    updateRoleListForOss(roleList);

    return roleList;
}

export function isTrackingStudent(roleId) {
    return convertToNumber(roleId) === ROLES.TrackingStudent;
}

export function isTrainer(roleId) {
    return convertToNumber(roleId) === ROLES.Trainer;
}

export function isDistrictDataViewer(roleId) {
    return convertToNumber(roleId) === ROLES.DistrictDataViewer;
}

export function isDistrictDataViewerAndTrainer(roleId) {
    return convertToNumber(roleId) === ROLES.DistrictDataViewerAndTrainer;
}