import PropTypes from "prop-types";
import React from "react";
import { GridCell, gridConstants, GridContainer, GridFlexSpacer } from "../Grid";

const LayoutMedium = ({ children }) => {
    return (
        <GridContainer>
            <GridFlexSpacer/>
            <GridCell
                medium_columns={gridConstants.column.TEN}
                large_columns={gridConstants.column.EIGHT}
            >
                {children}
            </GridCell>
            <GridFlexSpacer/>
        </GridContainer>
    );
};

LayoutMedium.propTypes = {
    children: PropTypes.any,
};

export default LayoutMedium;
