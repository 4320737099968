import {isNullOrUndefined} from "../../../Utilities/commonUtilities";
import {searchModel} from "../../../Utilities/Search";

export const courseEventSearchResults = (attributes) => ({...attributes});

export const courseEventSearchModel = (userId, siteGroupId, nameContains, isActive) => {
    return Object.assign({}, searchModel(userId.toString(), "courseTitle", false, "courseTitle"), {
        nameContains: nameContains ? nameContains : "",
        isActive: !isNullOrUndefined(isActive) ? isActive : "",
        siteGroupId,
        ClearCourseSearch: clearCourseSearch,
    });
};

function clearCourseSearch() {
    this.nameContains = "";
    this.isActive = "";
    this.ClearSearch(false);
}