import PropTypes from "prop-types";
import React from "react";
import {getButtonClasses} from "../Inputs/inputUtility";
import {emptyFunction} from "../../Utilities/testingUtilities";

export const ExternalLink = (props) => {
    const {className, hasNoIcon} = props;
    let classes = className ? className
        : hasNoIcon ? "noIcon"
            : undefined;

    return <ExternalLinkAdapter {...props} className={classes}/>;
};

export const ExternalLinkButton = (props) => {
    let classes = getButtonClasses(props);

    return <ExternalLinkAdapter {...props} className={classes}/>;
};
export const ExternalTertiaryLinkButton = (props) => {
    let classes = getButtonClasses({...props, isTertiary: true});

    return <ExternalLinkAdapter {...props} className={classes}/>;
};

export const ExternalLinkTitle = ({className, title, url}) =>
    <ExternalLinkAdapter label={``} title={title} url={url} className={`noIcon ${className}`}/>;

const ExternalLinkAdapter = ({
                                 className,
                                 handleClick = emptyFunction,
                                 hasWhiteSpaceAfter,
                                 hasWhiteSpaceBefore,
                                 label,
                                 opensInNewTab = true,
                                 title,
                                 url,
                             }) => {
    if (!label && !title) return <> </>;

    const newTabTarget = opensInNewTab ? "_blank" : null;
    const newTabRel = opensInNewTab ? "noopener noreferrer" : null;

    return <>
        {
            hasWhiteSpaceBefore && ` `
        }
        <a href={url} className={className} target={newTabTarget} rel={newTabRel} title={title}
           onClick={handleClick}>
            {label}
        </a>
        {
            hasWhiteSpaceAfter && ` `
        }
    </>;
};

const sharedProps = {
    className: PropTypes.string,
    handleClick: PropTypes.func,
    hasWhiteSpaceAfter: PropTypes.bool,
    hasWhiteSpaceBefore: PropTypes.bool,
    label: PropTypes.any,
    opensInNewTab: PropTypes.bool,
    title: PropTypes.string,
    url: PropTypes.string,
};

const buttonProps = {
    isExpanded: PropTypes.bool,
    isPrimary: PropTypes.bool,
};

ExternalLink.propTypes = {
    hasNoIcon: PropTypes.bool,
    ...sharedProps,
};
ExternalLinkButton.propTypes = {
    ...buttonProps,
    ...sharedProps,
};

ExternalTertiaryLinkButton.propTypes = {
    isExpanded: PropTypes.bool,
    ...sharedProps,
};

ExternalLinkTitle.propTypes = {
    ...sharedProps,
};

ExternalLinkAdapter.propTypes = {
    ...sharedProps,
};