import PropTypes from "prop-types";
import React, {createContext, useContext, useEffect, useState} from "react";
import userApi from "../../../Containers/User/userApi";
import followUpApi from "../../../Containers/FollowUp/followUpApi";
import {userModel} from "../../../Containers/User/userFactory";
import {isValidUser} from "../../../Containers/User/userUtilities";
import * as storageUtilities from "../../../Utilities/LocalStorage/storageUtilities";
import {isObjectNullOrEmpty} from "../../../Utilities/Types/objectUtilities";
import {useAppContext} from "./AppContext";
import {useAdminContext} from "./AdminContext";
import {filterCriteriaModel} from "../../../Containers/PageSetup/filterCriteriaFactory";
import {useCourseContext} from "./CourseContext";
import {isTrackingStudent} from "../../Authorize/roleConstants";

export const UserContext = createContext([]);
export const useUserContext = () => useContext(UserContext);

export const UserContextProvider = ({
                                        children,
                                    }) => {

    const [user, setUser] = useState(userModel());
    const isLoggedIn = storageUtilities.isLoggedIn();
    const isStudent = isTrackingStudent(user?.roleId);
    const {handleApiCall, siteDetails} = useAppContext();
    const {setIsAdmin} = useAdminContext();
    const {fetchCourses} = useCourseContext();

    const [filterCriteria, setFilterCriteria] = useState(filterCriteriaModel());
    const handleSetFilterCriteria = (criteria) => setFilterCriteria(criteria);
    const handleResetFilterCriteria = (criteria) => {
        if(!criteria)
            criteria = {...filterCriteria};
        criteria.ClearOptionalFilterCriteria();
        handleSetFilterCriteria(criteria);
    }

    const setUserIfValid = (user) => {
        user = userModel(user);
        if (isValidUser(user)) {
            setUser(user);
            return true;
        }

        return false;
    };

    const setLoggedInFalse = () => {
        storageUtilities.desistLogin();
        setUser(userModel());
        setIsAdmin(false);
        return false;
    };

    const setLoggedInTrue = (user) => {
        if (!isValidUser(user))
            return setLoggedInFalse();

        storageUtilities.persistLogin(user.userId, user.accessToken, user.refreshToken, user.isAdmin, user.roleId);
        setIsAdmin(user.isAdmin);

        if(user.notifier)
            user.notifier.notify();

        return true;
    };

    const fetchUser = () => {
        return handleApiCall(userApi.getUser, setUserIfValid, setLoggedInFalse);
    };

    const updateModuleComplete = (userId, enrollmentId, moduleId, pageId, overrideCallback) => {
        const callback = overrideCallback || setUserIfValid;
        handleApiCall(() => userApi.moduleComplete(userId, enrollmentId, moduleId, pageId), callback);
    };

    const updateModuleProgress = (userId, enrollmentId, moduleId, pageId, overrideCallback) => {
        const callback = overrideCallback || setUserIfValid;
        handleApiCall(() => userApi.moduleProgress(userId, enrollmentId, moduleId, pageId), callback);
    };

    const updateEnrollmentFollowUpActivity = (userId, enrollmentId, fileId, creditOptionSelected) => {
        handleApiCall(() => followUpApi.updateEnrollmentFollowUp(userId, enrollmentId, fileId, creditOptionSelected), setUserIfValid);
    };

    const updateEnrollments = (enrollments) => {
        setUser({...user, enrollments: enrollments})
    };

    useEffect(() => {
        if(!isLoggedIn || isObjectNullOrEmpty(siteDetails) || !siteDetails.siteGroupId) return;

        fetchCourses(siteDetails.siteGroupId);
    }, [siteDetails?.siteGroupId, isLoggedIn]);

    return (
        <UserContext.Provider
            value={{
                user,
                fetchUser,
                isLoggedIn,
                isStudent,
                setLoggedInFalse,
                setLoggedInTrue,
                updateModuleComplete,
                updateModuleProgress,
                updateEnrollmentFollowUpActivity,
                filterCriteria,
                handleSetFilterCriteria,
                handleResetFilterCriteria,
                updateEnrollments
            }}>
            {children}
        </UserContext.Provider>
    );
};

UserContextProvider.propTypes = {
    children: PropTypes.any.isRequired,
};