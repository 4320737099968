import PropTypes from "prop-types";
import React from "react";

const Section = ({ children, h2, className="" }) => {
    return (
        <section>
            {!!h2 && <h2 className={`h2 ${className}`}>{h2}</h2>}
            {children}
        </section>
    );
};

Section.propTypes = {
    className: PropTypes.string,
    h2: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array
    ]).isRequired,
};

export default React.memo(Section);
