import PropTypes from "prop-types";
import React, {createContext, useContext, useEffect, useState} from "react";
import courseApi from "../../../Containers/Course/courseApi";
import {setDbCourseImageStyle} from "../../../Utilities/cssUtilities";
import {isArrayNullOrEmpty} from "../../../Utilities/Types/arrayUtilities";
import {isObjectNullOrEmpty} from "../../../Utilities/Types/objectUtilities";
import {allow, policyEvents} from "../../Authorize";
import {useAppContext} from "./AppContext";
import {courseTypes} from "../../../Containers/Course/courseConstants";

export const CourseContext = createContext([]);
export const useCourseContext = () => useContext(CourseContext);

export const CourseContextProvider = ({
	                                      children,
                                      }) => {
	const [activeCourses, setActiveCourses] = useState([]);
	const [publicCourses, setPublicCourses] = useState([]);
	const [publicCommunities, setPublicCommunities] = useState([]);
	const [allCourses, setAllCourses] = useState([]);
	const [catalog, setCatalog] = useState([]);
	const [allCategories, setAllCategories] = useState([]);
	const {handleApiCallWithNoDim, handleApiCall, siteDetails} = useAppContext();

	const updateCourses = (allCourses, siteGroupId, forceRecreateCourseImages) => {
		const activeCourses = allCourses.filter(c => c.isActive && c.siteGroupId.toString() === siteGroupId.toString());
		const publicCourses = activeCourses.filter(c => !c.hidden);
		const publicCommunities = publicCourses.filter(c => c.courseType === courseTypes.ProfessionalCommunityCourse.id);
		setAllCourses(allCourses);
		setActiveCourses(activeCourses);
		setPublicCourses(publicCourses);
		setPublicCommunities(publicCommunities);
		setDbCourseImageStyle(allCourses, siteDetails.hasImage, forceRecreateCourseImages);
	};

	const fetchCourses = (siteGroupId, forceRecreateCourseImages = false) => {
		const siteGroupIdParam = allow(policyEvents.ACCESS_ALL_SITE_GROUPS) ? 0 : siteGroupId;
		handleApiCallWithNoDim(() => courseApi.getAllCourses(siteGroupIdParam), (results) => updateCourses(results, siteGroupId, forceRecreateCourseImages));
	};

	const fetchCatalog = (siteGroupId, silentGet = false) => {
		const handle = silentGet ? handleApiCallWithNoDim : handleApiCall;
		handle(() => courseApi.getCategories(siteGroupId), setCatalog);
	};

	const fetchAllCategories = () => {
		handleApiCallWithNoDim(() => courseApi.getAllCategories(), setAllCategories);
	}

	useEffect(() => {
		if (!isArrayNullOrEmpty(activeCourses) || isObjectNullOrEmpty(siteDetails) || !siteDetails.siteGroupId) return;
		fetchCourses(siteDetails.siteGroupId);
	}, [siteDetails]);

	useEffect(() => {
		if (!isArrayNullOrEmpty(catalog) || isObjectNullOrEmpty(siteDetails) || !siteDetails.siteGroupId) return;
		fetchCatalog(siteDetails.siteGroupId);
		fetchAllCategories();
	}, [siteDetails]);

	return (
		<CourseContext.Provider value={{allCategories, allCourses, catalog, activeCourses, publicCommunities, publicCourses, fetchCatalog, fetchCourses}}>
			{children}
		</CourseContext.Provider>
	);
};

CourseContextProvider.propTypes = {
	children: PropTypes.any,
};