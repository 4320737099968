import PropTypes from "prop-types";
import React, {createContext, useContext, useEffect, useLayoutEffect, useState} from "react";
import {regionsApi, siteDetailsApi} from "../../../SiteConfig";
import {initializeAnalytics, initializeAnalytics4, useAnalyticPageViews} from "../../../Utilities/analyticUtilities";
import {useCounter} from "../../../Utilities/Reducer";
import {emptyFunction, returnFalse, returnTrue} from "../../../Utilities/testingUtilities";
import {fetchFromCDN} from "./contextUtility";

export const AppContext = createContext([]);
export const useAppContext = () => useContext(AppContext);

export const AppContextProvider = ({children}) => {
    const calls = useCounter();
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingCss, setIsLoadingCss] = useState(true);
    const [returnUrl, setReturnUrl] = useState(undefined);
    const [siteDetails, setSiteDetails] = useState(undefined);
    const [allSiteDetails, setAllSiteDetails] = useState([]);
    const [gaInitialized, setGaInitialized] = useState(false);
    const [regions, setRegions] = useState([]);

    useAnalyticPageViews(siteDetails, gaInitialized);

    const addSiteGroupId = (object) => {
        return {...object, siteGroupId: siteDetails.siteGroupId};
    };

    const handleApiCallWithNoDim = (apiCall, thenCallback = emptyFunction, catchCallback = emptyFunction) => {
        handleApiCall(apiCall, thenCallback, catchCallback, false);
    };

    const handleApiCall = (apiCall, thenCallback = returnTrue, catchCallback = returnFalse, useCounter = true) => {
        if (useCounter)
            calls.increment();

        return new Promise((resolve) => {
            apiCall()
                .then((result) => {
                    if (useCounter)
                        calls.decrement();

                    resolve(thenCallback(result));
                })
                .catch((error) => {
                    if (useCounter)
                        calls.decrement();

                    resolve(catchCallback(error));
                });
        });

    };

    useEffect(() => {
        if (isLoading && calls.count === 0)
            setIsLoading(false);
        if (!isLoading && calls.count > 0)
            setIsLoading(true);
        if (calls.count < 0)
            calls.reset();
    }, [calls.count, isLoading]);

    useLayoutEffect(() => {
        const fetchSiteDetails = () => {
            handleApiCall(siteDetailsApi.getSiteDetails, setSiteDetails);
            handleApiCall(siteDetailsApi.getAllSiteDetails, setAllSiteDetails);
        };

        const fetchRegions = () => {
            handleApiCall(regionsApi.getRegions, setRegions);
        };

        fetchSiteDetails();
        fetchRegions();
    }, []);

    useLayoutEffect(() => {
        if (siteDetails) {
            if (siteDetails.gaTrackingCode) {
                initializeAnalytics(siteDetails.gaTrackingCode);
                setGaInitialized(true);
            }
            if (siteDetails.gaTrackingCode4) {
                initializeAnalytics4(siteDetails.gaTrackingCode4);
                setGaInitialized(true);
            }
            fetchFromCDN(siteDetails.cdnDirectory, siteDetails.cssVersion, siteDetails.cdnColor)
                .then(() => setIsLoadingCss(false));
        }

    }, [siteDetails]);

    return (
        <AppContext.Provider
            value={{
                addSiteGroupId,
                isLoading,
                isLoadingCss,
                handleApiCall,
                handleApiCallWithNoDim,
                returnUrl,
                setReturnUrl,
                siteDetails,
                allSiteDetails,
                regions,
            }}
        >
            {children}
        </AppContext.Provider>
    );
};

AppContextProvider.propTypes = {
    children: PropTypes.any,
};
