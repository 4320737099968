import PropTypes from "prop-types";
import React from "react";
import * as storageUtilities from "../../Utilities/LocalStorage/storageUtilities";
import {locations} from "../../Utilities/Location";
import {GridCell, GridContainer, GridFlexSpacer} from "../Grid";
import {LinkButton} from "../Link";
import {HeaderBasic} from "./HeaderBasic";
import HeaderOneColumn from "./HeaderOneColumn";
import HeaderTwoColumn from "./HeaderTwoColumn";
import HeaderNav from "./HeaderNav/HeaderNav";
import {Main} from "./Main";

const TemplateHeaderNav = (props) => {
    const {children, Header = HeaderBasic, isAdmin, isHeaderAdminOnly} = props;
    const showHeader = isHeaderAdminOnly && !isAdmin ? false : true;

    return (
        <>
            {
                showHeader &&
                <>
                    <Header/>
                    <HeaderNav {...props}/>
                </>
            }
            <Main>
                <GridContainer hasMarginX hasMarginY>
                    <GridFlexSpacer>
                        {children}
                    </GridFlexSpacer>
                </GridContainer>
            </Main>
        </>
    );
};

TemplateHeaderNav.propTypes = {
    children: PropTypes.any,
    Header: PropTypes.element,
    isAdmin: PropTypes.bool,
    isHeaderAdminOnly: PropTypes.bool,
};
export const TemplateBasicHeaderNav = TemplateHeaderNav;

const aphHeaderProps = {
    leftLabel: `APH Hive`,
    rightLabel: `American Printing House`,
    rightUrl: `//aph.org`,
};
const aphProps = {
    Header: () => HeaderTwoColumn(aphHeaderProps),
};
export const TemplateAPH = (props) => TemplateHeaderNav({...props, ...aphProps});

const doeOssProps = {
    Header: () => HeaderOneColumn({label: `Office of Safe Schools`}),
};
export const TemplateDoeOss = (props) => TemplateHeaderNav({...props, ...doeOssProps});

const maprProps = {
    Header: () => HeaderOneColumn({label: `MAP-R`}),
};
export const TemplateMAPR = (props) => {
    const showStudentLink = props.path === locations.LANDING.path;
    const navClass = showStudentLink ? "--student" : "--researcher";
    const linkDetails = showStudentLink ? locations.SIGN_IN.link : locations.LANDING.link;
    const isImpersonating = storageUtilities.getRoleIdOfImpersonator();
    return <>
        {
            !props.isLoggedIn &&
            <nav className={`headerNav headerNav${navClass}`}>
                <GridContainer hasMarginX>
                    <GridCell>
                        <LinkButton {...linkDetails} />
                    </GridCell>
                </GridContainer>
            </nav>
        }
        {TemplateHeaderNav({...props, ...maprProps, isHeaderAdminOnly: !isImpersonating})}
    </>
}

const dohProps = {
    Header: () => HeaderOneColumn({label: `Florida Health: Children’s Medical Services Online Training`}),
};
export const TemplateDOH = (props) => TemplateHeaderNav({...props, ...dohProps});

const fcrrHeaderProps = {
    leftLabel: `FCRR Academy`,
    rightLabel: `FCRR`,
    rightUrl: `//fcrr.fsu.edu`,
};
const fcrrProps = {
    Header: () => HeaderTwoColumn(fcrrHeaderProps),
};
export const TemplateFCRR = (props) => TemplateHeaderNav({...props, ...fcrrProps});

const ficwHeaderProps = {
    leftLabel: `GROW Center`,
    rightLabel: `FICW`,
    rightUrl: `//ficw.fsu.edu`,
};
const ficwProps = {
    Header: () => HeaderTwoColumn(ficwHeaderProps),
};
export const TemplateFICW = (props) => TemplateHeaderNav({...props, ...ficwProps});
