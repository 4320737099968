import {searchModel} from "../../Utilities/Search";

export const enrollmentSearchModel = (userId) => {
	return Object.assign({}, searchModel(userId.toString(), "courseTitle", false,
		"courseTitle", 10), {
		ClearEnrollmentSearch: clearEnrollmentSearch,
	});
};

function clearEnrollmentSearch() {
	this.ClearSearch(false);
}