import {createListFromObject} from "../../Utilities/Types/listUtilities";

export const elementTypes = {
	TEXTBOX: "TextBox",
	TEXTAREA: "TextArea",
	DROPDOWN: "Dropdown",
	RADIO: "Radio",
	CHECKBOX: "Checkbox",
	INLINE_RADIO: "InlineRadio",
	TITLE: "Title",
	OPTION: "Option",
};

export const ELEMENT_TYPE_ID = {
	TextBox: 1,
	TextArea: 2,
	Dropdown: 3,
	Radio: 4,
	Checkbox: 5,
	InlineRadio: 6,
	Title: 10,
	Option: 11,
	RadioWithTrueOrFalse: 51,
	InlineRadioWithOneThroughFive: 52
};

export const ELEMENT_TYPE = {
	1: "TextBox",
	2: "TextArea",
	3: "Dropdown",
	4: "Radio",
	5: "Checkbox",
	6: "InlineRadio",
	10: "Title",
	11: "Option",
};
export const elementTypeList = createListFromObject(ELEMENT_TYPE_ID);

export const TITLE_TYPES = {
	PageSet: "Page Set",
	ComponentSet: "Component Set"
};