import React from "react";
import { FieldsetAdapter, fieldsetAdapterProps } from "./FieldsetAdapter";
import { inputType } from "./inputConstants";
import { createFakeEvent } from "./inputUtility";

export const RadioList = (props) => {

    const { onChange } = props;

    const handleChange = (event) => {
        const { name, value } = event.target;

        return onChange(createFakeEvent(value, name));
    };
    return <FieldsetAdapter {...props} onChange={handleChange} inputType={inputType.RADIO}/>;
};

RadioList.propTypes = {
    ...fieldsetAdapterProps,
};