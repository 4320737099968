export const REPORT_FORM_FIELDS = {
    StartDate: "startDate",
    EndDate: "endDate",
    ReportType: "reportType",
    SiteGroupId: "siteGroupId",
    CourseId: "courseId",
    DistrictName: "districtName",
    UserInfo: "userInfo"
};

export const DEFAULT_REQUIRED_FIELDS = [
    REPORT_FORM_FIELDS.StartDate,
    REPORT_FORM_FIELDS.EndDate,
    REPORT_FORM_FIELDS.ReportType,
    REPORT_FORM_FIELDS.SiteGroupId
];