import {getRoleList, ROLE_NAMES, ROLES} from "../../Components/Authorize/roleConstants";
import {MOCK_SITE_GROUP_ID} from "../../SiteConfig/siteConstants";
import {isNullOrUndefined} from "../../Utilities/commonUtilities";
import {
	addSiteGroupId,
	fetchUser,
	handleApiCall,
	handleReturnRedirect,
	redirect,
	setWarnings,
	siteDetailsForTesting as siteDetails,
} from "../../Utilities/testingUtilities";
import {convertToDateTimeString, getTodayDate, getTodayDateAsString} from "../../Utilities/Types/dateUtilities";
import {categoryId} from "../Category/CategorySearch/categorySearchMockData";
import {courseId, getCourses} from "../Course/courseMockData";
import {NextTaskLocation} from "../Dashboard/dashboardConstants";
import {
	aphSiteDetails,
	doeOssSiteDetails,
	dohSiteDetails,
	earlyAccessSiteDetails,
	fcrrSiteDetails,
	fgrbiSiteDetails,
	ficwSiteDetails,
	maprSiteDetails,
	pdaSiteDetails,
	siteDetails as mockSiteDetails
} from "../../SiteConfig/siteDetailsMockData";
import {followUpStatus} from "../FollowUpApproval/followUpApprovalConstants";
import {moduleLastAccessedModel, userModel} from "./userFactory";
import {userSearchResults} from "./UserSearch/userSearchFactory";
import {isValidUser} from "./userUtilities";
import {searchMockData} from "../../Utilities/Search";

const currentSiteDetails = mockSiteDetails.find(f => f.siteGroupId === MOCK_SITE_GROUP_ID);
const date = getTodayDateAsString();
const fakeToken = (refreshToken, isAdmin = false) => {
	return {accessToken: "accessing", refreshToken: refreshToken, isAdmin: isAdmin};
};
let accounts = null;
let userIdAi = null;
let enrollmentIdAi = null;
const courses = getCourses();

export const getNewEnrollmentId = () => {
	enrollmentIdAi = 0;

	if (accounts) {
		for(const account of accounts) {
			if (account.enrollments) {
				for(const enrollment of account.enrollments) {
					if (enrollment.enrollmentId > enrollmentIdAi) enrollmentIdAi = enrollment.enrollmentId;
				}
			}
		}
	}

	return ++enrollmentIdAi;
};

export const getNewUserId = () => {
	userIdAi = 0;

	if (accounts) {
		for(const account of accounts) {
			if (account.userId > userIdAi) userIdAi = account.userId;
		}
	}

	return ++userIdAi;
};

const adminData = [
	{
		email: "sysadmin@fcim.org",
		password: "123",
		createDate: date,
		enrollments: [],
		firstName: "Admin",
		lastName: "User",
		userId: 2,
		roleId: ROLES.SystemAdmin,
		siteGroupId: aphSiteDetails.siteGroupId,
		siteAbbreviation: aphSiteDetails.siteAbbreviation,
		userRole: ROLE_NAMES.SystemAdmin,
		...fakeToken("2", true),
	},
	{
		userId: 10,
		firstName: "Coord",
		lastName: "Dinate",
		email: "coord@fcim.org",
		password: "123",
		roleId: ROLES.RegionalDataViewer,
		siteGroupId: aphSiteDetails.siteGroupId,
		siteAbbreviation: aphSiteDetails.siteAbbreviation,
		userRole: ROLE_NAMES.RegionalDataViewer,
		enrollments: [],
		createDate: date,
		...fakeToken("10", true),
	},
	{
		userId: 11,
		firstName: "Money",
		lastName: "Spunny",
		email: "money@fcim.org",
		password: "123",
		roleId: ROLES.AllDataViewer,
		siteGroupId: aphSiteDetails.siteGroupId,
		siteAbbreviation: aphSiteDetails.siteAbbreviation,
		userRole: ROLE_NAMES.AllDataViewer,
		enrollments: [],
		createDate: date,
		...fakeToken("11", true),
	},
	{
		userId: 12,
		firstName: "Jim",
		lastName: "Boo",
		email: "client@fcim.org",
		password: "123",
		roleId: ROLES.SiteAdmin,
		siteGroupId: aphSiteDetails.siteGroupId,
		siteAbbreviation: aphSiteDetails.siteAbbreviation,
		userRole: ROLE_NAMES.SiteAdmin,
		enrollments: [],
		createDate: date,
		...fakeToken("12", true),
	},
	{
		userId: 25,
		email: "support@fcim.org",
		password: "123",
		createDate: date,
		enrollments: [],
		firstName: "Tech",
		lastName: "Support",
		roleId: ROLES.TechSupport,
		siteGroupId: aphSiteDetails.siteGroupId,
		siteAbbreviation: aphSiteDetails.siteAbbreviation,
		userRole: ROLE_NAMES.TechSupport,
		...fakeToken("25", true),
	},
];

const userData = [
	{
		email: "kevin@fcim.org",
		password: "123",
		createDate: date,
		enrollments: [
			{
				enrollmentId: 1,
				courseId: 1,
				userId: 1,
				followUpActivityFileId: 5,
				followUpStatus: followUpStatus.Submitted,
				followUpStatusIsRejected: false,
				followUpRejectedReason: "",
				followUpStatusIsApproved: false,
				followUpActivityFileCreationDate: date,
				isArchived: false,
				creditOptionSelected: 0,
				creditEarned: "",
				dateCreated: date,
				dateCompleted: date,
				courseTitle: "Course Title",
				assessmentDate: date,
				surveyDate: date,
				allModulesComplete: true,
				moduleLastAccessed: {
					moduleId: 1,
				},
				progress: [
					{
						moduleId: 1,
						dateCreated: date,
						isAvailable: true,
					},
					{
						moduleId: 2,
						dateCreated: date,
						isAvailable: false,
					},
				],
				taskStatuses: [
					{
						title: "View Certificate",
						nextTaskLocation: NextTaskLocation.Certificate,
						surveyType: null,
						isPrimary: true,
						isEnabled: true,
						isRequired: false,
						isCompleted: false,
					},
					{
						title: "Review Course",
						nextTaskLocation: NextTaskLocation.ReviewCourse,
						surveyType: null,
						isPrimary: false,
						isEnabled: true,
						isRequired: false,
						isCompleted: false,
					},
				],
			},
			{
				enrollmentId: 6,
				courseId: 4,
				userId: 1,
				followUpActivityFileId: null,
				followUpStatus: "",
				followUpStatusIsRejected: false,
				followUpRejectedReason: "",
				followUpStatusIsApproved: false,
				followUpActivityFileCreationDate: "",
				isArchived: false,
				creditOptionSelected: 0,
				creditEarned: "",
				dateCreated: date,
				dateCompleted: "",
				courseTitle: "Course Title",
				assessmentDate: "",
				surveyDate: "",
				allModulesComplete: false,
				moduleLastAccessed: {
					moduleId: 1,
				},
				progress: [
					{
						moduleId: 1,
						dateCreated: date,
						isAvailable: true,
					},
				],
				taskStatuses: [
					{
						title: "Demographic Survey",
						nextTaskLocation: NextTaskLocation.Survey,
						surveyType: 1,
						isPrimary: true,
						isEnabled: true,
						isRequired: false,
						isCompleted: false,
					},
					{
						title: "Preassessment",
						nextTaskLocation: NextTaskLocation.Survey,
						surveyType: 2,
						isPrimary: true,
						isEnabled: true,
						isRequired: false,
						isCompleted: false,
					},
					{
						title: "View Course",
						nextTaskLocation: NextTaskLocation.Course,
						surveyType: null,
						isPrimary: true,
						isEnabled: true,
						isRequired: false,
						isCompleted: false,
					},
					{
						title: "Assessment",
						nextTaskLocation: NextTaskLocation.Survey,
						surveyType: 3,
						isPrimary: true,
						isEnabled: true,
						isRequired: true,
						isCompleted: false,
					},
					{
						title: "View Follow-up Activity",
						nextTaskLocation: NextTaskLocation.FollowUp,
						surveyType: null,
						isPrimary: true,
						isEnabled: true,
						isRequired: false,
						isCompleted: false,
					},
					{
						title: "Survey",
						nextTaskLocation: NextTaskLocation.Survey,
						surveyType: 4,
						isPrimary: true,
						isEnabled: true,
						isRequired: true,
						isCompleted: false,
					},
				],
			},
		],
		firstName: "Kevin",
		lastName: "Deak",
		userId: 1,
		roleId: ROLES.Participant,
		siteGroupId: aphSiteDetails.siteGroupId,
		siteAbbreviation: aphSiteDetails.siteAbbreviation,
		userRole: ROLE_NAMES.Participant,
		...fakeToken(),
	},
	{
		email: "kevin@fcim.org",
		password: "123",
		createDate: date,
		enrollments: [
			{
				enrollmentId: 21,
				courseId: 21,
				userId: 21,
				followUpActivityFileId: 5,
				followUpStatus: followUpStatus.Approved,
				followUpStatusIsRejected: false,
				followUpRejectedReason: "",
				followUpStatusIsApproved: true,
				followUpActivityFileCreationDate: date,
				isArchived: false,
				creditOptionSelected: 0,
				creditEarned: "",
				dateCreated: date,
				dateCompleted: null,
				courseTitle: "Course Title",
				assessmentDate: null,
				surveyDate: null,
				allModulesComplete: true,
				moduleLastAccessed: {
					moduleId: 1,
				},
				progress: [
					{
						moduleId: 1,
						dateCreated: date,
						isAvailable: true,
					},
				],
				taskStatuses: [
					{
						title: "Demographic Survey",
						nextTaskLocation: NextTaskLocation.Survey,
						surveyType: 1,
						isPrimary: true,
						isEnabled: true,
						isRequired: false,
						isCompleted: false,
					},
					{
						title: "View Course",
						nextTaskLocation: NextTaskLocation.Course,
						surveyType: null,
						isPrimary: true,
						isEnabled: true,
						isRequired: false,
						isCompleted: false,
					},
					{
						title: "View Follow-up Activity",
						nextTaskLocation: NextTaskLocation.FollowUp,
						surveyType: null,
						isPrimary: true,
						isEnabled: true,
						isRequired: false,
						isCompleted: false,
					},
					{
						title: "View Certificate",
						nextTaskLocation: NextTaskLocation.Certificate,
						surveyType: null,
						isPrimary: true,
						isEnabled: true,
						isRequired: false,
						isCompleted: false,
					},
				],
			},
		],
		firstName: "Kevin",
		lastName: "Deak",
		userId: 21,
		roleId: ROLES.Participant,
		siteGroupId: fgrbiSiteDetails.siteGroupId,
		siteAbbreviation: fgrbiSiteDetails.siteAbbreviation,
		userRole: ROLE_NAMES.Participant,
		...fakeToken("1"),
	},
	{
		email: "kevin@fcim.org",
		password: "123",
		createDate: date,
		enrollments: [
			{
				enrollmentId: 23,
				courseId: 23,
				userId: 23,
				followUpActivityFileId: 5,
				followUpStatus: followUpStatus.Approved,
				followUpStatusIsRejected: false,
				followUpRejectedReason: "",
				followUpStatusIsApproved: true,
				followUpActivityFileCreationDate: date,
				isArchived: false,
				creditOptionSelected: 0,
				creditEarned: "",
				dateCreated: date,
				dateCompleted: null,
				courseTitle: "Course Title",
				assessmentDate: null,
				surveyDate: null,
				allModulesComplete: true,
				moduleLastAccessed: {
					moduleId: 1,
				},
				progress: [
					{
						moduleId: 1,
						dateCreated: date,
						isAvailable: true,
					},
				],
				taskStatuses: [
					{
						title: "Demographic Survey",
						nextTaskLocation: NextTaskLocation.Survey,
						surveyType: 1,
						isPrimary: true,
						isEnabled: true,
						isRequired: false,
						isCompleted: false,
					},
					{
						title: "View Course",
						nextTaskLocation: NextTaskLocation.Course,
						surveyType: null,
						isPrimary: true,
						isEnabled: true,
						isRequired: false,
						isCompleted: false,
					},
					{
						title: "View Follow-up Activity",
						nextTaskLocation: NextTaskLocation.FollowUp,
						surveyType: null,
						isPrimary: true,
						isEnabled: true,
						isRequired: false,
						isCompleted: false,
					},
					{
						title: "View Certificate",
						nextTaskLocation: NextTaskLocation.Certificate,
						surveyType: null,
						isPrimary: true,
						isEnabled: true,
						isRequired: false,
						isCompleted: false,
					},
				],
			},
		],
		firstName: "Kevin",
		lastName: "Deak",
		userId: 23,
		roleId: ROLES.Participant,
		siteGroupId: earlyAccessSiteDetails.siteGroupId,
		siteAbbreviation: earlyAccessSiteDetails.siteAbbreviation,
		userRole: ROLE_NAMES.Participant,
		...fakeToken("1"),
	},
	{
		email: "kevin@fcim.org",
		password: "123",
		createDate: date,
		enrollments: [
			{
				enrollmentId: 22,
				courseId: 22,
				userId: 22,
				followUpActivityFileId: 5,
				followUpStatus: followUpStatus.Approved,
				followUpStatusIsRejected: false,
				followUpRejectedReason: "",
				followUpStatusIsApproved: true,
				followUpActivityFileCreationDate: date,
				isArchived: false,
				creditOptionSelected: 0,
				creditEarned: "",
				dateCreated: date,
				dateCompleted: null,
				courseTitle: "Course Title",
				assessmentDate: null,
				surveyDate: null,
				allModulesComplete: true,
				moduleLastAccessed: {
					moduleId: 1,
				},
				progress: [
					{
						moduleId: 1,
						dateCreated: date,
						isAvailable: true,
					},
				],
				taskStatuses: [
					{
						title: "Demographic Survey",
						nextTaskLocation: NextTaskLocation.Survey,
						surveyType: 1,
						isPrimary: true,
						isEnabled: true,
						isRequired: false,
						isCompleted: false,
					},
					{
						title: "View Course",
						nextTaskLocation: NextTaskLocation.Course,
						surveyType: null,
						isPrimary: true,
						isEnabled: true,
						isRequired: false,
						isCompleted: false,
					},
					{
						title: "View Follow-up Activity",
						nextTaskLocation: NextTaskLocation.FollowUp,
						surveyType: null,
						isPrimary: true,
						isEnabled: true,
						isRequired: false,
						isCompleted: false,
					},
					{
						title: "View Certificate",
						nextTaskLocation: NextTaskLocation.Certificate,
						surveyType: null,
						isPrimary: true,
						isEnabled: true,
						isRequired: false,
						isCompleted: false,
					},
				],
			},
		],
		firstName: "Kevin",
		lastName: "Deak",
		userId: 22,
		roleId: ROLES.Participant,
		siteGroupId: pdaSiteDetails.siteGroupId,
		siteAbbreviation: pdaSiteDetails.siteAbbreviation,
		userRole: ROLE_NAMES.Participant,
		...fakeToken("1"),
	},
	{
		userId: 3,
		firstName: "Stephen",
		lastName: "Griffin",
		email: "steveg@fcim.org",
		password: "123",
		roleId: ROLES.Participant,
		siteGroupId: aphSiteDetails.siteGroupId,
		siteAbbreviation: aphSiteDetails.siteAbbreviation,
		userRole: ROLE_NAMES.Participant,
		enrollments: [
			{
				enrollmentId: 2,
				courseId: 1,
				userId: 3,
				followUpActivityFileId: null,
				followUpStatus: "",
				followUpStatusIsRejected: false,
				followUpRejectedReason: "",
				followUpStatusIsApproved: false,
				followUpActivityFileCreationDate: "",
				isArchived: false,
				creditOptionSelected: 0,
				creditEarned: "",
				dateCreated: date,
				dateCompleted: "",
				courseTitle: "Course Title",
				assessmentDate: "",
				surveyDate: "",
				allModulesComplete: false,
				moduleLastAccessed: {
					moduleId: 1,
				},
				progress: [
					{
						moduleId: 1,
						dateCreated: date,
						isAvailable: true,
					},
				],
				taskStatuses: [{
					title: "View Course",
					nextTaskLocation: NextTaskLocation.Course,
					surveyType: null,
					isPrimary: true,
					isEnabled: true,
					isRequired: false,
					isCompleted: false,
				}],
			},
		],
		createDate: date,
		...fakeToken("3"),
	},
	{
		userId: 4,
		firstName: "Jane",
		lastName: "Doe",
		email: "jdoe@fcim.org",
		password: "123",
		roleId: ROLES.Participant,
		siteGroupId: aphSiteDetails.siteGroupId,
		siteAbbreviation: aphSiteDetails.siteAbbreviation,
		userRole: ROLE_NAMES.Participant,
		enrollments: [
			{
				enrollmentId: 3,
				courseId: 1,
				userId: 4,
				followUpActivityFileId: null,
				followUpStatus: "",
				followUpStatusIsRejected: false,
				followUpRejectedReason: "",
				followUpStatusIsApproved: false,
				followUpActivityFileCreationDate: "",
				isArchived: false,
				creditOptionSelected: 0,
				creditEarned: "",
				dateCreated: date,
				dateCompleted: "",
				allModulesComplete: false,
				courseTitle: "Course Title",
				assessmentDate: "",
				surveyDate: "",
				moduleLastAccessed: {
					moduleId: 1,
				},
				progress: [
					{
						moduleId: 1,
						dateCreated: date,
						isAvailable: true,
					},
				],
				taskStatuses: [{
					title: "View Course",
					nextTaskLocation: NextTaskLocation.Course,
					surveyType: null,
					isPrimary: true,
					isEnabled: true,
					isRequired: false,
					isCompleted: false,
				}],
			},
			{
				enrollmentId: 4,
				courseId: 2,
				userId: 4,
				followUpActivityFileId: null,
				followUpStatus: "",
				followUpStatusIsRejected: false,
				followUpRejectedReason: "",
				followUpStatusIsApproved: false,
				followUpActivityFileCreationDate: "",
				isArchived: false,
				creditOptionSelected: 0,
				creditEarned: "",
				dateCreated: date,
				dateCompleted: "",
				courseTitle: "Course Title",
				assessmentDate: "",
				surveyDate: "",
				allModulesComplete: false,
				moduleLastAccessed: {
					moduleId: 1,
				},
				progress: [
					{
						moduleId: 1,
						dateCreated: date,
						isAvailable: true,
					},
				],
				taskStatuses: [{
					title: "View Course",
					nextTaskLocation: NextTaskLocation.Course,
					surveyType: null,
					isPrimary: true,
					isEnabled: true,
					isRequired: false,
					isCompleted: false,
				}],
			},
		],
		createDate: date,
		...fakeToken("4"),
	},
	{
		userId: 5,
		firstName: "Abigail",
		lastName: "Tudo",
		email: "abigail@fcim.org",
		password: "123",
		roleId: ROLES.Participant,
		siteGroupId: aphSiteDetails.siteGroupId,
		siteAbbreviation: aphSiteDetails.siteAbbreviation,
		userRole: ROLE_NAMES.Participant,
		enrollments: [
			{
				enrollmentId: 5,
				courseId: 2,
				userId: 5,
				followUpActivityFileId: null,
				followUpStatus: "",
				followUpStatusIsRejected: false,
				followUpRejectedReason: "",
				followUpStatusIsApproved: false,
				followUpActivityFileCreationDate: "",
				isArchived: false,
				creditOptionSelected: 0,
				creditEarned: "",
				dateCreated: date,
				dateCompleted: "",
				courseTitle: "Course Title",
				assessmentDate: "",
				surveyDate: "",
				allModulesComplete: false,
				moduleLastAccessed: {
					moduleId: 1,
				},
				progress: [
					{
						moduleId: 1,
						dateCreated: date,
						isAvailable: true,
					},
				],
				taskStatuses: [{
					title: "View Course",
					nextTaskLocation: NextTaskLocation.Course,
					surveyType: null,
					isPrimary: true,
					isEnabled: true,
					isRequired: false,
					isCompleted: false,
				}],
			},
		],
		createDate: date,
		...fakeToken("5"),
	},
	{
		userId: 6,
		firstName: "Billy",
		lastName: "Butcher",
		email: "billy@fcim.org",
		password: "123",
		roleId: ROLES.Participant,
		siteGroupId: aphSiteDetails.siteGroupId,
		siteAbbreviation: aphSiteDetails.siteAbbreviation,
		userRole: ROLE_NAMES.Participant,
		enrollments: [],
		createDate: date,
		...fakeToken("6"),
	},
	{
		userId: 7,
		firstName: "Zoo",
		lastName: "Crazy",
		email: "zoo@fcim.org",
		password: "123",
		roleId: ROLES.Participant,
		siteGroupId: aphSiteDetails.siteGroupId,
		siteAbbreviation: aphSiteDetails.siteAbbreviation,
		userRole: ROLE_NAMES.Participant,
		enrollments: [],
		createDate: date,
		...fakeToken("7"),
	},
	{
		userId: 8,
		firstName: "Grey",
		lastName: "Bird",
		email: "grey@fcim.org",
		password: "123",
		roleId: ROLES.AllAccessParticipant,
		siteGroupId: aphSiteDetails.siteGroupId,
		siteAbbreviation: aphSiteDetails.siteAbbreviation,
		userRole: ROLE_NAMES.AllAccessParticipant,
		enrollments: [],
		createDate: date,
		...fakeToken("8"),
	},
	{
		userId: 9,
		firstName: "Punk",
		lastName: "Chunk",
		email: "punk@fcim.org",
		password: "123",
		roleId: ROLES.DemoParticipant,
		siteGroupId: aphSiteDetails.siteGroupId,
		siteAbbreviation: aphSiteDetails.siteAbbreviation,
		userRole: ROLE_NAMES.DemoParticipant,
		enrollments: [],
		createDate: date,
		...fakeToken("9"),
	},
	{
		userId: 13,
		firstName: "Iowa",
		lastName: "User",
		email: "iowa@fcim.org",
		password: "123",
		roleId: ROLES.Participant,
		siteGroupId: fgrbiSiteDetails.siteGroupId,
		siteAbbreviation: fgrbiSiteDetails.siteAbbreviation,
		userRole: ROLE_NAMES.Participant,
		enrollments: [],
		createDate: date,
		...fakeToken("13"),
	},
	{
		userId: 14,
		firstName: "fcrr",
		lastName: "fcrr",
		email: "fcrr@fcim.org",
		password: "123",
		roleId: ROLES.Participant,
		siteGroupId: fcrrSiteDetails.siteGroupId,
		siteAbbreviation: fcrrSiteDetails.siteAbbreviation,
		userRole: ROLE_NAMES.Participant,
		enrollments: [],
		createDate: date,
		...fakeToken("14"),
	},
	{
		userId: 15,
		firstName: "ficw",
		lastName: "ficw",
		email: "ficw@fcim.org",
		password: "123",
		roleId: ROLES.Participant,
		siteGroupId: ficwSiteDetails.siteGroupId,
		siteAbbreviation: ficwSiteDetails.siteAbbreviation,
		userRole: ROLE_NAMES.Participant,
		enrollments: [],
		createDate: date,
		...fakeToken("15"),
	},
	{
		userId: 16,
		firstName: "doh",
		lastName: "doh",
		email: "doh@fcim.org",
		password: "123",
		roleId: ROLES.Participant,
		siteGroupId: dohSiteDetails.siteGroupId,
		siteAbbreviation: dohSiteDetails.siteAbbreviation,
		userRole: ROLE_NAMES.Participant,
		enrollments: [],
		createDate: date,
		...fakeToken("16"),
	},
	{
		userId: 17,
		firstName: "doe",
		lastName: "doe",
		email: "doe@fcim.org",
		password: "123",
		roleId: ROLES.Participant,
		siteGroupId: doeOssSiteDetails.siteGroupId,
		siteAbbreviation: doeOssSiteDetails.siteAbbreviation,
		userRole: ROLE_NAMES.Participant,
		enrollments: [],
		createDate: date,
		...fakeToken("17"),
	},
	{
		userId: 18,
		firstName: "Fcrr",
		lastName: "Mapr",
		email: "doe@fcim.org",
		password: "123",
		roleId: ROLES.Participant,
		siteGroupId: maprSiteDetails.siteGroupId,
		siteAbbreviation: maprSiteDetails.siteAbbreviation,
		userRole: ROLE_NAMES.Participant,
		enrollments: [],
		createDate: date,
		...fakeToken("18"),
	},
];

export const adminUser = userModel(adminData[0]);
export const user = userModel(userData[0]);

export const getAccounts = (reset = false) => {
	if (accounts === null || reset)
		accounts = [...adminData, ...userData];
	return accounts;
};

export const getUsersWithSubmittedFollowUpActivities = () => {
	const allUsersFromSite = getAccounts().filter(f => f.siteGroupId === MOCK_SITE_GROUP_ID && (f.roleId === ROLES.Participant || f.roleId === ROLES.DemoParticipant));

	let followUpApprovals = [];
	for (let user of allUsersFromSite) {
		for (let enrollment of user.enrollments.filter(f => !isNullOrUndefined(f.followUpActivityFileId) && !f.followUpStatusIsApproved && !f.followUpStatusIsRejected)) {
			let approvalDetails = {
				userId: user.userId,
				firstName: user.firstName,
				lastName: user.lastName,
				email: user.email,
				userRole: user.userRole,
				courseTitle: courses.find(c => c.courseId === enrollment.courseId).title,
				courseId: enrollment.courseId,
				enrollmentId: enrollment.enrollmentId,
				dateCreated: enrollment.dateCreated,
				followUpActivityFileId: enrollment.followUpActivityFileId,
				followUpStatus: enrollment.followUpStatus,
				followUpStatusIsApproved: enrollment.followUpStatusIsApproved,
				followUpStatusIsRejected: enrollment.followUpStatusIsRejected,
				followUpRejectedReason: enrollment.followUpRejectedReason,
				followUpActivityFileCreationDate: enrollment.followUpActivityFileCreationDate,
				isArchived: false,
				creditOptionSelected: 0,
				creditEarned: ""
			};
			followUpApprovals.push(approvalDetails);
		}
	}

	return followUpApprovals;
};

export const updateStatusForSubmittedFollowUpActivities = (updatedFollowUpStatuses) => {
	const allUsersFromSite = getAccounts().filter(f => f.siteGroupId === MOCK_SITE_GROUP_ID && (f.roleId === ROLES.Participant || f.roleId === ROLES.DemoParticipant));
	const allEnrollmentIdsUpdated = updatedFollowUpStatuses.map(m => m.enrollmentId);
	for (let user of allUsersFromSite) {
		for (let enrollment of user.enrollments.filter(f => allEnrollmentIdsUpdated.indexOf(f.enrollmentId) > -1)) {
			const updatedEnrollment = updatedFollowUpStatuses.find(f => f.enrollmentId === enrollment.enrollmentId);

			enrollment.followUpStatusIsRejected = updatedEnrollment.followUpStatus === followUpStatus.Rejected;
			enrollment.followUpStatusIsApproved = updatedEnrollment.followUpStatus === followUpStatus.Approved;
			enrollment.followUpStatus = updatedEnrollment.followUpStatus;
			enrollment.followUpRejectedReason = updatedEnrollment.followUpRejectedReason;
		}
	}
};

export const addEnrollment = (courseId, userId, dateCompleted = null) => {
	if (!courseId || !userId || userId < 1) return 0;

	const enrollmentId = getNewEnrollmentId();
	let account = getAccount(userId);

	if (!isValidUser(account)) return 0;

	courseId = parseInt(courseId);

	if (!account.enrollments)
		account.enrollments = [];

	account.enrollments.push(
		{
			enrollmentId,
			courseId,
			courseTitle: courses.find(c => c.courseId === courseId).courseTitle,
			userId,
			dateCreated: date,
			dateCompleted: dateCompleted,
			progress: [],
			followUpActivityFileId: null,
			followUpStatus: "",
			followUpStatusIsRejected: false,
			followUpRejectedReason: "",
			followUpStatusIsApproved: false,
			followUpActivityFileCreationDate: "",
			isArchived: false,
			creditOptionSelected: 0,
			creditEarned: "",
			allModulesComplete: false,
			taskStatuses: [{
				title: "View Course",
				nextTaskLocation: "Course",
				surveyType: null,
				isPrimary: true,
				isEnabled: true,
				isRequired: false,
				isCompleted: false,
			}],
		});
	return enrollmentId;
};

export const getCourseEventEnrollments = (courseId) => {
	const allUsersFromSite = getAccounts().filter(f => f.siteGroupId === MOCK_SITE_GROUP_ID && (f.roleId === ROLES.Participant || f.roleId === ROLES.DemoParticipant));

	let courseEventEnrollments = [];
	for (let user of allUsersFromSite) {
		const userEnrollment = user.enrollments.find(f => f.courseId === courseId);
		if(!userEnrollment)
			continue;

		let approvalDetails = {
			enrollmentId: userEnrollment.enrollmentId,
			userId: userEnrollment.userId,
			firstName: user.firstName,
			lastName: user.lastName,
			dateCreated: userEnrollment.dateCreated
		};
		courseEventEnrollments.push(approvalDetails);
	}

	return courseEventEnrollments;
};

export const getCourseTrainingEnrollments = (courseId) => {
	const allUsersFromSite = getAccounts().filter(f => f.siteGroupId === MOCK_SITE_GROUP_ID && (f.roleId === ROLES.Participant || f.roleId === ROLES.DemoParticipant));

	let courseTrainingEnrollments = [];
	for (let user of allUsersFromSite) {
		const userEnrollment = user.enrollments.find(f => f.courseId === courseId);
		if (!userEnrollment)
			continue;

		let approvalDetails = {
			enrollmentId: userEnrollment.enrollmentId,
			userId: userEnrollment.userId,
			firstName: user.firstName,
			lastName: user.lastName,
			dateCreated: userEnrollment.dateCreated
		};
		courseTrainingEnrollments.push(approvalDetails);
	}

	return courseTrainingEnrollments;
};

export const getCourseTrainingEnrollmentDetail = (courseId, enrollmentId) => {
	const allUsersFromSite = getAccounts().filter(f => f.siteGroupId === MOCK_SITE_GROUP_ID && (f.roleId === ROLES.Participant || f.roleId === ROLES.DemoParticipant));

	let courseTrainingEnrollment = {};
	for (let user of allUsersFromSite) {
		const userEnrollment = user.enrollments.find(f => f.courseId === courseId && f.enrollmentId === enrollmentId );
		if (!userEnrollment)
			continue;

		courseTrainingEnrollment = {
			enrollmentId: userEnrollment.enrollmentId,
			userId: userEnrollment.userId,
			firstName: user.firstName,
			lastName: user.lastName,
			dateCreated: userEnrollment.dateCreated
		};
	}

	return courseTrainingEnrollment;
};

export const addCourseEventEnrollment = (courseId, userId) => {
	const defaultParticipantCompletionDate = courses.find(c => c.courseId === courseId).defaultParticipantCompletionDate;
	return addEnrollment(courseId, userId, defaultParticipantCompletionDate);
};

export const getProfessionalCommunityEnrollments = (courseId) => {
	const allUsersFromSite = getAccounts().filter(f => f.siteGroupId === MOCK_SITE_GROUP_ID && (f.roleId === ROLES.Participant || f.roleId === ROLES.DemoParticipant));

	let communityEnrollments = [];
	for (let user of allUsersFromSite) {
		const userEnrollment = user.enrollments.find(f => f.courseId === courseId);
		if(!userEnrollment)
			continue;

		let approvalDetails = {
			enrollmentId: userEnrollment.enrollmentId,
			userId: userEnrollment.userId,
			firstName: user.firstName,
			lastName: user.lastName,
			dateCreated: userEnrollment.dateCreated
		};
		communityEnrollments.push(approvalDetails);
	}

	return communityEnrollments;
};

export const addProfessionalCommunityEnrollment = (courseId, userId) => {
	return addEnrollment(courseId, userId);
};

export const updateEnrollment = (enrollmentDetailsModel) => {
	let account = getAccount(enrollmentDetailsModel.userId);

	let enrollmentToUpdate = account.enrollments.find(f => f.enrollmentId === enrollmentDetailsModel.enrollmentId);
	enrollmentToUpdate.followUpActivityFileId = enrollmentDetailsModel.followUpActivityFileId;
	enrollmentToUpdate.followUpStatus = enrollmentDetailsModel.followUpStatus;
	enrollmentToUpdate.followUpStatusIsRejected = enrollmentDetailsModel.followUpStatus === followUpStatus.Rejected;
	enrollmentToUpdate.followUpRejectedReason = enrollmentDetailsModel.followUpRejectedReason;
	enrollmentToUpdate.followUpStatusIsApproved = enrollmentDetailsModel.followUpStatus === followUpStatus.Approved;
	enrollmentToUpdate.followUpActivityFileCreationDate = convertToDateTimeString(enrollmentDetailsModel.followUpActivityFileCreationDate);
	enrollmentToUpdate.creditOptionSelected = enrollmentDetailsModel.creditOptionSelected;
	enrollmentToUpdate.creditEarned = '';
	enrollmentToUpdate.assessmentDate = convertToDateTimeString(enrollmentDetailsModel.assessmentDate);
	enrollmentToUpdate.surveyDate = convertToDateTimeString(enrollmentDetailsModel.surveyDate);
	let updatedProgress = enrollmentDetailsModel.progress.map((item) => {
		if (item.dateStarted === "" && item.dateFinished === "")
			return null;

		return {
			dateCreated: convertToDateTimeString(item.dateStarted),
			dateCompleted: convertToDateTimeString(item.dateFinished),
			moduleId: item.moduleId,
		};
	});

	enrollmentToUpdate.progress = updatedProgress.filter(f => !isNullOrUndefined(f));

	enrollmentToUpdate.allModulesComplete = enrollmentToUpdate.progress.filter(f => f.dateCompleted !== "").length === enrollmentDetailsModel.progress.length;
	enrollmentToUpdate.dateCompleted = (enrollmentToUpdate.allModulesComplete
		&& !isNullOrUndefined(enrollmentToUpdate.followUpActivityFileId)
		&& enrollmentToUpdate.followUpStatusIsApproved
		&& enrollmentToUpdate.assessmentDate !== ""
		&& enrollmentToUpdate.surveyDate !== "")
		? getTodayDate()
		: "";

	enrollmentToUpdate.taskStatuses = enrollmentToUpdate.dateCompleted !== ""
		? [
			{
				title: "View Certificate",
				nextTaskLocation: NextTaskLocation.Certificate,
				surveyType: null,
				isPrimary: true,
				isEnabled: true,
				isRequired: false,
				isCompleted: false,
			},
			{
				title: "Review Course",
				nextTaskLocation: NextTaskLocation.ReviewCourse,
				surveyType: null,
				isPrimary: false,
				isEnabled: true,
				isRequired: false,
				isCompleted: false,
			},
		]
		: [
			{
				title: "View Course",
				nextTaskLocation: "Course",
				surveyType: null,
				isPrimary: true,
				isEnabled: true,
				isRequired: false,
				isCompleted: false,
			},
		];

	return enrollmentToUpdate;
};

export const removeEnrollment = (userId, enrollmentId) => {
	let account = getAccount(userId);

	account.enrollments = account.enrollments.filter(f => f.enrollmentId !== enrollmentId);
};

export const startModule = (userId, enrollmentId, moduleId, pageId) => {
	if (!enrollmentId || !moduleId || !userId || userId < 1) return false;

	let user = getAccount(userId);

	if (!isValidUser(user)) return false;

	let enrollment = user.enrollments.find(e => e.enrollmentId === enrollmentId);
	let progress = enrollment.progress.find(p => p.moduleId === moduleId);

	enrollment.moduleLastAccessed = moduleLastAccessedModel({moduleId: moduleId});
	const pageProgress = {
		moduleContentId: pageId,
		dateStarted: date
	};

	if (!progress) {
		const newContentProgress = !pageId ? [] : pageProgress;
		enrollment.progress.push({
			moduleId: moduleId,
			dateCreated: date,
			contentProgress: newContentProgress
		});
	}
	else if(pageId) {
		let contentProgress = progress.contentProgress.find(f => f.moduleContentId === pageId);

		if(!contentProgress) {
			progress.contentProgress.push(pageProgress);
		}
	}

	return user;
};

export const completeModule = (userId, enrollmentId, moduleId, pageId) => {
	if (!startModule(userId, enrollmentId, moduleId)) return false;

	let user = getAccount(userId);
	let enrollment = user.enrollments.find(e => e.enrollmentId === enrollmentId);
	let progress = enrollment.progress.find(p => p.moduleId === moduleId);
	progress.dateCompleted = date;

	if(pageId) {
		let contentProgress = progress.contentProgress.find(f => f.moduleContentId === pageId);
		if(!contentProgress)
			progress.contentProgress.push({
				moduleContentId: pageId,
				dateStarted: date,
				dateFinished: date
			});
		else
			contentProgress.dateFinished = date;
	}
	enrollment.moduleLastAccessed = moduleLastAccessedModel({moduleId: moduleId});

	return user;
};

export const addFollowUpFile = (userId, enrollmentId, fileId, creditOptionSelected) => {
	let user = getAccount(userId);
	let enrollment = user.enrollments.find(e => e.enrollmentId === enrollmentId);
	enrollment.followUpActivityFileId = fileId;
	enrollment.followUpStatus = followUpStatus.Approved;
	enrollment.followUpStatusIsRejected = false;
	enrollment.followUpRejectedReason = "";
	enrollment.followUpStatusIsApproved = true;
	enrollment.followUpActivityFileCreationDate = date;
	enrollment.creditOptionSelected = creditOptionSelected;
	enrollment.creditEarned = "";
	return user;
};

export const addAccount = (incomingAccount) => {
	getAccounts();

	if (!incomingAccount.createDate)
		incomingAccount.createDate = date;
	if (!incomingAccount.userId)
		incomingAccount.userId = getNewUserId();
	incomingAccount.roleId = incomingAccount.roleId || ROLES.Participant;

	const rolesAdminCanManageList= getRoleList(currentSiteDetails.rolesThatAdminCanManage, currentSiteDetails.rolesUsedBySite);

	incomingAccount.userRole = rolesAdminCanManageList.find(f => f.value === incomingAccount.roleId).text;

	incomingAccount.siteAbbreviation = mockSiteDetails.find(f => f.siteGroupId === incomingAccount.siteGroupId.toString()).siteAbbreviation;

	const newAccount = {...incomingAccount, ...fakeToken()};
	accounts.push(newAccount);

	return newAccount;
};

export const updateAccount = (updatedAccount, userId) => {
	let user = getAccount(userId);

	user.email = updatedAccount.email;
	user.firstName = updatedAccount.firstName;
	user.lastName = updatedAccount.lastName;

	if (updatedAccount.updatePassword)
		user.password = updatedAccount.password;

	if (!isNullOrUndefined(updatedAccount.roleId)) {
		user.roleId = updatedAccount.roleId;
		user.userRole = getRoleList(currentSiteDetails.rolesThatAdminCanManage, currentSiteDetails.rolesUsedBySite).find(f => f.value.toString() === updatedAccount.roleId.toString()).text;
	}

	return user;
};

export const getAccount = (query) => {
	getAccounts();
	let account = accounts.find((account) => account.userId.toString() === query.toString());

	if (isValidUser(account)) return account;

	return accounts.find((account) => account.email === query);
};

export const accountExists = (email) => {
	return !!getAccount(email);
};

export const filterSearchResults = (searchCriteria) => {
	let allUsers = getAccounts();
	return searchMockData(allUsers, searchCriteria, userSearchResults);
};

const isLoading = false;

export const enrollProps = {
	activeCourses: courses,
	courseId,
	categoryId,
	handleApiCall,
	redirect,
	user,
	fetchUser,
	handleReturnRedirect,
	siteDetails
};
export const profileProps = {
	addSiteGroupId,
	handleApiCall,
	siteDetails,
	user,
	fetchUser,
	isLoading,
	setWarnings,
	redirect
};