import PropTypes from "prop-types";
import React from "react";
import {Switch, switchProps} from "./Switch";
import {FieldsetLayout} from "./FieldsetLayout";
import {GridCell, GridContainer} from "../Grid";

export const SwitchFieldset = ({
	                               activeLabel,
	                               value,
	                               children,
	                               inactiveLabel,
	                               name,
	                               onChange,
	                               label,
	                               legend,
                               }) => {

	return (
		<FieldsetLayout
			className="toggled__container"
			legend={legend}
			name={name}
		>
			<GridContainer hasMarginX>
				<GridCell>
					<Switch activeLabel={activeLabel} value={value} inactiveLabel={inactiveLabel}
					        name={name} onChange={onChange} label={label}/>
				</GridCell>
				{children}
			</GridContainer>
		</FieldsetLayout>
	);
};

SwitchFieldset.propTypes = {
	children: PropTypes.any,
	...switchProps,
};