export const sortElements = (id, title) => (
    {
        id,
        title
    }
);

export const orderResults = (result, listToSort) => (
    {
        id: result.draggableId,
        startIndex: result.source.index,
        endIndex: result.destination.index,
        listToSort
    }
);