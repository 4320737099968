import { Menu, MenuItem } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import ProfileButton from "./ProfileButton";
import { withStyles } from 'tss-react/mui';

const ProfileMenu = ({
                         menuItems,
                         profileLabel,
                     }) => {

    const id = `menu-profile`;
	const StyleMenuItem = withStyles(MenuItem, {root:{fontFamily: "inherit"}});
	const [anchorElement, setAnchorElement] = React.useState(null);
    const open = Boolean(anchorElement);

    const handleMenu = (event) => {
        setAnchorElement(event.currentTarget);
    };

    const handleMenuItemClick = (onClick) => {
        onClick();
        handleClose();
    };

    const handleClose = () => {
        setAnchorElement(null);
    };

    return (
        <div className={`clearfix`}>
            <ProfileButton
                aria_controls={id}
                label={profileLabel}
                name={`btnProfile`}
                onClick={handleMenu}
            />
            <br className={`clearfix`} />
            <Menu
                id={id}
                anchorEl={anchorElement}
                keepMounted
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                open={open}
                onClose={handleClose}
            >
                {
                    menuItems.map((item, key) => {
                        return (
                            <StyleMenuItem key={key} onClick={() => handleMenuItemClick(item.onClick)}>
                                {item.label}
                            </StyleMenuItem>
                        );
                    })
                }

            </Menu>
        </div>
    );
};

ProfileMenu.propTypes = {
    menuItems: PropTypes.array.isRequired,
    profileLabel: PropTypes.string.isRequired,
};

export default ProfileMenu;