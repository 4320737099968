import PropTypes from "prop-types";
import React from "react";

const GridTable = ({ className, children, id }) => {
    return (
        <div aria-live="polite" className="table-scroll" id={id}>
            <table className={className}>{children}</table>
        </div>
    );
};

GridTable.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
    className: PropTypes.string,
    id: PropTypes.string,
};

GridTable.defaultProps = {
    className: "",
    id: "",
};

export default GridTable;
