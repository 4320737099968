import {isNullOrUndefined} from "../../Utilities/commonUtilities";
import * as policyEvents from "./policyEventsConstants";
import {ROLES} from "./roleConstants";

export class DefaultPolicy {
    constructor(roleId, allowSiteAdminsForPolicyEvent = false) {
        this._policy = {};
        this._roleId = roleId;
        this._allowSiteAdminsForPolicyEvent = allowSiteAdminsForPolicyEvent;

        this.initializePolicy();
    }

    permit(policyEvent) {
        if (!policyEvent)
            return false;

        if (this._policy[policyEvent])
            return this._policy[policyEvent](this._roleId, this._allowSiteAdminsForPolicyEvent);

        return false;
    }

    initializePolicy() {
        this._policy[policyEvents.USERS_WITH_ENROLLMENTS] = function (roleId) {
            return roleId === ROLES.DemoParticipant || roleId === ROLES.Participant || roleId === ROLES.AllAccessParticipant
                || roleId === ROLES.TrackingStudent || roleId === ROLES.Trainer || roleId === ROLES.DistrictDataViewerAndTrainer || roleId === ROLES.DistrictDataViewer;
        };
        this._policy[policyEvents.PARTICIPANT] = function (roleId) {
            return roleId === ROLES.DemoParticipant || roleId === ROLES.Participant || roleId === ROLES.AllAccessParticipant
                || roleId === ROLES.TrackingStudent;
        };
        this._policy[policyEvents.CAN_ENROLL] = function (roleId) {
            return isNullOrUndefined(roleId) || roleId === ROLES.DemoParticipant || roleId === ROLES.Participant || roleId === ROLES.AllAccessParticipant || roleId === ROLES.TrackingStudent;
        };
        this._policy[policyEvents.VIEW] = function (roleId) {
            return roleId === ROLES.AllDataViewer || roleId === ROLES.SiteAdmin || roleId === ROLES.SystemAdmin || roleId === ROLES.TechSupport || roleId === ROLES.RegionalDataViewer || roleId === ROLES.DistrictDataViewer || roleId === ROLES.Trainer || roleId === ROLES.DistrictDataViewerAndTrainer;
        };
        this._policy[policyEvents.MANAGE] = function (roleId) {
            return roleId === ROLES.SiteAdmin || roleId === ROLES.SystemAdmin || roleId === ROLES.TechSupport;
        };
        this._policy[policyEvents.MANAGE_DELETED_USERS] = function (roleId, allowSiteAdminsForPolicyEvent) {
            return roleId === ROLES.SystemAdmin || roleId === ROLES.TechSupport || (allowSiteAdminsForPolicyEvent && roleId === ROLES.SiteAdmin);
        };
        this._policy[policyEvents.IMPORT_USERS] = function (roleId) {
            return roleId === ROLES.SystemAdmin || roleId === ROLES.TechSupport;
        };

        this._policy[policyEvents.MERGE_USERS] = function (roleId) {
            return roleId === ROLES.SystemAdmin || roleId === ROLES.TechSupport;
        };
        this._policy[policyEvents.IMPORT_TRACKING_STUDENTS] = function (roleId) {
            return roleId === ROLES.SiteAdmin || roleId === ROLES.SystemAdmin || roleId === ROLES.TechSupport;
        };
        this._policy[policyEvents.ADMIN_DASHBOARD] = function (roleId) {
            return this[policyEvents.MANAGE](roleId) || this[policyEvents.COURSE_MANAGE](roleId) || this[policyEvents.FOLLOW_UP_APPROVAL](roleId) || roleId === ROLES.Trainer || roleId === ROLES.DistrictDataViewerAndTrainer;
        };
        this._policy[policyEvents.COURSE_MANAGE] = function (roleId) {
            return roleId === ROLES.SystemAdmin;
        };
        this._policy[policyEvents.COURSE_EDIT] = function (roleId, allowSiteAdminsForPolicyEvent) {
            return (allowSiteAdminsForPolicyEvent && roleId === ROLES.SiteAdmin) || roleId === ROLES.SystemAdmin;
        };
        this._policy[policyEvents.CATEGORY_MANAGE] = function (roleId) {
            return roleId === ROLES.SystemAdmin;
        };
        this._policy[policyEvents.SURVEY_EDIT] = function (roleId, allowSiteAdminsForPolicyEvent) {
            return roleId === ROLES.SystemAdmin || roleId === ROLES.TechSupport || (allowSiteAdminsForPolicyEvent && roleId === ROLES.SiteAdmin);
        };
        this._policy[policyEvents.SURVEY_MANAGE] = function (roleId) {
            return roleId === ROLES.SystemAdmin || roleId === ROLES.TechSupport;
        };
        this._policy[policyEvents.COURSE_EVENT_MANAGE] = function (roleId) {
            return roleId === ROLES.SystemAdmin || roleId === ROLES.SiteAdmin;
        };
        this._policy[policyEvents.COURSE_TRAINING_MANAGE] = function (roleId) {
            return roleId === ROLES.SystemAdmin || roleId === ROLES.SiteAdmin || roleId === ROLES.Trainer || roleId === ROLES.DistrictDataViewerAndTrainer;
        };
        this._policy[policyEvents.COURSE_PROFESSIONAL_COMMUNITIES_MANAGE] = function (roleId) {
            return roleId === ROLES.SystemAdmin || roleId === ROLES.SiteAdmin;
        };
        this._policy[policyEvents.DELETE] = function (roleId) {
            return roleId === ROLES.SystemAdmin || roleId === ROLES.TechSupport || roleId === ROLES.SiteAdmin;
        };
        this._policy[policyEvents.IMPERSONATE] = function (roleId, allowSiteAdminsForPolicyEvent) {
            return roleId === ROLES.SystemAdmin || roleId === ROLES.TechSupport || (allowSiteAdminsForPolicyEvent && roleId === ROLES.SiteAdmin);
        };
        this._policy[policyEvents.ACCESS_ALL_SITE_GROUPS] = function (roleId) {
            return roleId === ROLES.SystemAdmin || roleId === ROLES.TechSupport;
        };
        this._policy[policyEvents.DELETE_FOLLOW_UP_FILES] = function (roleId) {
            return roleId === ROLES.SystemAdmin;
        };
        this._policy[policyEvents.ACCESS_ALL_TASKS] = function (roleId) {
            return roleId === ROLES.AllAccessParticipant;
        };
        this._policy[policyEvents.FOLLOW_UP_APPROVAL] = function (roleId) {
            return roleId === ROLES.SystemAdmin || roleId === ROLES.SiteAdmin;
        };
        this._policy[policyEvents.HAS_REGION] = function (roleId) {
            return roleId.toString() === ROLES.RegionalDataViewer.toString() || roleId.toString() === ROLES.DistrictDataViewer.toString() || roleId.toString()  === ROLES.Trainer.toString() || roleId.toString()  === ROLES.DistrictDataViewerAndTrainer.toString();
        };
        this._policy[policyEvents.VIEW_REGIONS] = function (roleId) {
            return roleId === ROLES.SystemAdmin || roleId === ROLES.SiteAdmin || roleId === ROLES.TechSupport || roleId === ROLES.AllDataViewer || roleId === ROLES.Trainer;
        };
    }

}