import React from 'react';
import PropTypes from 'prop-types';
import {HtmlDisplay} from "../../Display";

/* eslint-disable jsx-a11y/no-noninteractive-tabindex */

export const ButtonMock = ({
	                    name,
	                    label,
                        className,
                        hideLabel,
						disabled
                    }) => {
    const buttonClass = "button is-notClickable " + className + (disabled ? " is-disabled " : "");
    const labelClass = hideLabel ? " is-visuallyhidden " : "";

	return (
		<span className={buttonClass}
              tabIndex="0"
              id={name}>
			<HtmlDisplay html={label} className={labelClass} />
		</span>
	);
};

ButtonMock.propTypes = {
	name: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
    className: PropTypes.string,
    hideLabel: PropTypes.bool,
	disabled: PropTypes.bool,
};
