import PropTypes from 'prop-types';
import React from 'react';
import { HtmlDisplay } from "../Display";
import { HelpText, helpTextSuffix } from "./HelpText";

export const FileUploadButton = ({ disabled, helpText, isCollapsed, label, name, onChange }) => {

    return (
        <>
            <input type="file"
                   aria-describedby={`${name}${helpTextSuffix}`}
                   data-testid={name}
                   name={name}
                   id={name}
                   onChange={onChange}
            />
            {/* eslint-disable-next-line *//***********LINTER WRONG*************/}
            <label htmlFor={name} className={`button ${!isCollapsed ? "expanded" : ""} ${disabled ? " is-disabled" : ""}`}>
                <HtmlDisplay html={label} isInline />
            </label>
            <HelpText name={name} helpText={helpText} isCentered/>
        </>
    );
};

FileUploadButton.propTypes = {
    disabled: PropTypes.bool,
    helpText: PropTypes.string,
    isCollapsed: PropTypes.bool,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
};