import PropTypes from "prop-types";
import React, {useEffect} from "react";
import {isTrimmedStringEmpty} from "../../Utilities/commonUtilities";
import {Link} from "../Link";
import {refocusOnPageChange, useDocumentTitle} from "./landmarksUtility";
import {moduleIcon} from "../../Utilities/cssUtilities";

const CoursePageTitleAdapter = ({
                                    className,
                                    courseTitle,
                                    modulesListLinkTo,
                                    moduleTitle = "",
                                    pageLinkTo,
                                    pageTitle,
                                    usesBreadCrumbs = false
                                }) => {
    const useModuleTitle = usesBreadCrumbs && !isTrimmedStringEmpty(moduleTitle);
    const moduleDocumentTitle = useModuleTitle ? ` - ${moduleTitle}` : "";

    useDocumentTitle(pageTitle === courseTitle ? `${pageTitle}` : `${pageTitle}${moduleDocumentTitle} - ${courseTitle}`);

    useEffect(refocusOnPageChange, [pageTitle]);

    const moduleTitleBreadCrumbLink = isTrimmedStringEmpty(pageLinkTo)
        ? <>{moduleTitle}</>
        : <Link to={pageLinkTo} label={moduleTitle}/>

    const courseTitleBreadCrumbLink = isTrimmedStringEmpty(modulesListLinkTo)
        ? <>{courseTitle}</>
        : <Link to={modulesListLinkTo} label={courseTitle}/>;

    return (
        <header className={`pageTitle pageTitle--course ${className}`}>
            <br/>
            {
                courseTitle !== pageTitle &&
                <>
                    {
                        useModuleTitle &&
                        <div className={`pageTitle__courseTitle`}>
                            {courseTitleBreadCrumbLink}
                            &nbsp; &gt; &nbsp;
                            {moduleTitleBreadCrumbLink}
                        </div>
                    }
                    {
                        !useModuleTitle &&
                        <div className={`pageTitle__courseTitle`}>{courseTitle}</div>
                    }
                </>
            }
            <h1 tabIndex={"-1"} className={`${moduleIcon}`}>{pageTitle}<span id={"extraScreenReaderContent"} className={"show-for-sr"}/></h1>
        </header>
    );
};

export const CoursePageTitle = (props) => CoursePageTitleAdapter({...props});
export const ModulePageTitle = (props) => CoursePageTitleAdapter({
    className: props.className,
    courseTitle: props.moduleTitle,
    moduleTitle: props.modulePageTitle,
    pageLinkTo: props.pageLinkTo,
    pageTitle: props.pageTitle,
    usesBreadCrumbs: true
});

const sharedProps = {
    className: PropTypes.string,
    pageLinkTo: PropTypes.string,
    pageTitle: PropTypes.string.isRequired,
};

CoursePageTitleAdapter.propTypes = {
    ...sharedProps,
    courseTitle: PropTypes.string.isRequired,
    modulesListLinkTo: PropTypes.string,
    moduleTitle: PropTypes.string,
    usesBreadCrumbs: PropTypes.bool
};

CoursePageTitle.propTypes = {
    ...sharedProps,
    courseTitle: PropTypes.string.isRequired,
    modulesListLinkTo: PropTypes.string,
    moduleTitle: PropTypes.string,
    usesBreadCrumbs: PropTypes.bool
};
ModulePageTitle.propTypes = {
    ...sharedProps,
    modulePageTitle: PropTypes.string,
    moduleTitle: PropTypes.string.isRequired
};
