import PropTypes from "prop-types";
import React from "react";
import { convertToArray } from "../../Utilities/Types/arrayUtilities";
import { CheckBoxItem } from "./CheckboxField";
import { FieldsetLayout } from "./FieldsetLayout";
import { arrayDelimiter } from "./inputConstants";
import {
    appendedCheckboxOtherValue,
    createFakeEvent,
    generateOtherValue,
    isValueInArray,
    removeValueInArray,
    updateCheckboxOtherValue,
} from "./inputUtility";

const CheckBoxList = ({
                          disabled,
                          error,
                          responseFeedback,
                          legend,
                          listHasTextField = [],
                          name,
                          onChange,
                          options,
                          showLabel,
                          value,
                      }) => {
    let selectedList = convertToArray(value, arrayDelimiter);
    selectedList = [...selectedList].filter(a => a !== "");

    showLabel = options.length > 1 ? true : showLabel;

    const handleAdd = (newValue) => {
        selectedList.push(newValue);
        handleSetValue();
    };

    const handleRemove = (selectedValue) => {
        removeValueInArray(selectedList, selectedValue);
        handleSetValue();
    };

    const handleUpdate = (selectedValue, otherValue) => {
        updateCheckboxOtherValue(selectedList, selectedValue, otherValue);
        handleSetValue();
    };

    const handleSetValue = () => {
        onChange(createFakeEvent(selectedList.join(arrayDelimiter), `${name}`));
    };

    return (
        <FieldsetLayout
            disabled={disabled}
            error={error}
            responseFeedback={responseFeedback}
            legend={legend}
            name={name}
        >
            {
                options.map((option, index) => {
                    const { text, value } = option;
                    const optionName = options.length > 1 ? `${name}_${index}` : name;
                    const hasTextField = isValueInArray(listHasTextField, value);
                    const otherTextValue = hasTextField ? appendedCheckboxOtherValue(selectedList, value) : "";
                    const appendedValue = otherTextValue ? generateOtherValue(value, otherTextValue) : value;
                    const isChecked = isValueInArray(selectedList, appendedValue, !hasTextField);
                    const handleChange = () => isChecked ? handleRemove(appendedValue) : handleAdd(value);
                    return (<CheckBoxItem
                        key={optionName}
                        checked={isChecked}
                        hasTextField={hasTextField}
                        label={text}
                        name={optionName}
                        onChange={handleChange}
                        onChangeOther={handleUpdate}
                        showLabel={showLabel}
                        value={value}
                        otherTextValue={otherTextValue}
                    />);
                })
            }
        </FieldsetLayout>
    );
};

CheckBoxList.propTypes = {
    disabled: PropTypes.bool,
    error: PropTypes.string,
    responseFeedback: PropTypes.string,
    legend: PropTypes.string,
    listHasTextField: PropTypes.array,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    showLabel: PropTypes.bool,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
    ]).isRequired,
};

export { CheckBoxList };