import { createArrayFromObject } from "../../../../Utilities/Types/arrayUtilities";

export const buttonIconClassPrefix = "buttonIcon--";
export const buttonLabelClassPrefix = "buttonLabel--";
export const buttonIconType = {
    ADD: "add",
    ADD_OPTION: "addOption",
    CERTIFICATE: "certificate",
    COLLAPSE: "collapse",
    COLLAPSE_ALL: "collapseAll",
    COPY_COMPONENT: "copyComponent",
    CLEAR: "clear",
	CONTENT: "content",
	DELETE: "delete",
	DELETE_COMPONENT: "deleteComponent",
    DOWNLOAD_FOLLOWUP: "downloadFollowup",
    EDIT: "edit",
    DONE_EDITING: "doneEditing",
    EXIT: "exit",
    EXPAND: "expand",
    EXPAND_ALL: "expandAll",
    ENROLLMENTS: "enrollments",
    IMPERSONATE: "impersonate",
    MODULES: "modules",
    ORDER: "order",
    PAGES: "pages",
	PROFILE: "profile",
	REMOVE: "remove",
	SAVE: "save",
    SECTIONS: "sections",
    UPLOAD: "upload",
    VIEW: "view"
}

export const buttonIconTypeList = createArrayFromObject(buttonIconType);
