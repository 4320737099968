import {isNullOrUndefined} from "../../../Utilities/commonUtilities";
import {searchModel} from "../../../Utilities/Search";

export const categorySearchResults = (attributes) => ({...attributes});

export const categorySearchModel = (userId, siteGroupId, categoryInfo, isActive, parentsOnly = false) => {
    return Object.assign({}, searchModel(userId.toString(), "title", false, "title"), {
        categoryInfo: categoryInfo ? categoryInfo : "",
        isActive: !isNullOrUndefined(isActive) ? isActive : "Yes",
        siteGroupId,
        parentsOnly: parentsOnly,
        ClearCategorySearch: clearCategorySearch,
    });
};

function clearCategorySearch() {
    this.categoryInfo = "";
    this.isActive = "";
    this.ClearSearch(false);
}