import PropTypes from "prop-types";
import React from "react";
import {locations} from "../../Utilities/Location";
import {PageNavigation, PageTitle} from "../Landmarks";

const NotAuthorized = ({redirect}) => {

    const handleLogoutClick = () => {
        redirect(locations.SIGN_OUT.path);
    };

    return (
        <>
            <PageTitle h1={`Not Authorized`} hasBorder/>
            <PageNavigation handlePrimaryClick={handleLogoutClick} labelPrimary={"Logout"} />
        </>
    );
};

NotAuthorized.propTypes = {
    redirect: PropTypes.func.isRequired,
};


export default NotAuthorized;
