import PropTypes from "prop-types";
import React from "react";
import {useParams} from "react-router-dom";
import NestAdInfinitum from "../../Components/AdInfinitum/NestAdInfinitum";

export const ContextPropsProvider = ({
                                         children,
                                         providers,
                                     }) => {
    return (
        <NestAdInfinitum array={providers}>
            {children}
        </NestAdInfinitum>
    );
};

ContextPropsProvider.propTypes = {
    children: PropTypes.any,
    providers: PropTypes.array,
};

export const ContextPropsConsumer = ({
                                         children,
                                         contexts,
                                         props,
                                     }) => {
    const params = useParams();
    let contextProps = {};

    contexts.map(context => Object.assign(contextProps, context()));

    return (
        children.map((Component, key) => {
                if (!Component) return null;
                return <Component
                    key={key}
                    {...props}
                    {...contextProps}
                    {...params}
                />
            },
        )
    );
};

ContextPropsConsumer.propTypes = {
    children: PropTypes.array,
    contexts: PropTypes.array,
    props: PropTypes.object,
};