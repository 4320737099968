import {createYesNoObject} from "../../Components/Inputs/inputUtility";
import {isNullOrUndefined, isTrimmedStringEmpty} from "../../Utilities/commonUtilities";
import {dynamicSort} from "../../Utilities/Sort";
import {deepCopyArray} from "../../Utilities/Types/arrayUtilities";
import {elementTypes} from "../../Components/DynamicForm/dynamicFormConstants";
import {searchMockData} from "../../Utilities/Search";
import {
    emptyFunction,
    handleApiCall,
    redirect,
    setWarnings,
    siteDetailsForTesting as siteDetails
} from "../../Utilities/testingUtilities";
import {isObjectNullOrEmpty} from "../../Utilities/Types/objectUtilities";
import { contentType, pageType, moduleType, courseTypes, trainingStatus } from "./courseConstants";
import {
    APH_SITE_GROUP_ID,
    DOH_SITE_GROUP_ID,
    DOE_OSS_SITE_GROUP_ID,
    EARLY_ACCESS_SITE_GROUP_ID,
    FCRR_SITE_GROUP_ID,
    FGRBI_SITE_GROUP_ID,
    FICW_SITE_GROUP_ID,
    MAPR_SITE_GROUP_ID,
    MOCK_SITE_GROUP_ID,
    PDA_SITE_GROUP_ID,
} from "../../SiteConfig/siteConstants";
import {courseOptionsModel} from "./CourseDetails/courseDetailsFactory";
import {
    courseProfessionalCommunitySearchResults
} from "./CourseProfessionalCommunitySearch/courseProfessionalCommunitySearchFactory";
import {courseSearchResults} from "./CourseSearch/courseSearchFactory";
import {courseEventOptionsModel} from "./CourseEventDetails/courseEventDetailsFactory";
import {courseEventSearchResults} from "./CourseEventSearch/courseEventSearchFactory";
import {getModuleContentChildren, isModulePage} from "./courseUtilities";

let allCourses = null;
const yesNoObject = createYesNoObject(false);

const courseData = [
    {
        courseId: 1,
        siteGroupId: APH_SITE_GROUP_ID,
        courseTitle: `Assessment Training 101`,
        courseDescription: `<p>This is a description for the foundations course</p>`,
        bgImageExtension: '',
        contact: null,
        externalDirectoryRoute: null,
        isActive: true,
        isValid: true,
        violations: [],
        categoryIds: [1],
        credits: `<p><b>ACVREP credit hours:</b> 1</p>`,
        creditOptions: [`<b>ACVREP credit hours:</b> 1`],
        followUpActivityFormFileId: 1,
        isRequiredToCompleteModulesInOrder: true,
        modules: [
            {
                moduleId: 1,
                moduleNumber: 1,
                title: "Module 1",
                moduleType: moduleType.courseContent,
                allPageTypes: "",
                isRequired: true,
                modulePages: [
                    {
                        moduleContentId: 100,
                        pageId: null,
                        type: null,
                        description: "Content",
                        displayOrder: 1,
                        moduleSections: [
                            {
                                description: "Intro",
                                displayOrder: 1,
                                moduleContentId: 1,
                                pageId: 100,
                                type: contentType.html,
                                value: "<p>This is Module 1 content.</p>",
                                surveyId: null,
                            },
                            {
                                description: "Blah video",
                                displayOrder: 2,
                                moduleContentId: 2,
                                pageId: 100,
                                type: contentType.vimeo,
                                value: "296461572",
                                surveyId: null,
                            },

                        ],
                    },
                    {
                        moduleContentId: 101,
                        pageId: null,
                        type: pageType.cyk,
                        description: "CYK",
                        displayOrder: 2,
                        moduleSections: [
                            {
                                description: "CYK",
                                displayOrder: 3,
                                moduleContentId: 3,
                                pageId: 101,
                                type: pageType.cyk,
                                surveyId: 1,
                                survey: {
                                    title: "CheckYourKnowledge",
                                    surveyId: 1,
                                    surveyEnrollmentId: 0,
                                    canEditResponses: true,
                                    progress: "Not Started",
                                    passingScore: null,
                                    isCykWithoutPassingScore: true,
                                    score: null,
                                    isPreAssessment: false,
                                    missedQuestions: [],
                                    surveyDetails: [
                                        {
                                            response: "",
                                            componentId: 1,
                                            labelText: "",
                                            subtext: "",
                                            elementType: elementTypes.TITLE,
                                            isRequired: false,
                                            parentId: null,
                                            correctAnswerValue: null,
                                            surveyDetails: [
                                                {
                                                    response: "",
                                                    componentId: 5,
                                                    labelText: "",
                                                    subtext: "",
                                                    elementType: elementTypes.TITLE,
                                                    isRequired: false,
                                                    parentId: 1,
                                                    correctAnswerValue: null,
                                                    surveyDetails: [
                                                        {
                                                            response: "",
                                                            componentId: 10,
                                                            labelText: "When should you give information to a runner who is deaf and blind?",
                                                            subtext: "",
                                                            elementType: elementTypes.RADIO,
                                                            isRequired: true,
                                                            parentId: 5,
                                                            correctAnswerValue: "before the runner heads out to the course",
                                                            surveyDetails: [],
                                                            answerChoices: [
                                                                {
                                                                    text: "during the race as obstacles because apparent",
                                                                    value: "during the race as obstacles because apparent",
                                                                    componentId: 11,
                                                                    parentId: 10
                                                                },
                                                                {
                                                                    text: "before the runner heads out to the course",
                                                                    value: "before the runner heads out to the course",
                                                                    componentId: 12,
                                                                    parentId: 10
                                                                },
                                                                {
                                                                    text: "after the path has been completed but before the race",
                                                                    value: "after the path has been completed but before the race",
                                                                    componentId: 13,
                                                                    parentId: 10
                                                                },
                                                                {
                                                                    text: "all of the above",
                                                                    value: "all of the above",
                                                                    componentId: 14,
                                                                    parentId: 10
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                },
                            },
                        ],
                    },
                ],
            },
            {
                moduleId: 2,
                moduleNumber: 2,
                title: "Module 2",
                moduleType: moduleType.courseContent,
                allPageTypes: "",
                isRequired: false,
                modulePages: [
                    {
                        moduleContentId: 400,
                        pageId: null,
                        type: null,
                        description: "Content",
                        displayOrder: 1,
                        moduleSections: [
                            {
                                description: "Intro",
                                displayOrder: 1,
                                moduleContentId: 4,
                                pageId: 400,
                                type: contentType.html,
                                value: "<p>This is Module 2 content.</p>",
                                surveyId: null,
                            },
                            {
                                description: "Blah video",
                                displayOrder: 2,
                                moduleContentId: 5,
                                pageId: 400,
                                type: contentType.vimeo,
                                value: "296461572",
                                surveyId: null,
                            },
                        ],
                    },
                ],
            },
        ],
        hidden: false,
        closingCode: '',
        defaultParticipantCompletionDate: '',
        openingCode: '',
        courseType: courseTypes.RegularCourse.id
    },
    {
        courseId: 2,
        siteGroupId: APH_SITE_GROUP_ID,
        courseTitle: `Assessment Training 102`,
        courseDescription: `<p>I lorem Ipsum text, I should of used it here to create a readable description.</p>`,
        bgImageExtension: '',
        contact: null,
        externalDirectoryRoute: null,
        isActive: true,
        isValid: true,
        violations: [],
        credits: `<p><b>ACVREP credit hours:</b> 1</p>`,
        creditOptions: [`<b>ACVREP credit hours:</b> 1`],
        categoryIds: [1],
        followUpActivityFormFileId: 1,
        isRequiredToCompleteModulesInOrder: true,
        modules: [
            {
                moduleId: 3,
                moduleNumber: 1,
                title: "Module 1",
                moduleType: moduleType.courseContent,
                allPageTypes: "",
                isRequired: true,
                modulePages: [
                    {
                        moduleContentId: 600,
                        pageId: null,
                        type: null,
                        description: "Content",
                        displayOrder: 1,
                        moduleSections: [
                            {
                                description: "Intro",
                                displayOrder: 1,
                                moduleContentId: 6,
                                pageId: 600,
                                type: contentType.html,
                                value: "<p>This is Module 1 content.</p>",
                                surveyId: null,
                            },
                            {
                                description: "Blah video",
                                displayOrder: 2,
                                moduleContentId: 7,
                                pageId: 600,
                                type: contentType.vimeo,
                                value: "296461572",
                                surveyId: null,
                            },
                        ],
                    },
                ],
            },
            {
                moduleId: 4,
                moduleNumber: 2,
                title: "Module 2",
                moduleType: moduleType.courseContent,
                allPageTypes: "",
                isRequired: true,
                modulePages: [
                    {
                        moduleContentId: 800,
                        pageId: null,
                        type: null,
                        description: "Content",
                        displayOrder: 1,
                        moduleSections: [
                            {
                                description: "Intro",
                                displayOrder: 1,
                                moduleContentId: 8,
                                pageId: 800,
                                type: contentType.html,
                                value: "<p>This is Module 2 content.</p>",
                                surveyId: null,
                            },
                            {
                                description: "Blah video",
                                displayOrder: 2,
                                moduleContentId: 9,
                                pageId: 800,
                                type: contentType.vimeo,
                                value: "296461572",
                                surveyId: null,
                            },
                        ],
                    },
                ],
            },
        ],
        hidden: false,
        closingCode: '',
        defaultParticipantCompletionDate: '',
        openingCode: '',
        courseType: courseTypes.RegularCourse.id
    },
    {
        courseId: 3,
        siteGroupId: APH_SITE_GROUP_ID,
        courseTitle: `Early Childhood Training 101`,
        courseDescription: `<p>Sed vitae molestie nisl. Suspendisse tristique tellus vel justo consectetur scelerisque. Nam eu maximus mi. Sed mollis erat sed pellentesque posuere. Quisque vel dui gravida, mattis nulla scelerisque, varius ligula. Nullam porttitor turpis at mauris fermentum, a semper quam lobortis.</p>`,
        bgImageExtension: '',
        contact: null,
        externalDirectoryRoute: null,
        isActive: true,
        isValid: true,
        violations: [],
        credits: `<p><b>ACVREP credit hours:</b> 1</p>`,
        creditOptions: [`<b>ACVREP credit hours:</b> 1`],
        categoryIds: [3],
        followUpActivityFormFileId: 1,
        isRequiredToCompleteModulesInOrder: true,
        modules: [
            {
                moduleId: 5,
                moduleNumber: 1,
                title: "Module 1",
                moduleType: moduleType.courseContent,
                allPageTypes: "",
                isRequired: true,
                modulePages: [
                    {
                        moduleContentId: 1000,
                        pageId: null,
                        type: null,
                        description: "Content",
                        displayOrder: 1,
                        moduleSections: [
                            {
                                description: "Intro",
                                displayOrder: 1,
                                pageId: 1000,
                                moduleContentId: 10,
                                type: contentType.html,
                                value: "<p>This is Module 1 content.</p>",
                                surveyId: null,
                            },
                            {
                                description: "Blah video",
                                displayOrder: 2,
                                pageId: 1000,
                                moduleContentId: 11,
                                type: contentType.vimeo,
                                value: "296461572",
                                surveyId: null,
                            },
                        ],
                    },
                ],
            },
            {
                moduleId: 6,
                moduleNumber: 2,
                title: "Module 2",
                moduleType: moduleType.courseContent,
                allPageTypes: "",
                isRequired: true,
                modulePages: [
                    {
                        moduleContentId: 1200,
                        pageId: null,
                        type: null,
                        description: "Content",
                        displayOrder: 1,
                        moduleSections: [
                            {
                                description: "Intro",
                                displayOrder: 1,
                                moduleContentId: 12,
                                pageId: 1200,
                                type: contentType.html,
                                value: "This is Module 2 content.</p>",
                                surveyId: null,
                            },
                            {
                                description: "Blah video",
                                displayOrder: 2,
                                moduleContentId: 13,
                                pageId: 1200,
                                type: contentType.vimeo,
                                value: "296461572",
                                surveyId: null,
                            },
                        ],
                    },
                ],
            },
        ],
        hidden: false,
        closingCode: '',
        defaultParticipantCompletionDate: '',
        openingCode: '',
        courseType: courseTypes.RegularCourse.id
    },
    {
        courseId: 4,
        siteGroupId: APH_SITE_GROUP_ID,
        courseTitle: `Early Childhood Training 102`,
        courseDescription: `<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi pharetra velit nisi, nec pharetra lacus tempus vitae. Vestibulum at gravida ipsum.</p>`,
        bgImageExtension: '',
        contact: null,
        externalDirectoryRoute: null,
        isActive: true,
        isValid: true,
        violations: ["Missing modules"],
        categoryIds: [2],
        followUpActivityFormFileId: 1,
        isRequiredToCompleteModulesInOrder: true,
        modules: [
            {
                moduleId: 41,
                moduleNumber: 41,
                title: "Module 41",
                moduleType: moduleType.courseContent,
                allPageTypes: "",
                isRequired: true,
                modulePages: [
                    {
                        moduleContentId: 4100,
                        pageId: null,
                        type: null,
                        description: "Content",
                        displayOrder: 1,
                        moduleSections: [
                            {
                                description: "Intro",
                                displayOrder: 1,
                                moduleContentId: 41,
                                pageId: 4100,
                                type: contentType.html,
                                value: "<p>This is Module 1 content.</p>",
                                surveyId: null,
                            },
                            {
                                description: "Blah video",
                                displayOrder: 2,
                                moduleContentId: 42,
                                pageId: 4100,
                                type: contentType.vimeo,
                                value: "296461572",
                                surveyId: null,
                            },
                        ],
                    },
                    {
                        moduleContentId: 4300,
                        pageId: null,
                        type: pageType.cyk,
                        description: "CYK",
                        displayOrder: 1,
                        moduleSections: [
                            {
                                description: "CYK",
                                displayOrder: 3,
                                moduleContentId: 43,
                                pageId: 4300,
                                type: pageType.cyk,
                                surveyId: 41,
                                survey: {
                                    title: "CheckYourKnowledge",
                                    surveyId: 41,
                                    surveyEnrollmentId: 0,
                                    canEditResponses: true,
                                    progress: "Not Started",
                                    passingScore: null,
                                    isCykWithoutPassingScore: true,
                                    score: null,
                                    isPreAssessment: false,
                                    missedQuestions: [],
                                    surveyDetails: [
                                        {
                                            response: "",
                                            componentId: 41,
                                            labelText: "",
                                            subtext: "",
                                            elementType: elementTypes.TITLE,
                                            isRequired: false,
                                            parentId: null,
                                            correctAnswerValue: null,
                                            surveyDetails: [
                                                {
                                                    response: "",
                                                    componentId: 45,
                                                    labelText: "",
                                                    subtext: "",
                                                    elementType: elementTypes.TITLE,
                                                    isRequired: false,
                                                    parentId: 1,
                                                    correctAnswerValue: null,
                                                    surveyDetails: [
                                                        {
                                                            response: "",
                                                            componentId: 10,
                                                            labelText: "When should you give information to a runner who is deaf and blind?",
                                                            subtext: "",
                                                            elementType: elementTypes.RADIO,
                                                            isRequired: true,
                                                            parentId: 5,
                                                            correctAnswerValue: "before the runner heads out to the course",
                                                            surveyDetails: [],
                                                            answerChoices: [
                                                                {
                                                                    text: "during the race as obstacles because apparent",
                                                                    value: "during the race as obstacles because apparent",
                                                                    componentId: 11,
                                                                    parentId: 10
                                                                },
                                                                {
                                                                    text: "before the runner heads out to the course",
                                                                    value: "before the runner heads out to the course",
                                                                    componentId: 12,
                                                                    parentId: 10
                                                                },
                                                                {
                                                                    text: "after the path has been completed but before the race",
                                                                    value: "after the path has been completed but before the race",
                                                                    componentId: 13,
                                                                    parentId: 10
                                                                },
                                                                {
                                                                    text: "all of the above",
                                                                    value: "all of the above",
                                                                    componentId: 14,
                                                                    parentId: 10
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                },
                            },
                        ],
                    },
                ],
            },
            {
                moduleId: 2,
                moduleNumber: 2,
                title: "Module 2",
                moduleType: moduleType.courseContent,
                allPageTypes: "",
                isRequired: true,
                modulePages: [
                    {
                        moduleContentId: 400,
                        pageId: null,
                        type: null,
                        description: "Content",
                        displayOrder: 1,
                        moduleSections: [
                            {
                                description: "Intro",
                                displayOrder: 1,
                                moduleContentId: 4,
                                pageId: 400,
                                type: contentType.html,
                                value: "<p>This is Module 2 content.</p>",
                                surveyId: null,
                            },
                            {
                                description: "Blah video",
                                displayOrder: 2,
                                moduleContentId: 5,
                                pageId: 400,
                                type: contentType.vimeo,
                                value: "296461572",
                                surveyId: null,
                            },
                        ],
                    },
                ],
            },
        ],
        hidden: false,
        closingCode: '',
        defaultParticipantCompletionDate: '',
        openingCode: '',
        courseType: courseTypes.RegularCourse.id
    },
    {
        courseId: 5,
        siteGroupId: APH_SITE_GROUP_ID,
        courseTitle: `English Language Arts Training`,
        courseDescription: `<p>Mauris porta urna sit amet libero dignissim hendrerit. Cras a magna id lectus pharetra sollicitudin nec non erat. Morbi dolor nunc, tincidunt id interdum eu, molestie sagittis dui. Vivamus eu lorem pellentesque, pharetra arcu nec, convallis dolor.</p>`,
        bgImageExtension: '',
        contact: null,
        externalDirectoryRoute: "someExternalRoute",
        previewDirectoryRoute: "somePreviewRoute",
        hasPreview: true,
        isActive: true,
        isValid: false,
        violations: ["Missing modules"],
        categoryIds: [3, 5],
        followUpActivityFormFileId: 1,
        isRequiredToCompleteModulesInOrder: true,
        modules: [{
            moduleId: 22,
            moduleNumber: 1,
            title: "Module 1",
            moduleType: moduleType.courseContent,
            allPageTypes: "",
            isRequired: true,
            modulePages: [
                {
                    moduleContentId: 401,
                    pageId: null,
                    type: null,
                    description: "Content",
                    displayOrder: 1,
                    moduleSections: [],
                },
            ]
        }],
        hidden: false,
        closingCode: '',
        defaultParticipantCompletionDate: '',
        openingCode: '',
        courseType: courseTypes.RegularCourse.id
    },
    {
        courseId: 6,
        siteGroupId: APH_SITE_GROUP_ID,
        courseTitle: `Science and Health Training`,
        courseDescription: `<p>Fusce efficitur, ipsum pellentesque viverra lacinia, magna turpis volutpat sem, vel tincidunt neque libero efficitur metus. Proin consequat interdum volutpat. Sed ac metus euismod erat aliquet accumsan. Aliquam est tortor, congue et ultricies eu, pulvinar quis turpis.</p>`,
        bgImageExtension: '',
        contact: null,
        externalDirectoryRoute: null,
        isActive: true,
        isValid: false,
        violations: ["Missing modules"],
        categoryIds: [3, 6],
        followUpActivityFormFileId: 1,
        isRequiredToCompleteModulesInOrder: true,
        modules: [],
        hidden: false,
        closingCode: '',
        defaultParticipantCompletionDate: '',
        openingCode: '',
        courseType: courseTypes.RegularCourse.id
    },
    {
        courseId: 7,
        siteGroupId: APH_SITE_GROUP_ID,
        courseTitle: `Social Studies Training`,
        courseDescription: `<p>Mauris venenatis, elit vitae convallis mollis, augue nunc imperdiet sapien, a fringilla mauris ligula nec purus. In a urna mollis, vestibulum augue ut, varius lacus. Sed dictum lacinia tortor nec luctus. Cras eu consectetur dolor. Nulla facilisi.</p>`,
        bgImageExtension: '',
        contact: null,
        externalDirectoryRoute: null,
        isActive: true,
        isValid: false,
        violations: ["Missing modules"],
        categoryIds: [3, 7],
        followUpActivityFormFileId: 1,
        isRequiredToCompleteModulesInOrder: true,
        modules: [],
        hidden: false,
        closingCode: '',
        defaultParticipantCompletionDate: '',
        openingCode: '',
        courseType: courseTypes.RegularCourse.id
    },
    {
        courseId: 8,
        siteGroupId: APH_SITE_GROUP_ID,
        courseTitle: `Mathematics Training`,
        courseDescription: `<p>Cras id tempus risus, quis aliquam lacus. Sed at semper purus. Duis tristique ac urna a facilisis. Maecenas at sem lorem. Fusce consectetur bibendum mauris, quis rhoncus sem mollis eget. Ut ornare purus in augue pellentesque, nec sollicitudin ex semper. Quisque lacus leo, tristique vel mattis vel, venenatis sed orci.</p>`,
        bgImageExtension: '',
        contact: null,
        externalDirectoryRoute: null,
        isActive: true,
        isValid: false,
        violations: ["Missing modules"],
        categoryIds: [3, 8],
        followUpActivityFormFileId: 1,
        isRequiredToCompleteModulesInOrder: true,
        modules: [],
        hidden: false,
        closingCode: '',
        defaultParticipantCompletionDate: '',
        openingCode: '',
        courseType: courseTypes.RegularCourse.id
    },
    {
        courseId: 9,
        siteGroupId: APH_SITE_GROUP_ID,
        courseTitle: `Career and Technical Education Training`,
        courseDescription: `<p>Mauris pretium metus semper libero pharetra, et auctor ante tristique. In lorem diam, interdum eget ligula vitae, feugiat interdum sem. Vivamus felis dolor, dictum a aliquet in, porta ut ligula. Quisque venenatis nec quam ut gravida. Nunc nec faucibus mi. Morbi pretium ac ante tincidunt scelerisque.</p>`,
        bgImageExtension: '',
        contact: null,
        externalDirectoryRoute: null,
        isActive: true,
        isValid: false,
        violations: ["Missing modules"],
        categoryIds: [3, 9],
        followUpActivityFormFileId: 1,
        isRequiredToCompleteModulesInOrder: true,
        modules: [],
        hidden: false,
        closingCode: '',
        defaultParticipantCompletionDate: '',
        openingCode: '',
        courseType: courseTypes.RegularCourse.id
    },
    {
        courseId: 10,
        siteGroupId: APH_SITE_GROUP_ID,
        courseTitle: `Physical Education Training`,
        courseDescription: `<p>Morbi cursus augue in lectus maximus scelerisque. Nunc in imperdiet risus. Nunc consectetur urna luctus consequat laoreet. Integer egestas dolor dolor, malesuada lobortis arcu laoreet et. Donec euismod felis ut odio finibus malesuada. Sed aliquet tincidunt lectus ut consequat. Proin finibus nulla purus. Aliquam et ligula mauris. Donec porta, ipsum at consequat sodales, ligula erat convallis dui, at mollis erat diam eget dolor.</p>`,
        bgImageExtension: '',
        contact: null,
        externalDirectoryRoute: null,
        isActive: true,
        isValid: false,
        violations: ["Missing modules"],
        categoryIds: [3, 10],
        followUpActivityFormFileId: 1,
        isRequiredToCompleteModulesInOrder: true,
        modules: [],
        hidden: false,
        closingCode: '',
        defaultParticipantCompletionDate: '',
        openingCode: '',
        courseType: courseTypes.RegularCourse.id
    },
    {
        courseId: 11,
        siteGroupId: APH_SITE_GROUP_ID,
        courseTitle: `Fine Arts Training`,
        courseDescription: `<p>Nulla pharetra tristique sem, nec pellentesque nibh. Etiam justo odio, bibendum eget urna id, tempor maximus enim. In facilisis ultrices erat commodo ullamcorper. Maecenas posuere, metus non tincidunt dignissim, mauris quam scelerisque ipsum, id lacinia lacus dolor ut risus. Nullam sit amet placerat sapien.</p>`,
        bgImageExtension: '',
        contact: null,
        externalDirectoryRoute: null,
        isActive: true,
        isValid: false,
        violations: ["Missing modules"],
        categoryIds: [3, 11],
        followUpActivityFormFileId: 1,
        isRequiredToCompleteModulesInOrder: true,
        modules: [],
        hidden: false,
        closingCode: '',
        defaultParticipantCompletionDate: '',
        openingCode: '',
        courseType: courseTypes.RegularCourse.id
    },
    {
        courseId: 12,
        siteGroupId: APH_SITE_GROUP_ID,
        courseTitle: `Compensatory Skills Training`,
        courseDescription: `<p>nteger consequat ipsum suscipit orci feugiat, at gravida tortor finibus. Donec imperdiet, lorem a porta dictum, lorem neque malesuada lorem, nec egestas nibh massa et risus. Vestibulum arcu erat, convallis ornare urna fermentum, pretium malesuada nunc.</p>`,
        bgImageExtension: '',
        contact: null,
        externalDirectoryRoute: null,
        isActive: true,
        isValid: false,
        violations: ["Missing modules"],
        categoryIds: [4, 12],
        followUpActivityFormFileId: 1,
        isRequiredToCompleteModulesInOrder: true,
        modules: [],
        hidden: false,
        closingCode: '',
        defaultParticipantCompletionDate: '',
        openingCode: '',
        courseType: courseTypes.RegularCourse.id
    },
    {
        courseId: 13,
        siteGroupId: APH_SITE_GROUP_ID,
        courseTitle: `Orientation and Mobility Training`,
        courseDescription: `<p>Donec viverra diam quis ligula vulputate, et egestas ante placerat. Quisque interdum, nisl et dictum suscipit, erat sem blandit augue, at imperdiet augue ex a ligula.</p>`,
        bgImageExtension: '',
        contact: null,
        externalDirectoryRoute: null,
        isActive: true,
        isValid: false,
        violations: ["Missing modules"],
        categoryIds: [4, 13],
        followUpActivityFormFileId: 1,
        isRequiredToCompleteModulesInOrder: true,
        modules: [],
        hidden: false,
        closingCode: '',
        defaultParticipantCompletionDate: '',
        openingCode: '',
        courseType: courseTypes.RegularCourse.id
    },
    {
        courseId: 14,
        siteGroupId: APH_SITE_GROUP_ID,
        courseTitle: `Social Interaction Skills Training`,
        courseDescription: `<p>Donec viverra diam quis ligula vulputate, et egestas ante placerat. Quisque interdum, nisl et dictum suscipit, erat sem blandit augue, at imperdiet augue ex a ligula.</p>`,
        bgImageExtension: '',
        contact: null,
        externalDirectoryRoute: null,
        isActive: true,
        isValid: false,
        violations: ["Missing modules"],
        categoryIds: [4, 14],
        followUpActivityFormFileId: 1,
        isRequiredToCompleteModulesInOrder: true,
        modules: [],
        hidden: false,
        closingCode: '',
        defaultParticipantCompletionDate: '',
        openingCode: '',
        courseType: courseTypes.RegularCourse.id
    },
    {
        courseId: 15,
        siteGroupId: APH_SITE_GROUP_ID,
        courseTitle: `Independent Living Skills Training`,
        courseDescription: `<p>Donec viverra diam quis ligula vulputate, et egestas ante placerat. Quisque interdum, nisl et dictum suscipit, erat sem blandit augue, at imperdiet augue ex a ligula.</p>`,
        bgImageExtension: '',
        contact: null,
        externalDirectoryRoute: null,
        isActive: true,
        isValid: false,
        violations: ["Missing modules"],
        categoryIds: [4, 15],
        followUpActivityFormFileId: 1,
        isRequiredToCompleteModulesInOrder: true,
        modules: [],
        hidden: false,
        closingCode: '',
        defaultParticipantCompletionDate: '',
        openingCode: '',
        courseType: courseTypes.RegularCourse.id
    },
    {
        courseId: 16,
        siteGroupId: APH_SITE_GROUP_ID,
        courseTitle: `Recreation and Leisure Skills Training`,
        courseDescription: `<p>Donec viverra diam quis ligula vulputate, et egestas ante placerat. Quisque interdum, nisl et dictum suscipit, erat sem blandit augue, at imperdiet augue ex a ligula.</p>`,
        bgImageExtension: '',
        contact: null,
        externalDirectoryRoute: null,
        isActive: true,
        isValid: false,
        violations: ["Missing modules"],
        categoryIds: [4, 16],
        followUpActivityFormFileId: 1,
        isRequiredToCompleteModulesInOrder: true,
        modules: [],
        hidden: false,
        closingCode: '',
        defaultParticipantCompletionDate: '',
        openingCode: '',
        courseType: courseTypes.RegularCourse.id
    },
    {
        courseId: 17,
        siteGroupId: APH_SITE_GROUP_ID,
        courseTitle: `Career Education and Transition Training`,
        courseDescription: `<p>Donec viverra diam quis ligula vulputate, et egestas ante placerat. Quisque interdum, nisl et dictum suscipit, erat sem blandit augue, at imperdiet augue ex a ligula.</p>`,
        bgImageExtension: '',
        contact: null,
        externalDirectoryRoute: null,
        isActive: true,
        isValid: false,
        violations: ["Missing modules"],
        categoryIds: [4, 17],
        followUpActivityFormFileId: 1,
        isRequiredToCompleteModulesInOrder: true,
        modules: [],
        hidden: false,
        closingCode: '',
        defaultParticipantCompletionDate: '',
        openingCode: '',
        courseType: courseTypes.RegularCourse.id
    },
    {
        courseId: 18,
        siteGroupId: APH_SITE_GROUP_ID,
        courseTitle: `Use of Access Technology Training`,
        courseDescription: `<p>Donec viverra diam quis ligula vulputate, et egestas ante placerat. Quisque interdum, nisl et dictum suscipit, erat sem blandit augue, at imperdiet augue ex a ligula.</p>`,
        bgImageExtension: '',
        contact: null,
        externalDirectoryRoute: null,
        isActive: true,
        isValid: false,
        violations: ["Missing modules"],
        categoryIds: [4, 18],
        followUpActivityFormFileId: 1,
        isRequiredToCompleteModulesInOrder: true,
        modules: [],
        hidden: false,
        closingCode: '',
        defaultParticipantCompletionDate: '',
        openingCode: '',
        courseType: courseTypes.RegularCourse.id
    },
    {
        courseId: 19,
        siteGroupId: APH_SITE_GROUP_ID,
        courseTitle: `Sensory Efficiency Skills Training`,
        courseDescription: `<p>Donec viverra diam quis ligula vulputate, et egestas ante placerat. Quisque interdum, nisl et dictum suscipit, erat sem blandit augue, at imperdiet augue ex a ligula.</p>`,
        bgImageExtension: '',
        contact: null,
        externalDirectoryRoute: null,
        isActive: false,
        isValid: false,
        violations: ["Missing modules"],
        categoryIds: [4, 19],
        followUpActivityFormFileId: 1,
        isRequiredToCompleteModulesInOrder: true,
        modules: [],
        hidden: false,
        closingCode: '',
        defaultParticipantCompletionDate: '',
        openingCode: '',
        courseType: courseTypes.RegularCourse.id
    },
    {
        courseId: 20,
        siteGroupId: APH_SITE_GROUP_ID,
        courseTitle: `Self-Determination Training`,
        courseDescription: `<p>Donec viverra diam quis ligula vulputate, et egestas ante placerat. Quisque interdum, nisl et dictum suscipit, erat sem blandit augue, at imperdiet augue ex a ligula.</p>`,
        bgImageExtension: '',
        contact: null,
        externalDirectoryRoute: "someRoute",
        isActive: true,
        isValid: false,
        violations: ["Missing modules"],
        categoryIds: [4, 20],
        followUpActivityFormFileId: 1,
        isRequiredToCompleteModulesInOrder: true,
        modules: [],
        hidden: false,
        closingCode: '',
        defaultParticipantCompletionDate: '',
        openingCode: '',
        courseType: courseTypes.RegularCourse.id
    },
    {
        courseId: 21,
        siteGroupId: FGRBI_SITE_GROUP_ID,
        courseTitle: `Iowa Fun`,
        courseDescription: `<p>Sed vitae molestie nisl. Suspendisse tristique tellus vel justo consectetur scelerisque. Nam eu maximus mi. Sed mollis erat sed pellentesque posuere. Quisque vel dui gravida, mattis nulla scelerisque, varius ligula. Nullam porttitor turpis at mauris fermentum, a semper quam lobortis.</p>`,
        bgImageExtension: '',
        contact: null,
        externalDirectoryRoute: "someRoute",
        isActive: true,
        isValid: true,
        violations: [],
        credits: `<p><b>Credit hours:</b> 1</p>`,
        creditOptions: [`<b>ACVREP credit hours:</b> 1`],
        categoryIds: [1],
        followUpActivityFormFileId: 1,
        isRequiredToCompleteModulesInOrder: false,
        modules: [
            {
                moduleId: 7,
                moduleNumber: 1,
                title: "Module 1",
                moduleType: moduleType.courseContent,
                allPageTypes: "",
                isRequired: true,
                modulePages: [
                    {
                        moduleContentId: 1400,
                        pageId: null,
                        type: null,
                        description: "Content",
                        displayOrder: 1,
                        moduleSections: [
                            {
                                description: "Intro",
                                displayOrder: 1,
                                moduleContentId: 14,
                                pageId: 1400,
                                type: contentType.html,
                                value: "<p>This is Module 1 content.</p>",
                                surveyId: null,
                            },
                            {
                                description: "Blah video",
                                displayOrder: 2,
                                moduleContentId: 15,
                                pageId: 1400,
                                type: contentType.vimeo,
                                value: "296461572",
                                surveyId: null,
                            },
                        ],
                    },
                ],
            },
            {
                moduleId: 8,
                moduleNumber: 2,
                title: "Module 2",
                moduleType: moduleType.courseContent,
                allPageTypes: "",
                isRequired: true,
                modulePages: [
                    {
                        moduleContentId: 1600,
                        pageId: null,
                        type: null,
                        description: "Content",
                        displayOrder: 1,
                        moduleSections: [
                            {
                                description: "Intro",
                                displayOrder: 1,
                                moduleContentId: 16,
                                pageId: 1600,
                                type: contentType.html,
                                value: "This is Module 2 content.</p>",
                                surveyId: null,
                            },
                            {
                                description: "Blah video",
                                displayOrder: 2,
                                moduleContentId: 17,
                                pageId: 1600,
                                type: contentType.vimeo,
                                value: "296461572",
                                surveyId: null,
                            },
                        ],
                    },
                ],
            },
        ],
        hidden: false,
        closingCode: '',
        defaultParticipantCompletionDate: '',
        openingCode: '',
        courseType: courseTypes.RegularCourse.id
    },
    {
        courseId: 22,
        siteGroupId: PDA_SITE_GROUP_ID,
        courseTitle: `PDA Fun`,
        courseDescription: `<p>Sed vitae molestie nisl. Suspendisse tristique tellus vel justo consectetur scelerisque. Nam eu maximus mi. Sed mollis erat sed pellentesque posuere. Quisque vel dui gravida, mattis nulla scelerisque, varius ligula. Nullam porttitor turpis at mauris fermentum, a semper quam lobortis.</p>`,
        bgImageExtension: '',
        contact: null,
        externalDirectoryRoute: "someExternalRoute",
        previewDirectoryRoute: "somePreviewRoute",
        hasPreview: true,
        isActive: true,
        isValid: true,
        violations: [],
        credits: `<p><b>Credit hours:</b> 1</p>`,
        creditOptions: [`<b>ACVREP credit hours:</b> 1`],
        categoryIds: [1],
        followUpActivityFormFileId: 1,
        isRequiredToCompleteModulesInOrder: false,
        modules: [
            {
                moduleId: 7,
                moduleNumber: 1,
                title: "Module 1",
                moduleType: moduleType.courseContent,
                allPageTypes: "",
                isRequired: true,
                modulePages: [
                    {
                        moduleContentId: 1500,
                        pageId: null,
                        type: null,
                        description: "Content",
                        displayOrder: 1,
                        moduleSections: [
                            {
                                description: "Intro",
                                displayOrder: 1,
                                moduleContentId: 14,
                                pageId: 1500,
                                type: contentType.html,
                                value: "<p>This is Module 1 content.</p>",
                                surveyId: null,
                            },
                            {
                                description: "Blah video",
                                displayOrder: 2,
                                moduleContentId: 15,
                                pageId: 1500,
                                type: contentType.vimeo,
                                value: "296461572",
                                surveyId: null,
                            },
                        ],
                    },
                ],
            },
            {
                moduleId: 8,
                moduleNumber: 2,
                title: "Module 2",
                moduleType: moduleType.courseContent,
                allPageTypes: "",
                isRequired: true,
                modulePages: [
                    {
                        moduleContentId: 2000,
                        pageId: null,
                        type: null,
                        description: "Content",
                        displayOrder: 1,
                        moduleSections: [
                            {
                                description: "Intro",
                                displayOrder: 1,
                                moduleContentId: 16,
                                pageId: 2000,
                                type: contentType.html,
                                value: "This is Module 2 content.</p>",
                                surveyId: null,
                            },
                            {
                                description: "Blah video",
                                displayOrder: 2,
                                moduleContentId: 17,
                                pageId: 2000,
                                type: contentType.vimeo,
                                value: "296461572",
                                surveyId: null,
                            },
                        ],
                    },
                ],
            },
        ],
        hidden: false,
        closingCode: '',
        defaultParticipantCompletionDate: '',
        openingCode: '',
        courseType: courseTypes.RegularCourse.id
    },
    {
        courseId: 23,
        siteGroupId: EARLY_ACCESS_SITE_GROUP_ID,
        courseTitle: `Early Access Fun`,
        courseDescription: `<p>Sed vitae molestie nisl. Suspendisse tristique tellus vel justo consectetur scelerisque. Nam eu maximus mi. Sed mollis erat sed pellentesque posuere. Quisque vel dui gravida, mattis nulla scelerisque, varius ligula. Nullam porttitor turpis at mauris fermentum, a semper quam lobortis.</p>`,
        bgImageExtension: '',
        contact: null,
        externalDirectoryRoute: "someExternalRoute",
        previewDirectoryRoute: "somePreviewRoute",
        hasPreview: true,
        isActive: true,
        isValid: true,
        violations: [],
        credits: `<p><b>Credit hours:</b> 1</p>`,
        creditOptions: [`<b>ACVREP credit hours:</b> 1`],
        categoryIds: [1],
        followUpActivityFormFileId: 1,
        isRequiredToCompleteModulesInOrder: false,
        modules: [
            {
                moduleId: 7,
                moduleNumber: 1,
                title: "Module 1",
                moduleType: moduleType.courseContent,
                allPageTypes: "",
                isRequired: true,
                modulePages: [
                    {
                        moduleContentId: 1700,
                        pageId: null,
                        type: null,
                        description: "Content",
                        displayOrder: 1,
                        moduleSections: [
                            {
                                description: "Intro",
                                displayOrder: 1,
                                moduleContentId: 14,
                                pageId: 1700,
                                type: contentType.html,
                                value: "<p>This is Module 1 content.</p>",
                                surveyId: null,
                            },
                            {
                                description: "Blah video",
                                displayOrder: 2,
                                moduleContentId: 15,
                                pageId: 1700,
                                type: contentType.vimeo,
                                value: "296461572",
                                surveyId: null,
                            },
                        ],
                    },
                ],
            },
            {
                moduleId: 8,
                moduleNumber: 2,
                title: "Module 2",
                moduleType: moduleType.courseContent,
                allPageTypes: "",
                isRequired: true,
                modulePages: [
                    {
                        moduleContentId: 1800,
                        pageId: null,
                        type: null,
                        description: "Content",
                        displayOrder: 1,
                        moduleSections: [],
                    },
                ],
            },
        ],
        hidden: false,
        closingCode: '',
        defaultParticipantCompletionDate: '',
        openingCode: '',
        courseType: courseTypes.RegularCourse.id
    },
    {
        courseId: 24,
        siteGroupId: APH_SITE_GROUP_ID,
        courseTitle: `Event 1`,
        courseDescription: ``,
        bgImageExtension: '',
        contact: null,
        externalDirectoryRoute: "",
        previewDirectoryRoute: "",
        hasPreview: false,
        isActive: true,
        isValid: true,
        violations: [],
        credits: `<p><b>Credit hours:</b> 1</p>`,
        creditOptions: [`<b>ACVREP credit hours:</b> 1`],
        categoryIds: [],
        followUpActivityFormFileId: null,
        isRequiredToCompleteModulesInOrder: false,
        modules: [],
        hidden: true,
        closingCode: 'CC1',
        defaultParticipantCompletionDate: '11/1/2021',
        openingCode: 'OC1',
        courseType: courseTypes.EventCourse.id
    },
    {
        courseId: 25,
        siteGroupId: APH_SITE_GROUP_ID,
        courseTitle: `Event 2`,
        courseDescription: ``,
        bgImageExtension: '',
        contact: null,
        externalDirectoryRoute: "",
        previewDirectoryRoute: "",
        hasPreview: false,
        isActive: true,
        isValid: true,
        violations: [],
        credits: `<p><b>Credit hours:</b> 2</p>`,
        creditOptions: [`<b>ACVREP credit hours:</b> 2`],
        categoryIds: [],
        followUpActivityFormFileId: null,
        isRequiredToCompleteModulesInOrder: false,
        modules: [],
        hidden: true,
        closingCode: 'CC2',
        defaultParticipantCompletionDate: '12/2/2021',
        openingCode: 'OC2',
        courseType: courseTypes.EventCourse.id
    },
    {
        courseId: 25,
        siteGroupId: FCRR_SITE_GROUP_ID,
        courseTitle: `Tutoring`,
        courseDescription: ``,
        bgImageExtension: '',
        contact: null,
        externalDirectoryRoute: "",
        previewDirectoryRoute: "",
        hasPreview: false,
        isActive: true,
        isValid: true,
        violations: [],
        credits: `<p><b>Credit hours:</b> 2</p>`,
        creditOptions: [`<b>ACVREP credit hours:</b> 2`],
        categoryIds: [],
        followUpActivityFormFileId: null,
        isRequiredToCompleteModulesInOrder: false,
        modules: [],
        hidden: true,
        closingCode: '',
        defaultParticipantCompletionDate: '12/2/2021',
        openingCode: '',
        courseType: courseTypes.RegularCourse.id
    },
    {
        courseId: 25,
        siteGroupId: FICW_SITE_GROUP_ID,
        courseTitle: `STARS`,
        courseDescription: ``,
        bgImageExtension: '',
        contact: null,
        externalDirectoryRoute: "",
        previewDirectoryRoute: "",
        hasPreview: false,
        isActive: true,
        isValid: true,
        violations: [],
        credits: `<p><b>Credit hours:</b> 2</p>`,
        creditOptions: [`<b>ACVREP credit hours:</b> 2`],
        categoryIds: [],
        followUpActivityFormFileId: null,
        isRequiredToCompleteModulesInOrder: false,
        modules: [],
        hidden: true,
        closingCode: '',
        defaultParticipantCompletionDate: '12/2/2021',
        openingCode: '',
        courseType: courseTypes.RegularCourse.id
    },
    {
        courseId: 25,
        siteGroupId: DOH_SITE_GROUP_ID,
        courseTitle: `Children's Medical Services Online Training`,
        courseDescription: ``,
        bgImageExtension: '',
        contact: null,
        externalDirectoryRoute: "",
        previewDirectoryRoute: "",
        hasPreview: false,
        isActive: true,
        isValid: true,
        violations: [],
        credits: `<p><b>Credit hours:</b> 2</p>`,
        creditOptions: [`<b>credit hours:</b> 2`],
        categoryIds: [],
        followUpActivityFormFileId: null,
        isRequiredToCompleteModulesInOrder: false,
        modules: [],
        hidden: true,
        closingCode: '',
        defaultParticipantCompletionDate: '12/2/2021',
        openingCode: '',
        courseType: courseTypes.RegularCourse.id
    },
    {
        courseId: 26,
        siteGroupId: APH_SITE_GROUP_ID,
        externalDirectoryRoute: "",
        previewDirectoryRoute: "",
        hasPreview: false,
        isActive: true,
        isValid: true,
        violations: [],
        credits: ``,
        followUpActivityFormFileId: null,
        isRequiredToCompleteModulesInOrder: false,
        closingCode: '',
        defaultParticipantCompletionDate: '',
        openingCode: '',
        courseType: courseTypes.ProfessionalCommunityCourse.id,
        bgImageExtension: "",
        categoryIds: [],
        contact: null,
        creditOptions: {},
        courseTitle: "Assistive Technology Specialist",
        courseDescription: "Professional who evaluates a student’s strengths and needs, recommends devices and services, and provides training on how to utilize assistive technology solutions",
        hasPostAssessment: false,
        hidden: false,
        modules: [{
            allPageTypes: null,
            bgImageExtension: "",
            isRequired: false,
            moduleId: 509,
            moduleNumber: 1,
            modulePages: [{
                description: "here",
                displayOrder: 1,
                moduleContentId: 622,
                moduleSections: [{
                    description: "Webinars",
                    displayOrder: 1,
                    moduleContentId: 623,
                    moduleSections: [],
                    pageId: 622,
                    survey: null,
                    surveyId: null,
                    type: "Webinars",
                    value: "<ul><li>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </li><li>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </li><li>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. </li><li>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</li></ul>",
                }],
                pageId: null,
                survey: null,
                surveyId: null,
                type: null,
                value: null,
            }],
            moduleType: "Course Content",
            title: "Assistive Technology Specialist Content",
        }]
    },
    {
        courseId: 25,
        siteGroupId: DOE_OSS_SITE_GROUP_ID,
        courseTitle: `Threat Management Training`,
        courseDescription: ``,
        bgImageExtension: '',
        contact: null,
        externalDirectoryRoute: "",
        previewDirectoryRoute: "",
        hasPreview: false,
        isActive: true,
        isValid: true,
        violations: [],
        credits: `<p><b>Credit hours:</b> 2</p>`,
        creditOptions: [`<b>credit hours:</b> 2`],
        categoryIds: [],
        followUpActivityFormFileId: null,
        isRequiredToCompleteModulesInOrder: false,
        modules: [],
        hidden: true,
        closingCode: '',
        defaultParticipantCompletionDate: '12/2/2021',
        openingCode: '',
        courseType: courseTypes.RegularCourse.id
    },
    {
        courseId: 25,
        siteGroupId: MAPR_SITE_GROUP_ID,
        courseTitle: `Test Course`,
        courseDescription: ``,
        bgImageExtension: '',
        contact: null,
        externalDirectoryRoute: "",
        previewDirectoryRoute: "",
        hasPreview: false,
        isActive: true,
        isValid: true,
        violations: [],
        credits: `<p><b>Credit hours:</b> 2</p>`,
        creditOptions: [`<b>credit hours:</b> 2`],
        categoryIds: [],
        followUpActivityFormFileId: null,
        isRequiredToCompleteModulesInOrder: false,
        modules: [],
        hidden: true,
        closingCode: '',
        defaultParticipantCompletionDate: '12/2/2021',
        openingCode: '',
        courseType: courseTypes.RegularCourse.id
    },
];

export const courseId = courseData[0].courseId.toString();

const updateModuleComplete = emptyFunction;
const updateModuleProgress = emptyFunction;
const moduleId = "";

export const props = {
    courseId,
    moduleId,
    updateModuleComplete,
    updateModuleProgress,
    handleApiCall,
    redirect,
    siteDetails,
    setWarnings
};

export function getCourses(reset = false) {
    if (isNullOrUndefined(allCourses) || reset)
        allCourses = deepCopyArray(courseData);

    return allCourses;
}

export function getCourseForAdmin(courseId) {
    const allCourses = getCourses();
    let course = allCourses.find(f => f.courseId.toString() === courseId.toString());
    course.options = courseOptionsModel({
        ...course.options, ...{
            inservicePoints: course.credits,
            completeRequiredModulesInOrder: course.isRequiredToCompleteModulesInOrder
        }
    });
    course.followUpActivityFileName = course.followUpActivityFormFileId ? "LEGO_Braille_Bricks_Play-based_Teaching_Method_Follow_Up_Form_C8.docx" : "";
    return course;
}

export function getCourseEventForAdmin(courseId) {
    const allCourses = getCourses();
    let course = allCourses.find(f => f.courseId.toString() === courseId.toString());
    course.options = courseEventOptionsModel({inservicePoints: course.credits});
    return course;
}

export function getCourseEventByCodes(openingCode, closingCode) {
    const allCourses = getCourses();
    let course = allCourses.find(f => f.openingCode === openingCode && f.closingCode === closingCode && f.isActive);
    if (!course)
        return null;

    course.options = courseEventOptionsModel({inservicePoints: course.credits});
    return course;
}

export function getCourseProfessionalCommunityForAdmin(courseId) {
    const allCourses = getCourses();
    return allCourses.find(f => f.courseId.toString() === courseId.toString());
}


export function filterSearchResults(searchCriteria) {
    const allCourses = getCourses();

    const filteredCourses = allCourses.map((course) => {
        return {
            ...course,
            ...{
                isActive: course.isActive ? yesNoObject.Yes : yesNoObject.No,
                hidden: course.hidden ? yesNoObject.Yes : yesNoObject.No,
                isValid: course.isValid ? yesNoObject.Yes : yesNoObject.No,
                isExternal: isTrimmedStringEmpty(course.externalDirectoryRoute) ? yesNoObject.No : yesNoObject.Yes
            }
        };
    });

    return searchMockData(filteredCourses, searchCriteria, courseSearchResults);
}

export function filterCourseEventSearchResults(searchCriteria) {
    const allCourseEvents = getCourses().filter(f => f.courseType === courseTypes.EventCourse.id);

    const filteredCourseEvents = allCourseEvents.map((course) => {
        return {
            ...course,
            ...{
                isActive: course.isActive ? yesNoObject.Yes : yesNoObject.No
            }
        };
    });

    return searchMockData(filteredCourseEvents, searchCriteria, courseEventSearchResults);
}

export function filterCourseProfessionalCommunitySearchResults(searchCriteria) {
    const allCourseCommunities = getCourses().filter(f => f.courseType === courseTypes.ProfessionalCommunityCourse.id);

    const filteredCourseCommunities = allCourseCommunities.map((course) => {
        return {
            ...course,
            ...{
                isActive: course.isActive ? yesNoObject.Yes : yesNoObject.No
            }
        };
    });

    return searchMockData(filteredCourseCommunities, searchCriteria, courseProfessionalCommunitySearchResults);
}

const getNewCourseId = (allCourses) => {
    let courseIdAi = 0;
    for(const course of allCourses) {
        if (course.courseId > courseIdAi) courseIdAi = course.courseId;
    }

    return ++courseIdAi;
};

export function saveCourse(courseDetails) {
    let allCourses = getCourses();
    let course = allCourses.find(f => f.courseId.toString() === courseDetails.courseId.toString());

    if (isObjectNullOrEmpty(course)) {
        course = {
            courseId: getNewCourseId(allCourses),
            siteGroupId: MOCK_SITE_GROUP_ID,
            courseTitle: courseDetails.courseTitle,
            courseDescription: courseDetails.courseDescription,
            externalDirectoryRoute: courseDetails.externalDirectoryRoute,
            isActive: courseDetails.isActive,
            credits: courseDetails.inservicePoints,
            creditOptions: [courseDetails.inservicePoints],
            categoryIds: courseDetails.categoryIds,
            followUpActivityFormFileId: courseDetails.followUpActivityFormFileId,
            followUpActivityFileName: courseDetails.followUpActivityFormFileId ? "LEGO_Braille_Bricks_Play-based_Teaching_Method_Follow_Up_Form_C8.docx" : "",
            isRequiredToCompleteModulesInOrder: courseDetails.completeRequiredModulesInOrder,
            hidden: courseDetails.hidden,
            options: courseOptionsModel(courseDetails)
        };
        allCourses.push(course);
    } else {
        course.courseTitle = courseDetails.courseTitle;
        course.courseDescription = courseDetails.courseDescription;
        course.externalDirectoryRoute = courseDetails.externalDirectoryRoute;
        course.isActive = courseDetails.isActive;
        course.credits = courseDetails.inservicePoints;
        course.creditOptions = [courseDetails.inservicePoints];
        course.categoryIds = courseDetails.categoryIds;
        course.followUpActivityFormFileId = courseDetails.followUpActivityFormFileId;
        course.followUpActivityFileName = courseDetails.followUpActivityFormFileId ? "LEGO_Braille_Bricks_Play-based_Teaching_Method_Follow_Up_Form_C8.docx" : "";
        course.isRequiredToCompleteModulesInOrder = courseDetails.completeRequiredModulesInOrder;
        course.hidden = courseDetails.hidden;
        course.options = courseOptionsModel(courseDetails)
    }

    return course;
}


export function saveCourseEvent(courseEventsDetails) {
    let allCourseEvents = getCourses();
    let courseEvent = allCourseEvents.find(f => f.courseId.toString() === courseEventsDetails.courseId.toString());

    if (isObjectNullOrEmpty(courseEvent)) {
        courseEvent = {
            courseId: getNewCourseId(allCourseEvents),
            siteGroupId: MOCK_SITE_GROUP_ID,
            courseTitle: courseEventsDetails.courseTitle,
            openingCode: courseEventsDetails.openingCode,
            closingCode: courseEventsDetails.closingCode,
            defaultParticipantCompletionDate: courseEventsDetails.defaultParticipantCompletionDate,
            isActive: courseEventsDetails.isActive,
            credits: courseEventsDetails.inservicePoints,
            creditOptions: [courseEventsDetails.inservicePoints],
            hidden: true,
            options: courseEventOptionsModel(courseEventsDetails)
        };
        allCourses.push(courseEvent);
    } else {
        courseEvent.courseTitle = courseEventsDetails.courseTitle;
        courseEvent.isActive = courseEventsDetails.isActive;
        courseEvent.openingCode = courseEventsDetails.openingCode;
        courseEvent.closingCode = courseEventsDetails.closingCode;
        courseEvent.defaultParticipantCompletionDate = courseEventsDetails.defaultParticipantCompletionDate;
        courseEvent.credits = courseEventsDetails.inservicePoints;
        courseEvent.creditOptions = [courseEventsDetails.inservicePoints];
        courseEvent.options = courseEventOptionsModel(courseEventsDetails)
    }

    return courseEvent;
}

export function saveCourseProfessionalCommunity(courseCommunityDetails) {
    let allCourseCommunities = getCourses();
    let courseCommunity = allCourseCommunities.find(f => f.courseId.toString() === courseCommunityDetails.courseId.toString());

    if (isObjectNullOrEmpty(courseCommunity)) {
        courseCommunity = {
            courseId: getNewCourseId(allCourseCommunities),
            siteGroupId: MOCK_SITE_GROUP_ID,
            courseTitle: courseCommunityDetails.courseTitle,
            isActive: courseCommunityDetails.isActive,
            hidden: true
        };
        allCourses.push(courseCommunity);
    } else {
        courseCommunity.courseTitle = courseCommunityDetails.courseTitle;
        courseCommunity.isActive = courseCommunityDetails.isActive;
    }

    return courseCommunity;
}

export function removeModule(courseId, moduleId) {
    let allCourses = getCourses();
    let course = allCourses.find(f => f.courseId.toString() === courseId.toString());

    course.modules = course.modules.filter(f => f.moduleId.toString() !== moduleId.toString());
}

export function updateModuleSortOrder(courseId, newOrder) {
    let allCourses = getCourses();
    let course = allCourses.find(f => f.courseId.toString() === courseId.toString());

    for (let index = 0; index < newOrder.length; index++) {
        let module = course.modules.find(f => f.moduleId === newOrder[index]);
        if (module)
            module.moduleNumber = index + 1;
    }

    course.modules.sort(dynamicSort("moduleNumber"));

    return course;
}

const getNewModuleId = (allCourses) => {
    let moduleIdAi = 0;
    for(const course of allCourses) {
        for(const module of course.modules) {
            if (module.moduleId > moduleIdAi) moduleIdAi = module.moduleId;
        }
    }

    return ++moduleIdAi;
};

export function saveModule(moduleDetails, courseId) {
    let allCourses = getCourses();
    let course = allCourses.find(f => f.courseId.toString() === courseId.toString());
    let module = course.modules.find(f => f.moduleId.toString() === moduleDetails.moduleId.toString());

    let isNewModule = false;
    if (isObjectNullOrEmpty(module)) {
        isNewModule = true;
        module = {
            moduleId: getNewModuleId(allCourses),
            moduleNumber: course.modules.length + 1,
            modulePages: []
        }
    }

    module.title = moduleDetails.title;
    module.isRequired = moduleDetails.isRequired;

    if (isNewModule)
        course.modules.push(module);

    return course;
}

export function removeModuleContent(courseId, moduleId, moduleContentId) {
    let allCourses = getCourses();
    let course = allCourses.find(f => f.courseId.toString() === courseId.toString());
    let module = course.modules.find(f => f.moduleId.toString() === moduleId.toString());

    if (module.modulePages.some(f => String(f.moduleContentId) === String(moduleContentId)))
        module.modulePages = module.modulePages.filter(f => f.moduleContentId.toString() !== moduleContentId.toString());
    else {
        let page = module.modulePages.find((page) => (page.moduleSections.some(f => String(f.moduleContentId) === String(moduleContentId))));
        page.moduleSections = page.moduleSections.filter(f => String(f.moduleContentId) !== String(moduleContentId));
    }
}

export function updateModuleContentSortOrder(courseId, moduleId, parentId, newOrder) {
    let allCourses = getCourses();
    let course = allCourses.find(f => f.courseId.toString() === courseId.toString());
    let module = course.modules.find(f => f.moduleId.toString() === moduleId.toString());

    let children = getModuleContentChildren(module.modulePages, parentId);

    for (let index = 0; index < newOrder.length; index++) {
        let moduleContent = children.find(f => f.moduleContentId === newOrder[index]);
        if (moduleContent)
            moduleContent.displayOrder = index + 1;
    }

    children.sort(dynamicSort("displayOrder"));

    return course;
}

const getNewModuleContentId = (allCourses) => {
    let moduleContentIdAi = 0;
    for(const course of allCourses) {
        for(const module of course.modules) {
            for(const page of module.modulePages) {
                if (page.moduleContentId > moduleContentIdAi) moduleContentIdAi = page.moduleContentId;
                for(const section of page.moduleSections) {
                    if (section.moduleContentId > moduleContentIdAi) moduleContentIdAi = section.moduleContentId;
                }
            }
        }
    }

    return ++moduleContentIdAi;
};

export function saveModuleContent(moduleContentDetails, courseId, moduleId) {
    let allCourses = getCourses();
    let course = allCourses.find(f => f.courseId.toString() === courseId.toString());
    let module = course.modules.find(f => f.moduleId.toString() === moduleId.toString());
    let children = getModuleContentChildren(module.modulePages, moduleContentDetails.pageId);
    let moduleContent = children.find(f => f.moduleContentId.toString() === moduleContentDetails.moduleContentId.toString());

    let isNewModuleContent = false;
    if (isObjectNullOrEmpty(moduleContent)) {
        isNewModuleContent = true;
        moduleContent = {
            moduleContentId: getNewModuleContentId(allCourses),
            displayOrder: children.length + 1,
        }
    }

    moduleContent.description = moduleContentDetails.description;
    moduleContent.type = moduleContentDetails.type;
    moduleContent.value = moduleContentDetails.value;

    if (isNewModuleContent)
        children.push(moduleContent);

    if (isModulePage(moduleContentDetails.pageId))
        module.modulePages = children;
    else {
        let moduleParent = module.modulePages.find(f => String(f.moduleContentId) === String(moduleContentDetails.pageId));
        moduleParent.moduleSections = children;
    }

    return course;
}

export function removeProfessionalCommunityContent(courseId, moduleContentId) {
    let allCourses = getCourses();
    let course = allCourses.find(f => f.courseId.toString() === courseId.toString());
    if (!course.modules)
        return;

    let module = course.modules[0];

    if (!module.modulePages)
        return;

    let page = module.modulePages[0];
    page.moduleSections = page.moduleSections.filter(f => String(f.moduleContentId) !== String(moduleContentId));
}

export function updateProfessionalCommunityContentSortOrder(courseId, newOrder) {
    let allCourses = getCourses();
    let course = allCourses.find(f => f.courseId.toString() === courseId.toString());

    if (!course.modules)
        return;

    let module = course.modules[0];

    let children = module.modulePages[0];

    if (!children)
        return;

    for (let index = 0; index < newOrder.length; index++) {
        let moduleContent = children.find(f => f.moduleContentId === newOrder[index]);
        if (moduleContent)
            moduleContent.displayOrder = index + 1;
    }

    children.sort(dynamicSort("displayOrder"));

    return course;
}

export function updateCourseTrainingVerificationCompleted(courseId) {
    let allCourses = getCourses();
    let course = allCourses.find(f => f.courseId.toString() === courseId.toString());

    course.status = trainingStatus.VerificationCompleteTrainingClosed

    return course;
}

export function saveProfessionalCommunityContent(moduleContentDetails, courseId) {
    let allCourses = getCourses();
    let course = allCourses.find(f => f.courseId.toString() === courseId.toString());

    if (!course.modules) {
        course = saveModule({
            isRequired: false,
            moduleId: 0,
            courseTitle: ""
        }, courseId);
    }
    let module = course.modules[0];
    if (!module.modulePages || module.modulePages.length === 0) {
        course = saveModuleContent({
            description: "",
            moduleContentId: 0,
            pageId: null,
            type: null,
            moduleSections: [],
            value: null
        }, courseId, module.moduleId);
    }

    let moduleContent = module.modulePages[0].moduleSections.find(f => f.moduleContentId.toString() === moduleContentDetails.moduleContentId.toString());

    let isNewModuleContent = false;
    if (isObjectNullOrEmpty(moduleContent)) {
        isNewModuleContent = true;
        moduleContent = {
            moduleContentId: getNewModuleContentId(allCourses),
            displayOrder: module.modulePages[0].moduleSections.length + 1,
            type: contentType.html
        }
    }

    moduleContent.description = moduleContentDetails.type;
    moduleContent.type = moduleContentDetails.type;
    moduleContent.value = moduleContentDetails.value;

    if (isNewModuleContent)
        module.modulePages[0].moduleSections.push(moduleContent);

    return course;
}