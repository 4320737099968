import PropTypes from "prop-types";
import React from "react";
import {Button, inputStyles} from "../Inputs/";
import {GridCell, gridConstants, GridContainer} from "./index";

const ResultsPager = ({
                          currentPage,
                          disabled,
                          handleFirstClick,
                          handlePreviousClick,
                          handleNextClick,
                          handleLastClick,
                          isSecondaryPager,
                          recordsPerPage,
                          totalRecords,
                      }) => {
    const hideSecondaryPager = isSecondaryPager && totalRecords < recordsPerPage;
    if (!totalRecords || hideSecondaryPager) return null;

    let firstVisibleRecord = (currentPage - 1) * recordsPerPage + 1;

    let recordsOnCurrentPage = currentPage * recordsPerPage;
    let lastVisibleRecord =
        totalRecords > recordsOnCurrentPage ? (currentPage - 1) * recordsPerPage + recordsPerPage : totalRecords;

    let cantNavigateBackward = currentPage === 1;
    let cantNavigateForward = lastVisibleRecord >= totalRecords;

    return (
        <>
            {
                cantNavigateBackward && cantNavigateForward &&
                <div>
                    <div className={`pager__text`}>
                        {`Displaying ${totalRecords} Records`}
                    </div>
                </div>
            }
            {
                (!cantNavigateBackward || !cantNavigateForward) &&
                <nav aria-label="Search Results">
                    <GridContainer hasMarginX className={`pager`}>
                        <GridCell small_columns={gridConstants.column.TWO}>
                            <Button
                                onClick={handleFirstClick}
                                buttonType={inputStyles.button.types.FIRST}
                                name="btnFirstPage"
                                label="First"
                                showLabel={false}
                                disabled={disabled || cantNavigateBackward}
                            />
                        </GridCell>
                        <GridCell small_columns={gridConstants.column.THREE}>
                            <Button
                                onClick={handlePreviousClick}
                                buttonType={inputStyles.button.types.PREVIOUS}
                                name="btnPreviousPage"
                                label="Previous"
                                showLabel={false}
                                disabled={disabled || cantNavigateBackward}
                            />
                        </GridCell>
                        <GridCell small_columns={gridConstants.column.TWO}>
                            <div className={`pager__text`}>
                                <span className={`show-for-sr`}> Displaying Records </span>
                                <span>{`${firstVisibleRecord} – ${lastVisibleRecord} of ${totalRecords}`}</span>
                                <span className={`show-for-sr`}> Total Records </span>
                            </div>
                        </GridCell>

                        <GridCell small_columns={gridConstants.column.THREE}>
                            <Button
                                onClick={handleNextClick}
                                buttonType={inputStyles.button.types.NEXT}
                                name="btnNextPage"
                                label="Next"
                                showLabel={false}
                                disabled={disabled || cantNavigateForward}
                            />
                        </GridCell>
                        <GridCell small_columns={gridConstants.column.TWO}>
                            <Button
                                onClick={handleLastClick}
                                buttonType={inputStyles.button.types.LAST}
                                name="btnLastPage"
                                label="Last"
                                showLabel={false}
                                disabled={disabled || cantNavigateForward}
                            />
                        </GridCell>
                    </GridContainer>
                </nav>
            }
        </>
    );
};

ResultsPager.propTypes = {
    currentPage: PropTypes.number,
    disabled: PropTypes.bool,
    handleFirstClick: PropTypes.func.isRequired,
    handlePreviousClick: PropTypes.func.isRequired,
    handleNextClick: PropTypes.func.isRequired,
    handleLastClick: PropTypes.func.isRequired,
    isSecondaryPager: PropTypes.bool,
    recordsPerPage: PropTypes.number,
    totalRecords: PropTypes.number,
};

export default ResultsPager;
