import PropTypes from "prop-types";
import React from "react";
import { NavLink } from "react-router-dom";

export const Link = ({ className, label, to, handleClick = () => {} }) => {
    if (!label) return <> </>;

    return (
        <NavLink to={to} className={className} onClick={handleClick} exact>
            {label}
        </NavLink>
    );
};

Link.propTypes = {
    className: PropTypes.string,
    label: PropTypes.any,
    to: PropTypes.string,
    handleClick: PropTypes.func
};