import PropTypes from "prop-types";
import React, {useEffect} from "react";
import {refocusOnPageChange, useDocumentTitle} from "./landmarksUtility";

const PageTitle = ({
                       children,
                       className = "",
                       h1,
                       hasBorder,
                       isCentered,
                       isDocumentTitle = true
                   }) => {

    className += " pageTitle ";
    className += hasBorder ? " pageTitle--border " : "";
    className += isCentered ? " pageTitle--center " : "";

    useDocumentTitle(isDocumentTitle ? h1 : "");

    useEffect(refocusOnPageChange, [h1]);

    return (
        <header className={className}>
            <br/>
            <h1 tabIndex={"-1"}>{h1} <span id={"extraScreenReaderContent"} className={"show-for-sr"}/></h1>
            <div className={`h2`}>{children}</div>
        </header>
    );
};

PageTitle.propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    h1: PropTypes.string.isRequired,
    hasBorder: PropTypes.bool,
    isCentered: PropTypes.bool,
    isDocumentTitle: PropTypes.bool,
};

export default React.memo(PageTitle);
